import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import { FormikErrors } from "formik";
import { NewLetterDetailsFormValues } from "../types";

export default function LogoUploader({
  setFieldValue,
  currentLogo,
}: {
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<void> | Promise<FormikErrors<NewLetterDetailsFormValues>>;
  currentLogo?: string;
}) {
  const [logoPreview, setLogoPreview] = useState<string | ArrayBuffer | null>(
    null
  );

  return (
    <>
      {currentLogo && !logoPreview && (
        <>
          <Avatar sx={{ width: 150, height: 150, mb: 4 }} src={currentLogo} />
        </>
      )}

      {logoPreview && (
        <>
          <Avatar
            sx={{ width: 150, height: 150, mb: 4 }}
            src={logoPreview as string}
          />
        </>
      )}

      <Button variant="contained" component="label">
        Add Logo
        <input
          name="logo"
          accept=".png,.jpg,.gif"
          id="site_logo"
          type="file"
          hidden
          onChange={(e) => {
            const fileReader = new FileReader();
            fileReader.onload = () => {
              if (fileReader.readyState === 2) {
                if (e.target.files?.[0]) {
                  setFieldValue("logo", e.target.files[0]);
                }
                setLogoPreview(fileReader.result);
              }
            };
            if (e.target.files?.[0]) {
              fileReader.readAsDataURL(e.target.files[0]);
            }
          }}
        />
      </Button>
    </>
  );
}
