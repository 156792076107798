import React, { useState, useRef, useEffect, useMemo } from "react";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useSearchBox, useHits } from "react-instantsearch";
import { useNavigate, Link } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import classNames from "classnames";
import "./styles.scss";
import useSearchAnalytics from "../hooks/useSearchAnalytics";

const CustomAutoComplete = ({ isEmbedded }: { isEmbedded: boolean }) => {
  const [open, setOpen] = useState(false);
  const inputRef = useRef<HTMLDivElement>(null);
  const textFieldRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const { query: searchBoxQuery, refine } = useSearchBox();
  const { hits } = useHits();

  useSearchAnalytics(searchBoxQuery);

  const query = useMemo(() => {
    return searchBoxQuery.trim();
  }, [searchBoxQuery]);

  const currentHits = useMemo(() => {
    return hits.slice(0, 5);
  }, [hits]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        inputRef.current &&
        !inputRef.current.contains(event.composedPath()[0] as Node)
      ) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && !!query) {
      textFieldRef.current?.blur();
      setOpen(false);
      if (isEmbedded) {
        window.location.href = `/marketplace?query=${encodeURIComponent(query)}`;
      } else {
        navigate(`/marketplace?query=${encodeURIComponent(query)}`);
      }
    }
  };

  return (
    <div
      ref={inputRef}
      className={classNames("algolia-search__autocomplete", {
        "algolia-search__autocomplete--expand": open,
        "algolia-search__autocomplete--collapse": !open,
      })}
    >
      <TextField
        placeholder="Search the Marketplace"
        variant="outlined"
        fullWidth
        value={searchBoxQuery}
        onChange={(event) => refine(event.target.value)}
        onFocus={() => setOpen(true)}
        onKeyDown={handleKeyDown}
        inputRef={textFieldRef}
        InputProps={{
          startAdornment: (
            <InputAdornment
              position="start"
              className="algolia-search__autocomplete-icon"
              sx={{ mr: "-8px" }}
            >
              <SearchIcon />
            </InputAdornment>
          ),
          sx: { borderRadius: "50px" },
        }}
        className="algolia-search__autocomplete-input"
      />
      {open && (
        <Paper className="algolia-search__autocomplete-dropdown">
          {currentHits.length > 0 ? (
            currentHits.map((hit) => (
              <Link
                onClick={() => {
                  setOpen(false);
                  textFieldRef.current?.blur();
                }}
                to={`/sites/${hit.slug}?attr_source=top-nav`}
                key={hit.objectID}
                reloadDocument={true}
              >
                <Card className="algolia-search__autocomplete-card">
                  <Box display="flex">
                    <CardMedia
                      component="img"
                      sx={{ width: 75, aspectRatio: "1/1" }}
                      image={hit.logo_url}
                      alt="Logo"
                    />
                    <CardContent style={{ minWidth: 0, padding: "5px 20px" }}>
                      <Typography
                        variant="body1"
                        fontWeight="500"
                        className="algolia-search__autocomplete-title"
                      >
                        {hit.name}
                      </Typography>
                      <Typography
                        variant="body2"
                        className="algolia-search__autocomplete-description"
                      >
                        {hit.description}
                      </Typography>
                    </CardContent>
                  </Box>
                </Card>
              </Link>
            ))
          ) : (
            <Box className="algolia-search__autocomplete-no-results">
              <Typography variant="body1">No results</Typography>
            </Box>
          )}
          <Box className="algolia-search__autocomplete-footer">
            <Link
              onClick={() => {
                setOpen(false);
                textFieldRef.current?.blur();
              }}
              to={
                query.length > 0 && currentHits.length > 0
                  ? `/marketplace?query=${encodeURIComponent(query)}`
                  : "/categories/recommendations"
              }
              reloadDocument={
                isEmbedded || query.length === 0 || currentHits.length === 0
              }
              className="algolia-search__autocomplete-footer-link"
            >
              {query.length > 0 && currentHits.length > 0
                ? "Show All"
                : "All Recommended Newsletters"}
            </Link>
          </Box>
        </Paper>
      )}
    </div>
  );
};

export default CustomAutoComplete;
