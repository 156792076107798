import React from "react";
import {
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Avatar,
  Typography,
  TableContainer,
} from "@mui/material";
import TableCell from "@mui/material/TableCell/TableCell";
import { grey } from "@mui/material/colors";
import { Link } from "react-router-dom";
import StatusItem from "components/StatusItem";
import PositionedMenu from "./PositionedMenu";
import { IPublisher } from "./types";

export default function SitesList({
  publishers,
}: {
  publishers: IPublisher[];
}) {
  return (
    <TableContainer component={Paper} sx={{ p: 3 }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="left" width={4} />
            <TableCell>Profile name</TableCell>
            <TableCell align="left">Status</TableCell>
            <TableCell align="left" />
          </TableRow>
        </TableHead>
        <TableBody>
          {publishers.map((row) => (
            <TableRow
              key={row.slug}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell align="left">
                {row.logo ? (
                  <Avatar src={row.logo} />
                ) : (
                  <Avatar sx={{ bgcolor: grey[500] }}>logo</Avatar>
                )}
              </TableCell>
              <TableCell align="left">
                <Link
                  to={`/sites/${row.slug}`}
                  reloadDocument={true}
                  target="_blank"
                >
                  <Typography sx={{ color: "text.primary" }}>
                    {row.name}
                  </Typography>
                </Link>
              </TableCell>
              <TableCell align="left">
                <StatusItem status={row.status} />
              </TableCell>
              <TableCell align="right">
                <PositionedMenu publisher={row} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
