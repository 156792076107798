import React from "react";
import { Grid } from "@mui/material";
import PaymentInfoCard from "./PaymentInfoCard";

export default function PaymentsInfoCards({
  futurePayouts,
  pendingPayouts,
  totalPaid,
}: {
  futurePayouts: number;
  pendingPayouts: number;
  totalPaid: number;
}) {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <PaymentInfoCard caption="Future Payouts" value={futurePayouts} />
        </Grid>
        <Grid item xs={12} md={4}>
          <PaymentInfoCard caption="Pending Payouts" value={pendingPayouts} />
        </Grid>
        <Grid item xs={12} md={4}>
          <PaymentInfoCard caption="Total Paid" value={totalPaid} />
        </Grid>
      </Grid>
    </>
  );
}
