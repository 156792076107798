import React, { useState } from "react";
import { ToggleButton, Typography, Grid } from "@mui/material";
import { DAYS_OF_WEEK, weeks, weeksCaptions } from "./index";

export type MonthlyWeekSelectorProps = {
  onChange: (selected: string[]) => void;
  bymonthweekday: string[];
};

export default function MonthlyWeekSelector({
  onChange,
  bymonthweekday,
}: MonthlyWeekSelectorProps) {
  const [selectedItems, setSelectedItems] = useState<string[]>(bymonthweekday);

  const handleToggle = (item: string) => {
    setSelectedItems((prevItems) =>
      prevItems.includes(item)
        ? prevItems.filter((i) => i !== item)
        : [...prevItems, item]
    );
  };

  React.useEffect(() => {
    onChange(selectedItems);
  }, [selectedItems, onChange]);

  return (
    <Grid container spacing={1} width="440px">
      {weeks.map((week, index) => (
        <Grid
          item
          xs={12}
          key={`week-${week}`}
          container
          spacing={1}
          alignItems="center"
        >
          <Grid item sx={{ width: 40 }}>
            <Typography>{weeksCaptions[index]}</Typography>
          </Grid>
          {DAYS_OF_WEEK.map((day) => (
            <Grid item key={`${week},${day}`}>
              <ToggleButton
                value={`${week},${day}`}
                selected={selectedItems.includes(`${week},${day}`)}
                onChange={() => handleToggle(`${week},${day}`)}
                sx={{ width: 48, height: 40 }}
                data-testid={`${week},${day}`}
              >
                {day.toString()}
              </ToggleButton>
            </Grid>
          ))}
        </Grid>
      ))}
    </Grid>
  );
}
