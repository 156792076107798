import React, { useState } from "react";
import {
  FormControlLabel,
  Switch,
  Tooltip,
  Typography,
  Link,
  Box,
  FormHelperText,
} from "@mui/material";
import { HelpOutline } from "@mui/icons-material";
import { grey } from "@mui/material/colors";
import { SMART_PUBLISHED_PRICE_MIN } from "../sponsoredEmail/Placement";

interface SmartPricingProps {
  name: string;
  values: {
    smart_pricing_enabled: boolean;
    published_price: number;
    smart_pricing_lowest_price: number;
  };
  smartPricingDisabled: boolean;
  smartPricingToggle: (checked?: boolean) => void;
  buttonRef?: React.MutableRefObject<HTMLInputElement | null>;
}

function SmartPricingSwitch({
  name,
  values,
  smartPricingDisabled,
  smartPricingToggle,
  buttonRef,
}: SmartPricingProps) {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const handleTooltipClick = (event: any) => {
    event.preventDefault();
    if (event.nativeEvent.pointerType === "mouse") {
      window.open(
        "https://www.paved.com/help/articles/8124581-turn-on-smart-pricing",
        "_blank",
        "noopener,noreferrer"
      );
    } else if (event.nativeEvent.pointerType === "touch") {
      setTooltipOpen(!tooltipOpen);
    }
  };

  const handleTooltipOpen = () => {
    setTooltipOpen(true);
  };

  const handleTooltipClose = () => {
    setTooltipOpen(false);
  };

  return (
    <Box>
      <Typography
        sx={{
          fontWeight: 400,
          fontSize: "1.125rem",
          color: "#0e1952",
          lineHeight: 1.2,
        }}
      >
        Price Settings
      </Typography>

      <FormControlLabel
        control={
          <Tooltip
            title={
              smartPricingDisabled
                ? `Available only if the sponsorship price is at least $${SMART_PUBLISHED_PRICE_MIN}`
                : ""
            }
          >
            <div>
              <Switch
                name={name}
                value={values.smart_pricing_enabled}
                checked={values.smart_pricing_enabled}
                onClick={() => smartPricingToggle(false)}
                disabled={smartPricingDisabled}
                inputRef={buttonRef}
              />
            </div>
          </Tooltip>
        }
        label={
          <React.Fragment>
            Smart Pricing
            <Tooltip
              title={
                <div>
                  <Typography sx={{ fontSize: "0.75rem", fontWeight: 500 }}>
                    The Smart Pricing feature lets you set a dynamic price range
                    for your sponsorships instead of a fixed price.{" "}
                  </Typography>
                  <Link
                    href="https://www.paved.com/help/articles/8124581-turn-on-smart-pricing"
                    target="_blank"
                    rel="noreferrer"
                    sx={{
                      color: grey[50],
                      textDecoration: "underline",
                      "&:hover": {
                        textDecorationColor: grey[500],
                        color: grey[500],
                      },
                      fontSize: "0.75rem",
                      fontWeight: 500,
                    }}
                  >
                    Learn how Smart Pricing works
                  </Link>
                </div>
              }
              open={tooltipOpen}
              onOpen={() => handleTooltipOpen()}
              onClose={handleTooltipClose}
              onClick={(e) => handleTooltipClick(e)}
              leaveTouchDelay={5000}
            >
              <HelpOutline
                sx={{
                  fontSize: "medium",
                  ml: 0.25,
                  color: "primary.main",
                }}
              />
            </Tooltip>
          </React.Fragment>
        }
        sx={{ mr: 0.25, mt: 1 }}
      />
      <FormHelperText sx={{ mt: 0.5 }}>
        Automatically offer a last-minute discount as an incentive for
        advertisers. Your price stays within the range you set, and you can
        change it anytime.
      </FormHelperText>
    </Box>
  );
}

export default SmartPricingSwitch;
