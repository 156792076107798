import React, { useState, useEffect } from "react";
import {
  Grid,
  Button,
  Card,
  CardHeader,
  CardContent,
  CircularProgress,
  FormHelperText,
  IconButton,
  InputLabel,
  TextField,
  Box,
  Typography,
  Divider,
  SelectChangeEvent,
} from "@mui/material";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import axios from "axios";
import CreativeRequirements, { AssetError } from "./CreativeRequirements";
import { IPlacement } from "types";
import PlacementExample from "./PlacementExample";
import noop from "lodash/noop";
import SmartPricingModal from "./SmartPricingModal";
import { useSmartPricing } from "../hooks/useSmartPricing";
import SponsorshipPricing from "../components/SmartPricing";
import SmartPricingSwitch from "../components/SmartPricingSwitch";
export const PUBLISHED_PRICE_MIN = 75;
export const SMART_PUBLISHED_PRICE_MIN = 150;
export const SMART_PRICE_SEED_PC = 0.6;

const locations = [
  {
    value: "top",
    label: "Top",
  },
  {
    value: "middle",
    label: "Middle",
  },
  {
    value: "bottom",
    label: "Bottom",
  },
  {
    value: "other",
    label: "Other",
  },
];

type Props = {
  prefix: string;
  values: IPlacement;
  touched: { [key: string]: any };
  errors: { [key: string]: any };
  index: number;
  isCanRemoved: boolean;
  isDeleting: boolean;
  exampleUrl: string;
  siteSlug: string;
  setFieldValue: (field: string, value: any) => void;
  handleChange: (e: SelectChangeEvent | React.ChangeEvent) => void;
  handleBlur: (e: React.FocusEvent) => void;
  handleRemove: (index: number) => void;
  exceedsPreviousAdvertisersLimit: boolean;
  handlePriceBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
};

export default function Placement({
  prefix,
  values,
  touched,
  errors,
  index,
  isCanRemoved,
  isDeleting,
  exampleUrl,
  siteSlug,
  setFieldValue,
  handleChange,
  handleBlur,
  handleRemove,
  exceedsPreviousAdvertisersLimit,
  handlePriceBlur,
}: Props) {
  const [highlightPlacement, setHighlightPlacement] = useState("");
  useEffect(() => {
    if (values.id && siteSlug && exampleUrl)
      axios({
        method: "get",
        url: `/api/v1/publisher/sites/${siteSlug}/sponsorship_options/sponsored_email/highlight_placement?id=${values.id}`,
      })
        .then((response) => {
          setHighlightPlacement(response.data.placement_screenshot);
        })
        .catch(noop);
  }, [values.id, siteSlug, exampleUrl]);

  const [modalOpen, setModalOpen] = useState(false);

  const { smartPricingDisabled, smartPricingToggle, buttonRef } =
    useSmartPricing(values, setFieldValue, setModalOpen, prefix);

  return (
    <Card>
      <SmartPricingModal
        open={modalOpen}
        setModalOpen={setModalOpen}
        smartPricingToggle={smartPricingToggle}
      />
      <CardHeader
        title={values.name}
        titleTypographyProps={{ variant: "h6" }}
        action={
          isCanRemoved ? (
            <IconButton
              color="primary"
              disabled={isDeleting}
              onClick={() => handleRemove(index)}
            >
              {isDeleting ? (
                <CircularProgress size={20} />
              ) : (
                <DeleteForeverOutlinedIcon />
              )}
            </IconButton>
          ) : null
        }
      />
      <CardContent>
        <Grid container spacing={4} flexDirection="column">
          <Grid item>
            <InputLabel htmlFor={`${prefix}.name`}>Placement Name</InputLabel>
            <TextField
              id={`${prefix}.name`}
              name={`${prefix}.name`}
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.name && Boolean(errors.name)}
              data-error-element={Boolean(errors.name)}
              helperText={
                touched.name && Boolean(errors.name) ? errors.name : ""
              }
            />
            <FormHelperText>
              Enter a name for this placement e.g. "Featured Placement".
            </FormHelperText>
          </Grid>
          <Grid item>
            <InputLabel htmlFor={`${prefix}.placement_location`}>
              Placement Location
            </InputLabel>
            <Grid container spacing={1}>
              {locations.map((location) => (
                <Grid item key={location.value}>
                  <Button
                    variant="outlined"
                    onClick={() =>
                      setFieldValue(
                        `${prefix}.placement_location`,
                        location.value
                      )
                    }
                    sx={{
                      color:
                        values.placement_location === location.value
                          ? "primary"
                          : "#656d77",
                      borderColor:
                        values.placement_location === location.value
                          ? "primary.main"
                          : "#dadcde",
                    }}
                  >
                    {location.label}
                  </Button>
                </Grid>
              ))}
            </Grid>
            <FormHelperText>
              {touched.placement_location &&
                Boolean(errors.placement_location) && (
                  <Box
                    component="span"
                    sx={{ color: "error.main", mb: 2, display: "block" }}
                    data-error-element={Boolean(errors.placement_location)}
                  >
                    {errors.placement_location}
                  </Box>
                )}
              Select where the placement is located within your newsletter.
            </FormHelperText>
          </Grid>
          <Grid item>
            <InputLabel htmlFor={`${prefix}.ad_description`}>
              Placement Details
            </InputLabel>
            <TextField
              id={`${prefix}.ad_description`}
              fullWidth={true}
              name={`${prefix}.ad_description`}
              multiline
              rows={3}
              value={values.ad_description}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.ad_description && Boolean(errors.ad_description)}
              data-error-element={Boolean(errors.ad_description)}
              helperText={
                touched.ad_description && Boolean(errors.ad_description)
                  ? errors.ad_description
                  : null
              }
            />
            <FormHelperText>
              This is a short description of the sponsorship placement. You can
              keep it simple or use it as an opportunity to tell an advertiser
              why they should buy this placement. e.g.: This is our featured
              placement at the top of our email. It is seen by all of our
              subscribers as soon as they open our newsletter.
            </FormHelperText>
          </Grid>
          <Grid item>
            <InputLabel
              htmlFor={`${prefix}.user_ad_click_total`}
              sx={{ display: "flex", alignItems: "center" }}
            >
              Sponsorship Clicks
              {!exceedsPreviousAdvertisersLimit && (
                <FormHelperText
                  sx={{
                    marginTop: 0.25,
                    paddingLeft: 0.75,
                    fontStyle: "italic",
                  }}
                >
                  Optional
                </FormHelperText>
              )}
            </InputLabel>
            <TextField
              id={`${prefix}.user_ad_click_total`}
              name={`${prefix}.user_ad_click_total`}
              type="number"
              value={values.user_ad_click_total}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                touched.user_ad_click_total &&
                Boolean(errors.user_ad_click_total)
              }
              data-error-element={Boolean(errors.user_ad_click_total)}
              helperText={
                touched.user_ad_click_total &&
                Boolean(errors.user_ad_click_total)
                  ? errors.user_ad_click_total
                  : ""
              }
            />
            <FormHelperText>
              This is the number of clicks for this sponsorship placement. You
              can calculate this by looking at the average number of clicks for
              your most recent sponsors. First time or unsure? It's better to
              leave this field blank than guess.
            </FormHelperText>
          </Grid>
          <Grid item>
            <SmartPricingSwitch
              values={values}
              name={`${prefix}.smart_pricing_enabled`}
              smartPricingDisabled={smartPricingDisabled}
              smartPricingToggle={smartPricingToggle}
              buttonRef={buttonRef}
            />
          </Grid>
          <Divider sx={{ mt: 2 }} />
          <Grid item>
            <SponsorshipPricing
              type="sponsored_email"
              onChange={handleChange}
              onBlur={handleBlur}
              values={values}
              errors={errors}
              prefix={prefix}
              touched={touched}
              smartPricingDisabled={smartPricingDisabled}
              onPriceBlur={handlePriceBlur}
            />
          </Grid>
          <Grid item>
            <CreativeRequirements
              id={values.id}
              prefix={`${prefix}.creativeAssets`}
              values={values.creativeAssets}
              errors={{
                linkAsset: errors.linkAsset,
                nonLinkAsset: errors.nonLinkAsset,
                assetsErrors: errors.creativeAssets as AssetError[],
              }}
              touched={touched.creativeAssets as { [key: string]: boolean }[]}
              handleChange={handleChange}
              handleBlur={handleBlur}
              setFieldValue={setFieldValue}
            />
            {touched.creativeAssets &&
              errors.creativeAssets &&
              !Array.isArray(errors.creativeAssets) && (
                <Typography
                  component="span"
                  sx={{
                    color: "error.main",
                    mt: "12px",
                    mb: 2,
                    display: "block",
                    fontSize: ".75rem",
                  }}
                  data-error-element={Boolean(errors.creativeAssets)}
                >
                  {errors.creativeAssets}
                </Typography>
              )}
          </Grid>
          {exampleUrl && (
            <Grid item>
              <PlacementExample
                siteSlug={siteSlug}
                id={values.id.toString()}
                exampleUrl={exampleUrl}
                hightlightPlacement={highlightPlacement}
              />
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
}
