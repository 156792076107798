import React from "react";
import { Popper, Paper } from "@mui/material";
import * as Yup from "yup";
import cls from "classnames";

const validations = [
  {
    schema: Yup.string().matches(/[a-z]/).matches(/[A-Z]/),
    message: "uppercase and lowercase Latin letters,",
  },
  {
    schema: Yup.string().matches(/[0-9]/),
    message: "numbers,",
  },
  {
    schema: Yup.string().matches(/[\W_]/),
    message: "symbols,",
  },
  {
    schema: Yup.string().min(8),
    message: "at least 8 characters.",
  },
];

export default function PasswordPopper({
  id,
  open,
  anchorEl,
  value,
}: {
  id: string | undefined;
  open: boolean;
  anchorEl: null | HTMLElement;
  value: string;
}) {
  const validationResults = validations.map((validation) => ({
    ...validation,
    isValid: validation.schema.isValidSync(value),
  }));

  const isPasswordValid = validationResults.every(
    (validationResult) => validationResult.isValid
  );

  return isPasswordValid ? null : (
    <Popper
      id={id}
      open={open}
      anchorEl={anchorEl}
      placement="top"
      disablePortal={true}
    >
      <Paper
        className="password__helper"
        sx={{
          maxWidth: anchorEl?.offsetWidth,
          bgcolor: "background.paper",
        }}
      >
        <div>Password must include:</div>
        {validations.map((item) => (
          <div key={item.message} className="password__helper-item">
            <div
              className={cls({
                check: item.schema.isValidSync(value),
                error: !item.schema.isValidSync(value),
              })}
            >
              {!item.schema.isValidSync(value) && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="140%"
                  height="140%"
                  preserveAspectRatio="xMidYMid meet"
                  viewBox="0 0 24 24"
                  focusable="false"
                >
                  <path d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41Z"></path>
                </svg>
              )}
            </div>
            <div>{item.message}</div>
          </div>
        ))}
      </Paper>
    </Popper>
  );
}
