import React, { ChangeEvent } from "react";
import { Box, Button, InputLabel, TextField } from "@mui/material";
import { ICreativeRequirement } from "types";
import { useTheme } from "@mui/material/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/pro-regular-svg-icons";

export default function CreativeItemLinkField({
  creativeItem,
  value,
  onChange,
  onBlur,
  error,
  warning,
  validating,
  isValid,
  onValidateClick,
}: {
  creativeItem: ICreativeRequirement;
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur: (e: React.FocusEvent) => void;
  error?: string;
  warning?: string;
  validating?: boolean;
  isValid?: boolean | undefined;
  onValidateClick: () => void;
}) {
  const theme = useTheme();
  const { name, uuid } = creativeItem;

  const sx =
    !!error || !!warning
      ? {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: !!error
              ? theme.palette.error.main
              : "#de5d06 !important",
          },
          "& .MuiFormHelperText-root": {
            color: !!error ? theme.palette.error.main : "#de5d06",
            mt: "6px",
          },
        }
      : null;

  return (
    <>
      <InputLabel htmlFor={uuid} sx={{ maxWidth: "60vw" }}>
        {name}
      </InputLabel>
      <Box display="flex" alignItems="flex-start">
        <TextField
          fullWidth={true}
          id={uuid}
          name={uuid}
          type="text"
          multiline={false}
          rows={1}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          error={!!error}
          helperText={error ? error : warning}
          disabled={validating}
          data-error-element={Boolean(error || warning)}
          sx={sx}
        />
        {(!isValid || validating) && (
          <Button
            variant="contained"
            color="primary"
            sx={{ ml: 1, mt: "-1px" }}
            disabled={validating || !!error || !value}
            onClick={onValidateClick}
          >
            <Box sx={{ whiteSpace: "nowrap" }}>
              {validating ? "Checking" : "Check Link"}
            </Box>
          </Button>
        )}
        {isValid && (
          <FontAwesomeIcon
            icon={faCircleCheck}
            style={{ color: "#6cce87", marginLeft: "8px", marginTop: "8px" }}
          />
        )}
      </Box>
    </>
  );
}
