import React, { ReactNode, useEffect, useState } from "react";
import { SelectChangeEvent } from "@mui/material/Select";
import { Box, MenuItem, InputLabel, CircularProgress } from "@mui/material";
import axios from "axios";
import Select from "components/CustomSelect";
import { Category } from "../types";
import noop from "lodash/noop";

export default function CategoryIdSelect({
  value,
  onChange,
}: {
  value: string;
  onChange: (event: SelectChangeEvent<unknown>, child: ReactNode) => void;
}) {
  const [categoriesList, setCategoriesList] = useState<Category[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .get("/api/v1/categories")
      .then(function (response) {
        setCategoriesList(response.data);
      })
      .catch(noop)
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <>
      <InputLabel id="category_ids">
        What category best describes your newsletter?
      </InputLabel>
      <Select
        fullWidth={true}
        labelId="category_ids"
        name="category_ids"
        id="category_ids"
        value={loading ? "" : value}
        onChange={onChange}
        startAdornment={
          loading ? (
            <Box sx={{ display: "flex", pl: 2 }}>
              <Box sx={{ mr: 2 }}>Loading...</Box>
              <CircularProgress color="inherit" size={20} />
            </Box>
          ) : null
        }
      >
        {categoriesList.map((category) => (
          <MenuItem key={category.id} value={category.id}>
            {category.name}
          </MenuItem>
        ))}
      </Select>
    </>
  );
}
