import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { DateRangePicker, isSameDay } from "react-dates";
import "./react_dates_overrides.scss";
import { Moment } from "moment";
import { useState } from "react";
import { useRange } from "react-instantsearch";
import moment from "moment";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const blockedDatesList = [moment(), moment().add(1, "days")];

export default function DatePanel() {
  const { start, refine } = useRange({ attribute: "sponsored_email_dates" });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const upToLg = !useMediaQuery("(min-width:1024px)");

  const [from, to] = start;
  const [startDate, setStartDate] = useState(
    Number.isFinite(from) ? moment.unix(from as number) : null
  );
  const [endDate, setEndDate] = useState(
    Number.isFinite(to) ? moment.unix(to as number) : null
  );
  const [focusedInput, setFocusedInput] = useState(null);

  const onDateChange = (dates: {
    startDate: Moment | null;
    endDate: Moment | null;
  }) => {
    setStartDate(dates.startDate);
    setEndDate(dates.endDate);

    if (dates.startDate === null && dates.endDate === null) {
      refine([undefined, undefined]);
    }

    if (dates.startDate && dates.endDate) {
      const startTimestamp = dates.startDate.unix();
      const endTimestamp = dates.endDate.unix();
      refine([startTimestamp, endTimestamp]);
    }
  };

  const onFocusChange = (focusedInput: any) => {
    setFocusedInput(focusedInput);
  };

  const isDayBlocked = (day1: Moment) =>
    blockedDatesList.some((day2) => isSameDay(day1, day2));

  return (
    <div>
      <DateRangePicker
        startDate={startDate}
        startDateId="start_date_id"
        endDate={endDate}
        endDateId="end_date_id"
        onDatesChange={onDateChange}
        focusedInput={focusedInput}
        onFocusChange={onFocusChange}
        showDefaultInputIcon
        small
        displayFormat="MMM D"
        daySize={36}
        hideKeyboardShortcutsPanel
        showClearDates
        isDayBlocked={isDayBlocked}
        readOnly
        numberOfMonths={isMobile ? 1 : 2}
        withPortal={upToLg ? true : false}
      />
    </div>
  );
}
