import React from "react";
import azure from "../assets/images/sso-icons/azure.svg";
import google from "../assets/images/sso-icons/google.svg";
// import okta from "../assets/images/sso-icons/okta.svg";
import { Button, Link } from "@mui/material";

const ssoProvidersList = [
  { provider: "google", caption: "Google", image: google },
  { provider: "azure", caption: "Microsoft", image: azure },
  // { provider: "okta", caption: "Okta", image: okta },
];

export default function SSOButtons() {
  return (
    <div className="sso-buttons">
      <p className="subheading">Or continue with:</p>
      {ssoProvidersList.map((item) => (
        <Link href={`/sso?provider=${item.provider}`} key={item.provider}>
          <Button
            variant="outlined"
            size="large"
            fullWidth={true}
            startIcon={
              <img width="21" height="21" src={item.image} alt={item.caption} />
            }
            sx={{ mb: 2 }}
          >
            {item.caption}
          </Button>
        </Link>
      ))}
    </div>
  );
}
