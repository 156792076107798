import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText/ListItemText";
import { Link } from "react-router-dom";
import { IProfileErrorGroup } from "./index";
import ListItemIcon from "@mui/material/ListItemIcon/ListItemIcon";
import ErrorIcon from "@mui/icons-material/Error";
import { Link as MuiLink, List, Typography } from "@mui/material";

export default function ProfileErrorGroup({
  item,
}: {
  item: IProfileErrorGroup;
}) {
  return (
    <>
      <ListItem>
        <ListItemIcon sx={{ minWidth: 35, color: "red" }}>
          <ErrorIcon />
        </ListItemIcon>
        <ListItemText>
          <Typography variant="h6">{item.name}</Typography>
        </ListItemText>
      </ListItem>
      <ListItem sx={{ paddingTop: 0 }}>
        <List sx={{ paddingLeft: 4, paddingTop: 0, listStyleType: "disc" }}>
          {item.errors.map((error, errorIndex) => (
            <ListItem
              key={errorIndex}
              sx={{ display: "list-item", paddingBottom: 1 }}
              disablePadding
            >
              <ListItemText>
                <MuiLink
                  to={error.link}
                  underline={"always"}
                  component={Link}
                  reloadDocument={error.link.endsWith("/schedule")}
                >
                  {error.message}
                </MuiLink>
              </ListItemText>
            </ListItem>
          ))}
        </List>
      </ListItem>
    </>
  );
}
