import { useEffect, useContext } from "react";
import { useFormik, FormikConfig, FormikValues } from "formik";
import { SignUpContext } from "../context";
import { toCamel } from "../utils";

export const useCustomFormik = (config: FormikConfig<FormikValues>) => {
  const formik = useFormik(config);
  const { errors } = useContext(SignUpContext);
  useEffect(() => {
    Object.entries(errors).forEach(([key, value]) => {
      if (value?.length) {
        formik.setFieldError(toCamel(key), value[0]);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);
  return formik;
};
