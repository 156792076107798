import React from "react";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";

import { AdvertiserItem } from "../types";

export default function PreviousAdvertiserItem({
  optionProps,
  item,
  onRemove,
}: {
  optionProps?: React.HTMLAttributes<HTMLLIElement>;
  item: AdvertiserItem;
  onRemove?: (itemId: number | undefined) => void;
}) {
  const secondaryAction =
    onRemove && item.id !== undefined ? (
      <IconButton
        edge="end"
        aria-label="delete"
        onClick={() => onRemove(item.id)}
      >
        <DeleteIcon />
      </IconButton>
    ) : null;

  return (
    <ListItem {...optionProps} secondaryAction={secondaryAction}>
      <ListItemAvatar>
        <Avatar src={item.logo} />
      </ListItemAvatar>
      <ListItemText primary={item.name} />
    </ListItem>
  );
}
