import cls from "classnames";
import axios from "axios";
import { useState } from "react";
import { Box, Chip, useTheme } from "@mui/material";

export type Hit = {
  objectID: string;
  slug: string;
  "is_top_rated?": boolean;
  "is_verified?": boolean;
  logo_url: string;
  name: string;
  category_names: string[] | null;
  description: string;
  max_discount: number;
  search_badge_background_color: string | null;
  search_badge_end_date: string | null;
  search_badge_text: string | null;
  search_badge_text_color: string | null;
};
export type HitProps = {
  hit: Hit;
  favorites: number[];
};

function isInFutureOrToday(dateString: string | null) {
  if (!dateString) return true;
  const givenDate = new Date(dateString);
  givenDate.setHours(0, 0, 0, 0);

  const today = new Date();
  today.setHours(0, 0, 0, 0);
  return givenDate.getTime() >= today.getTime();
}

export default function HitComponent({ hit, favorites }: HitProps) {
  const {
    objectID,
    slug,
    logo_url,
    name,
    category_names,
    description,
    max_discount,
    search_badge_background_color,
    search_badge_end_date,
    search_badge_text,
    search_badge_text_color,
  } = hit;

  const theme = useTheme();

  const [isFavorite, setIsFavorite] = useState(
    favorites.includes(parseInt(objectID))
  );

  const toggleFavorite = () => {
    setIsFavorite(!isFavorite);

    axios({
      method: isFavorite ? "delete" : "post",
      url: `/api/v1/sites/${slug}/favorites`,
    });
  };

  return (
    <div className="item item-half">
      <div
        data-id={objectID}
        className={cls("card", {
          liked: isFavorite,
        })}
      >
        <a className="link" href={`/sites/${slug}`}>
          <div
            className={cls("logo", {
              top_rated_icon: hit["is_top_rated?"],
              verified: hit["is_verified?"],
            })}
          >
            <img src={logo_url} width="60" height="60" alt={name} />
          </div>

          <strong className="company">{name}</strong>
          <Box display="flex" gap={1} alignItems="center" mb={1} mt={1}>
            {category_names && category_names.length > 0 && (
              <p className="specialization">{category_names}</p>
            )}
            {!!max_discount && (
              <Chip
                label={`SAVE ${max_discount}%`}
                size="small"
                sx={{
                  bgcolor: "#3DB95F",
                  color: "#fff",
                  height: "20px",
                  ".MuiChip-label": { px: "18px" },
                  fontWeight: 500,
                  [theme.breakpoints.down("lg")]: {
                    ".MuiChip-label": { px: "9px" },
                  },
                }}
              />
            )}
          </Box>
          <p
            className={`description line-clamp-${
              (category_names && category_names.length > 0) || !!max_discount
                ? "2"
                : "3"
            }`}
          >
            {description}
          </p>
        </a>
        <div className="like favorite_icon" onClick={toggleFavorite}></div>
        {search_badge_text && isInFutureOrToday(search_badge_end_date) && (
          <Chip
            label={search_badge_text}
            size="small"
            sx={{
              bgcolor: `${search_badge_background_color}`,
              color: `${search_badge_text_color}`,
              height: "20px",
              fontWeight: 500,
              borderRadius: 0,
              ml: "auto",
              position: "absolute",
              right: 0,
              bottom: "10px",
            }}
          />
        )}
      </div>
    </div>
  );
}
