import { IUser, SegmentDataLayer, SpecWindow } from "../types";
import { isAdmin } from "utils/authorize";

let segmentDataLayer: SegmentDataLayer;

export function storeSegmentLayer(userData: IUser, segmentsData: any) {
  const { userType, firstName, lastName, email, companyName } = userData;
  let { ro_id: id, created_at: created_ts } = segmentsData;

  const created = new Date(created_ts * 1_000).toISOString();

  segmentDataLayer = {
    state: "logged in",
    email,
    first_name: firstName,
    last_name: lastName,
    firstName,
    lastName,
    name: `${firstName} ${lastName}`,
    id,
    type: userType!,
    company_name: companyName!,
    company: companyName!,
    created,
    created_ts,
  };
}

export function sendSegmentsEvent(companyName: string) {
  try {
    if (isAdmin()) {
      return;
    }

    const useSegments = "analytics" in window;
    if (!useSegments) {
      return;
    }

    segmentDataLayer.company = companyName;
    segmentDataLayer.company_name = companyName;

    (window as SpecWindow).analytics.identify?.(
      segmentDataLayer.id,
      segmentDataLayer
    );
    (window as SpecWindow).analytics.track?.(
      `${segmentDataLayer.type}_signed_up`
    );
  } catch (e) {
    console.error(e);
  }
}
