import React from "react";
import { Box, Typography } from "@mui/material";
import { MonetizationOn } from "@mui/icons-material";
import { EventContentArg } from "@fullcalendar/core";
import { SponsorshipType } from "./types";

interface CalendarEventProps {
  event: EventContentArg["event"];
}

const CalendarEvent: React.FC<CalendarEventProps> = ({ event }) => {
  const {
    title,
    extendedProps: { reserved, sponsorship_type, custom_price },
  } = event;

  if (!event.id) return null;

  let eventClassName = "event-open";
  if (reserved) {
    eventClassName = "event-reserved";
  } else if (sponsorship_type === SponsorshipType.DEDICATED_EMAIL) {
    eventClassName = "dedicated-email";
  }

  let textColor = "success.main";
  if (reserved) {
    textColor = "error.main";
  } else if (sponsorship_type === SponsorshipType.DEDICATED_EMAIL) {
    textColor = "primary.main";
  }

  const textDecoration = reserved ? "line-through" : "none";

  return (
    <Box
      className={eventClassName}
      sx={{
        whiteSpace: "normal",
        display: "flex",
        alignItems: "center",
        height: "100%",
        borderRadius: "4px",
      }}
    >
      <Typography
        noWrap
        variant="body2"
        color={textColor}
        sx={{
          wordBreak: "break-word",
          padding: "2px",
          fontSize: "12px",
          textDecoration: textDecoration,
        }}
      >
        {title}
      </Typography>
      {custom_price && (
        <MonetizationOn
          color="success"
          sx={{ fontSize: "16px", textAlign: "baseline" }}
        />
      )}
    </Box>
  );
};

export default CalendarEvent;
