import { Link } from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";

export default function CampaignBreadCrumbs({
  lastItem,
}: {
  lastItem: string;
}) {
  return (
    <Breadcrumbs aria-label="breadcrumb">
      <Link href="/advertiser/campaigns" underline="none">
        Sponsorships
      </Link>
      <Typography color="text.primary">{lastItem}</Typography>
    </Breadcrumbs>
  );
}
