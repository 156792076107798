import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import { ICreativeRequirement } from "types";

import { faImage } from "@fortawesome/pro-light-svg-icons";

import AssetInfo from "./components/AssetInfo";
import AssetPreview from "./components/AssetPreview";
import { AssetsLibrary } from "./components/AssetsLibrary";
import CardTop from "./components/CardTop";
import Wrapper from "./components/Wrapper";

const IMAGE_UPLOAD_PLACEHOLDER_TEXT = "Upload an image for your sponsorship";

export interface Asset {
  id: string;
  image: string;
  thumb: string;
  width: number;
  height: number;
  file_size: number;
  file_name: string;
}

export type FileData = string | Blob | null;

export default function CreativeItemImageField({
  creativeItem,
  onImageChange,
  error,
}: {
  creativeItem: ICreativeRequirement;
  onImageChange: (fileOrUrl: FileData) => void;
  error?: string;
}) {
  const [imgFile, setImgFile] = useState<File | null>(null);
  const [imgSrcUrl, setImgSrcUrl] = useState<FileData>(null);
  const [newFilename, setNewFilename] = useState<string | undefined>();
  const [assetsLibraryOpen, setAssetsLibraryOpen] = useState(false);

  const { name, uuid, width, height } = creativeItem;
  const handleImageSelect = (image: File | Asset) => {
    const isFile = image instanceof File;

    setNewFilename(isFile ? image.name : image.id);
    setImgFile(isFile ? image : null);
    setImgSrcUrl(isFile ? null : image.image);
    onImageChange(isFile ? image : image.image);
  };

  useEffect(() => {
    setImgFile(null);
    setImgSrcUrl(creativeItem.value || null);
  }, [creativeItem]);

  return !width || !height ? null : (
    <>
      <CardTop uuid={uuid} name={name} width={width} height={height} />
      <Wrapper>
        <AssetPreview imgSrcUrl={imgSrcUrl} imgFile={imgFile} icon={faImage} />
        <AssetInfo
          newFilename={newFilename}
          srcUrl={imgSrcUrl?.toString()}
          placeholder={IMAGE_UPLOAD_PLACEHOLDER_TEXT}
          extension="PNG, JPG, JPEG or GIF"
        />
        <div style={{ marginLeft: "10px" }}>
          <Button
            variant="contained"
            onClick={() => setAssetsLibraryOpen(true)}
          >
            {!!imgSrcUrl || !!imgFile ? "Change" : "Asset Library"}
          </Button>
        </div>
      </Wrapper>

      <AssetsLibrary
        open={assetsLibraryOpen}
        setAssetsLibraryOpen={setAssetsLibraryOpen}
        handleImageSelect={handleImageSelect}
        requiredWidth={width}
        requiredHeight={height}
        uuid={uuid}
      />
    </>
  );
}
