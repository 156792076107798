import React from "react";
import { Paper, Typography } from "@mui/material";
import { currencyFormatter } from "utils/formatters";

export default function PaymentInfoCard({
  caption,
  value,
}: {
  caption: string;
  value: number;
}) {
  return (
    <Paper
      sx={{
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
        p: 1,
      }}
    >
      <Typography variant="subtitle2" sx={{ fontWeight: "bold" }} gutterBottom>
        {caption}
      </Typography>
      <Typography sx={{ fontWeight: "bold" }} variant="h3">
        {currencyFormatter(value, 0)}
      </Typography>
    </Paper>
  );
}
