import React, { useContext, useMemo, useState } from "react";
import StepCaption from "components/StepCaption";
import { SignUpContext } from "../context";
import { useAxios, useCustomFormik } from "../hooks";
import * as Yup from "yup";
import noop from "lodash/noop";
import SignUpSelect from "./SignUpSelect";
import SignUpField from "./SignUpField";
import SignButton from "components/SignButton";
import { CircularProgress } from "@mui/material";
import { SelectOption } from "../types";

interface IOption {
  value: string;
  label: string;
}

const FormSchema = Yup.object().shape({
  newsletter_name: Yup.string().required("Newsletter name is required."),
  subscribers: Yup.string().required("Subscribers count is required."),
  category: Yup.string().required("Newsletter topic is required."),
  frequency: Yup.string().required("Frequency is required."),
});

export default function NewsLetterDetailsForm() {
  const { onboardingValues } = useContext(SignUpContext);
  const { requestApi } = useAxios();
  const [loading, setLoading] = useState(false);

  const frequencyOptions = useMemo(() => {
    return onboardingValues
      ? onboardingValues.frequency.map(
          (item: string): SelectOption => ({
            value: item,
            label: item,
          })
        )
      : [];
  }, [onboardingValues]);

  const topicOptions = useMemo(() => {
    return onboardingValues
      ? onboardingValues.categories.map(
          (item: string): SelectOption => ({
            value: item,
            label: item,
          })
        )
      : [];
  }, [onboardingValues]);

  const subscribersQtyOptions = useMemo(() => {
    return onboardingValues
      ? onboardingValues.subscribers.map(
          (item: string): SelectOption => ({
            value: item,
            label: item,
          })
        )
      : [];
  }, [onboardingValues]);

  const formik = useCustomFormik({
    initialValues: {
      newsletter_name: "",
      subscribers: undefined,
      category: undefined,
      frequency: undefined,
    },
    validationSchema: FormSchema,
    onSubmit: (values) => {
      setLoading(true);
      localStorage.setItem("onboardingNewsLetterName", values.newsletter_name);
      requestApi("patch", "/api/v1/users/onboarding", values)
        .then(() => (window.location.pathname = "/publisher/sites"))
        .catch(noop)
        .finally(() => {
          setLoading(false);
        });
    },
  });

  // TODO: common helper
  const getErrorMessage = (fieldName: string) =>
    formik.touched[fieldName]
      ? formik.errors[fieldName]?.toString()
      : undefined;

  return (
    <div>
      <StepCaption
        heading="Please tell us a little bit about your newsletter"
        subheading="If you have multiple newsletters, enter the details for your largest or main newsletter"
      />
      <form className="news-letter-form" onSubmit={formik.handleSubmit}>
        <SignUpField
          name="newsletter_name"
          value={formik.values.newsletter_name}
          caption="Enter name"
          errorMessage={getErrorMessage("newsletter_name")}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          labelPosition="top"
          labelText="What is your newsletter name?"
        />
        <SignUpSelect
          options={subscribersQtyOptions}
          placeholder="Select"
          onChange={(selectedOption: unknown) => {
            const value = (selectedOption as IOption).value;
            formik.setFieldValue("subscribers", value);
          }}
          onBlur={() => {
            formik.setTouched({ subscribers: true });
          }}
          errorMessage={getErrorMessage("subscribers")}
          name="subscribers"
          labelText="How many subscribers do you have?"
        />
        <SignUpSelect
          options={topicOptions}
          placeholder="Select"
          onChange={(selectedOption: unknown) => {
            const value = (selectedOption as IOption).value;
            formik.setFieldValue("category", value);
          }}
          onBlur={() => {
            formik.setTouched({ category: true });
          }}
          errorMessage={getErrorMessage("category")}
          name="category"
          labelText="Select the topic of your newsletter"
        />
        <SignUpSelect
          options={frequencyOptions}
          placeholder="Select"
          onChange={(selectedOption: unknown) => {
            const value = (selectedOption as IOption).value;
            formik.setFieldValue("frequency", value);
          }}
          onBlur={() => {
            formik.setTouched({ frequency: true });
          }}
          errorMessage={getErrorMessage("frequency")}
          name="frequency"
          labelText="How frequently do you send your newsletter?"
        />
        <SignButton
          type="submit"
          variant="contained"
          fullWidth
          size="large"
          disabled={loading}
          endIcon={
            loading ? (
              <CircularProgress size={12} sx={{ color: "#fff" }} />
            ) : null
          }
        >
          Continue
        </SignButton>
      </form>
    </div>
  );
}
