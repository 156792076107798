export function getFirstErrorElement() {
  return document.querySelector('[data-error-element="true"]');
}

export function scrollToFirstError() {
  getFirstErrorElement()?.scrollIntoView({
    behavior: "smooth",
    block: "center",
    inline: "start",
  });
}
