import React, { useState } from "react";
import Select, { MultiValue, InputActionMeta } from "react-select";
import makeAnimated from "react-select/animated";
import { InputLabel, CircularProgress } from "@mui/material";
import axios from "axios";
import debounce from "lodash/debounce";

const animatedComponents = makeAnimated();

interface Props {
  value: string[];
  description: string;
  setFieldValue: (field: string, value: MultiValue<string>) => void;
}

interface TagItem {
  label: string;
  value: string;
}

const requestTagsList = debounce(
  (
    tags: string[],
    query: string,
    description: string,
    setTagsList: (data: TagItem[]) => void,
    setLoading: (value: boolean) => void
  ) => {
    setTagsList([]);
    setLoading(true);
    axios
      .get("/api/v1/publisher/tags", {
        params: {
          q: query,
          tags: tags.join(","),
          description,
        },
      })
      .then((response) => {
        const newItems = response.data.map((item: string) => ({
          label: item,
          value: item,
        }));
        setTagsList(newItems);
      })
      .finally(() => setLoading(false));
  },
  300
);

export default function TagSelect({
  value,
  description,
  setFieldValue,
}: Props) {
  const [tagsList, setTagsList] = useState<TagItem[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchParam, setSearchParam] = useState<string>("");

  const getTagList = (tags: string[], query = searchParam) => {
    requestTagsList(tags, query, description, setTagsList, setLoading);
  };

  const onChange = (newValue: MultiValue<any>) => {
    const updatedValue = newValue.map((item) => item.value);
    setFieldValue("tag_list", updatedValue);
    setSearchParam("");
    getTagList(updatedValue, "");
  };

  const onInputChange = (
    inputValue: string,
    { action, prevInputValue }: InputActionMeta
  ) => {
    if (action === "input-change") {
      setSearchParam(inputValue);
      getTagList(value, inputValue);
      return inputValue;
    }
    if (action === "menu-close") {
      setSearchParam("");
      return "";
    }
    return prevInputValue;
  };

  const filterOptions = () => {
    return true;
  };

  return (
    <>
      <InputLabel htmlFor="tagList">
        What tags best describe your site and/or newsletter?
      </InputLabel>
      <Select
        name="tag_list"
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            neutral20: "rgba(0,40,100,.12)",
            neutral30: "#495057",
          },
        })}
        styles={{
          control: (styles) => ({
            ...styles,
            minHeight: "35px",
          }),
          valueContainer: (styles) => ({
            ...styles,
            padding: "0 8px",
          }),
          input: (styles) => ({
            ...styles,
            margin: 0,
          }),
          multiValue: (styles) => ({
            ...styles,
            height: "25px",
          }),
          multiValueLabel: (styles) => ({
            ...styles,
            color: "#495057",
            padding: "0 3px",
          }),
          dropdownIndicator: (styles) => ({
            ...styles,
            padding: "0 8px",
            color: "rgba(0, 0, 0, 0.54)",
            ":hover": {
              color: "rgba(0, 0, 0, 0.54)",
            },
          }),
        }}
        isLoading={loading}
        isClearable={false}
        isSearchable={true}
        inputValue={searchParam}
        closeMenuOnSelect={false}
        components={{
          ...animatedComponents,
          LoadingIndicator: () => (
            <CircularProgress
              color="inherit"
              size={20}
              sx={{ marginRight: 1 }}
            />
          ),
        }}
        value={value.map((item) => ({ label: item, value: item }))}
        isMulti
        options={tagsList}
        filterOption={filterOptions}
        onChange={onChange}
        onMenuOpen={() => getTagList(value)}
        onInputChange={onInputChange}
      />
    </>
  );
}
