import Decimal from "decimal.js";

import type { DateStringYYYYDashMMDashDD } from "./types";

export function calculatePayoutAndFee(value: number, pavedFee: number): string {
  if (!value) {
    return "$0";
  }

  const bdValue = new Decimal(value);
  const bdPavedFee = new Decimal(pavedFee);

  const newPayout = bdValue
    .times(new Decimal(100).minus(bdPavedFee).div(100))
    .toDecimalPlaces(2, Decimal.ROUND_UP);

  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(newPayout.toNumber());
}

export const formatCurrency = (value: number) =>
  new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(value);

export const formatDate = (dateString: DateStringYYYYDashMMDashDD) =>
  new Date(dateString).toLocaleDateString("en-US", {
    weekday: "short",
    month: "short",
    day: "numeric",
    year: "numeric",
  });

export const formatCurrencyNoDecimals = (value: number) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(value);
};
