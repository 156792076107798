import React from "react";
import { FormHelperText } from "@mui/material";
import PayoutCalculator from "./payoutCalculator/PayoutCalculator";

interface SponsorshipPricingProps {
  values: {
    smart_pricing_enabled: boolean;
    published_price: number;
    smart_pricing_lowest_price: number;
  };
  errors: {
    published_price?: string;
    smart_pricing_lowest_price?: string;
  };
  prefix?: string;
  touched: {
    published_price?: boolean;
    smart_pricing_lowest_price?: boolean;
  };
  onChange: (e: React.ChangeEvent<any>) => void;
  onBlur: (e: React.FocusEvent<any>) => void;
  smartPricingDisabled: boolean;
  type: "dedicated_email" | "sponsored_email" | "sponsored_post";
  onPriceBlur?: (e: React.FocusEvent<any>) => void;
}

const SponsorshipPricing: React.FC<SponsorshipPricingProps> = ({
  values,
  errors,
  prefix,
  touched,
  onChange,
  onBlur,
  smartPricingDisabled,
  type,
  onPriceBlur,
}) => {
  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    onBlur(e);
    onPriceBlur?.(e);
  };

  return (
    <>
      <PayoutCalculator
        name={prefix ? `${prefix}.published_price` : "published_price"}
        label={
          values.smart_pricing_enabled
            ? "Regular Sponsorship Price"
            : "Sponsorship Price"
        }
        value={values.published_price}
        error={errors.published_price}
        touched={touched.published_price}
        type={type}
        onChange={onChange}
        onBlur={handleBlur}
      />
      {values.smart_pricing_enabled && (
        <FormHelperText>This is your standard price.</FormHelperText>
      )}
      {values.smart_pricing_enabled && (
        <>
          <PayoutCalculator
            name={
              prefix
                ? `${prefix}.smart_pricing_lowest_price`
                : "smart_pricing_lowest_price"
            }
            label="Minimum Sponsorship Price"
            value={values.smart_pricing_lowest_price}
            error={errors.smart_pricing_lowest_price}
            touched={touched.smart_pricing_lowest_price}
            type={type}
            onChange={onChange}
            onBlur={onBlur}
            disabled={smartPricingDisabled}
          />
          <FormHelperText sx={{ mb: 2 }}>
            This is the lowest price you're willing to accept.
          </FormHelperText>
        </>
      )}
    </>
  );
};

export default SponsorshipPricing;
