import React from "react";
import SideMenuItem from "components/SideMenuItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartBar } from "@fortawesome/pro-regular-svg-icons";
import { listItemTextSX } from "./SideMenu";
import { useParams } from "react-router-dom";

export default function ReportingMenuItem({
  statusName,
}: {
  statusName: string;
}) {
  const { campaignId } = useParams();

  return (
    <SideMenuItem
      caption="Reporting"
      iconComponent={<FontAwesomeIcon icon={faChartBar} />}
      to={`/advertiser/campaigns/${campaignId}/report`}
      disable={statusName !== "Sent"}
      listItemTextSX={listItemTextSX}
      nativeLink={true}
      tooltipTitle={
        statusName !== "Sent"
          ? "Reporting is available after the campaign is completed"
          : ""
      }
    />
  );
}
