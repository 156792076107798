import { useEffect, useCallback, useMemo } from "react";
import debounce from "lodash/debounce";
import axios from "axios";
import { setAxiosAuth } from "../utils/auth";

const useSearchAnalytics = (query: string, delay: number = 1500) => {
  useEffect(() => {
    setAxiosAuth();
  }, []);

  const handleSearchQueryChange = useCallback((searchQuery: string) => {
    axios.post("/api/v1/search", { query: searchQuery });
  }, []);

  const debouncedSearch = useMemo(
    () =>
      debounce((searchQuery: string) => {
        if (searchQuery) handleSearchQueryChange(searchQuery);
      }, delay),
    [handleSearchQueryChange, delay],
  );

  useEffect(() => {
    debouncedSearch(query);
    return () => {
      debouncedSearch.cancel();
    };
  }, [query, debouncedSearch]);
};

export default useSearchAnalytics;
