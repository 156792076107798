import React, { useContext, useMemo, useState } from "react";
import { CircularProgress } from "@mui/material";
import * as Yup from "yup";

import { SignUpContext } from "../context";
import { useAxios, useCustomFormik } from "../hooks";
import SignButton from "components/SignButton";
import StepCaption from "components/StepCaption";
import SignUpCheckBox from "./SignUpCheckBox";
import SignUpSelect from "./SignUpSelect";
import noop from "lodash/noop";
import { useNavigate } from "react-router-dom";
import { SelectOption } from "../types";

interface ICampaignBudgetOption {
  value: string;
  label: string;
}

const FormSchema = Yup.object().shape({
  goals: Yup.array().min(1, "Please select your advertising goals"),
  budget: Yup.string().required("Please select your ideal campaign budget."),
});

export default function GoalsAndBudgetForm() {
  const { userData, onboardingValues, setUserData } = useContext(SignUpContext);
  const navigate = useNavigate();
  const { requestApi } = useAxios();
  const [loading, setLoading] = useState(false);
  const formik = useCustomFormik({
    initialValues: {
      goals: [],
      budget: undefined,
    },
    validationSchema: FormSchema,
    onSubmit: (values) => {
      const body = {
        company_name: userData.companyName,
        url: userData.url,
        goals: values.goals,
        budget: values.budget,
      };
      setLoading(true);
      requestApi("patch", "/api/v1/users/onboarding", body)
        .then(() => {
          setUserData({
            ...userData,
            ...values,
          });
          navigate("/users/onboarding/complete");
        })
        .catch(noop)
        .finally(() => {
          setLoading(false);
        });
    },
  });

  const goalTypes = useMemo(() => {
    return onboardingValues ? onboardingValues.goals : [];
  }, [onboardingValues]);

  const campaignBudgetTypes = useMemo(() => {
    return onboardingValues ? onboardingValues.budget : [];
  }, [onboardingValues]);

  const campaignBudgetOptions = campaignBudgetTypes.map(
    (budgetType: string): SelectOption => ({
      value: budgetType,
      label: budgetType,
    })
  );

  const getErrorMessage = (fieldName: string) =>
    formik.touched[fieldName]
      ? formik.errors[fieldName]?.toString()
      : undefined;

  return (
    <div>
      <StepCaption heading="What are your goals?" />
      <form onSubmit={formik.handleSubmit}>
        <div className="type">
          {goalTypes.map((type, index) => (
            <SignUpCheckBox
              key={type}
              name="goals[]"
              value={type}
              errorMessage={
                index === goalTypes.length - 1
                  ? getErrorMessage("goals")
                  : undefined
              }
              onChange={(e: React.FormEvent<HTMLInputElement>) => {
                const checked = e.currentTarget.checked;
                const values = [...formik.values.goals];
                if (checked) {
                  values.push(e.currentTarget.value);
                  formik.setFieldValue("goals", values);
                } else {
                  const newValues = values.filter(
                    (value) => value !== e.currentTarget.value
                  );
                  formik.setFieldValue("goals", newValues);
                }
              }}
            />
          ))}
        </div>
        <SignUpSelect
          options={campaignBudgetOptions}
          placeholder="Ideal Campaign Budget"
          onChange={(selectedOption: unknown) => {
            const value = (selectedOption as ICampaignBudgetOption).value;
            formik.setFieldValue("budget", value);
          }}
          onBlur={() => {
            formik.setTouched({ budget: true });
          }}
          errorMessage={getErrorMessage("budget")}
        />
        <SignButton
          type="submit"
          variant="contained"
          fullWidth
          size="large"
          disabled={loading}
          endIcon={
            loading ? (
              <CircularProgress size={12} sx={{ color: "#fff" }} />
            ) : null
          }
        >
          Continue
        </SignButton>
      </form>
    </div>
  );
}
