import React, { useState } from "react";
import * as yup from "yup";
import { Box, Button, IconButton, Modal, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Form, Formik, FormikHelpers } from "formik";
import SignInField from "./SignInField";
import axios from "axios";

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email("Must be a valid e-mail")
    .required("E-mail is required."),
});

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 480,
  maxWidth: "100%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 7,
  borderRadius: 0.5,
};

interface Props {
  open: boolean;
  handleClose: () => void;
  email: string;
}

const ResetPassword: React.FC<Props> = ({ open, handleClose, email }) => {
  const [resetRequested, setResetRequested] = useState<boolean>(false);

  const handleSubmit = (
    data: { email: string },
    actions: FormikHelpers<{ email: string }>
  ) => {
    const url = "/api/v1/users/send_reset_password_instructions";

    axios
      .post(url, {
        ...data,
      })
      .then(() => {
        setResetRequested(true);
      })
      .catch(({ response }) => {
        if (response?.data?.errors) {
          const { errors } = response?.data;

          if (typeof errors === "object") {
            return actions.setErrors(errors);
          }
        }
      })
      .finally(() => actions.setSubmitting(false));
  };

  const onClose = () => {
    if (resetRequested) setResetRequested(false);
    handleClose();
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box position="absolute" top={12} right={12}>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        {resetRequested ? (
          <Box display="flex" alignItems="center" flexDirection="column">
            <img
              className="reset-password-confirm-img"
              alt="request has been sent"
            />
            <Typography
              variant="h5"
              component="h2"
              textAlign="center"
              sx={{ marginBottom: 1.5, fontWeight: 500, color: "#0e1952" }}
            >
              Hang tight!
            </Typography>
            <Typography textAlign="center">
              We just sent you a password reset email. It should be with you in
              a moment.
            </Typography>
          </Box>
        ) : (
          <Formik
            initialValues={{ email }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ errors, isSubmitting, touched, handleBlur }) => (
              <Form className="new_user">
                <Typography
                  variant="h5"
                  component="h2"
                  textAlign="center"
                  sx={{ marginBottom: 1.5, fontWeight: 500, color: "#0e1952" }}
                >
                  Forgot your password?
                </Typography>
                <Typography paddingBottom={2.5} textAlign="center">
                  Don't worry, we'll send you a link to&nbsp;reset your
                  password.
                </Typography>
                <SignInField
                  name="email"
                  touched={touched}
                  errors={errors}
                  type="email"
                  onBlur={handleBlur}
                />
                <div className="form-submit">
                  <Button
                    type="submit"
                    id="reset-passowrd-submit"
                    variant="contained"
                    size="large"
                    fullWidth
                    disabled={isSubmitting}
                    sx={{
                      backgroundColor: "primary.dark",
                    }}
                  >
                    Send me the link
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </Box>
    </Modal>
  );
};

export default ResetPassword;
