import React from "react";

import Box from "@mui/material/Box";
import List from "@mui/material/List";
import SideMenuItem from "components/SideMenuItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAd,
  faInfoSquare,
  faCommentDots,
} from "@fortawesome/pro-regular-svg-icons";
import { useParams } from "react-router-dom";
import ReportingMenuItem from "./ReportingMenuItem";

export const listItemTextSX = { fontSize: "15px" };

export default function SideMenu({
  statusName,
}: {
  statusName: string | undefined;
}) {
  const { campaignId } = useParams();

  return (
    <Box>
      <nav>
        <List component={"div"}>
          <SideMenuItem
            caption="Details"
            iconComponent={<FontAwesomeIcon icon={faInfoSquare} />}
            to={`/advertiser/campaigns/${campaignId}`}
            nativeLink={true}
            listItemTextSX={listItemTextSX}
          />
          <SideMenuItem
            caption="Creative"
            iconComponent={<FontAwesomeIcon icon={faAd} />}
            to={`/advertiser/campaigns/${campaignId}/creative/edit`}
            listItemTextSX={listItemTextSX}
          />
          <SideMenuItem
            caption="Chat"
            iconComponent={<FontAwesomeIcon icon={faCommentDots} />}
            to={`/advertiser/campaigns/${campaignId}/chat`}
            nativeLink={true}
            listItemTextSX={listItemTextSX}
          />
          {!!statusName && <ReportingMenuItem statusName={statusName} />}
        </List>
      </nav>
    </Box>
  );
}
