import React, { useContext } from "react";
import { Button, Menu } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import PositionedMenuItem from "./PositionedMenuItem";
import { IPublisher } from "./types";
import { UserContext } from "context";

// TODO: rename publisher >> siteData
export default function PositionedMenu({
  publisher,
}: {
  publisher: IPublisher;
}) {
  const { userData } = useContext(UserContext);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const {
    show_configure_ad_network,
    show_confirm_details,
    show_set_availability,
    status,
    slug,
    description,
    email_ads,
  } = publisher;

  // TODO:  <% if site[:status] == 'active' %> need to discuss
  const isActive = () => status.toLowerCase() === "live";

  //  (last item)
  return (
    <div>
      <Button
        id="demo-customized-button"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="outlined"
        disableElevation
        onClick={handleClick}
        endIcon={<ArrowDropDownIcon />}
        sx={{
          color: "text.primary",
          textTransform: "capitalize",
          fontSize: "0.8125rem",
        }}
      >
        Options
      </Button>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {show_confirm_details && (
          <PositionedMenuItem
            to={`/sites/confirm/${slug}`}
            text="Confirm Details"
            onClick={handleClose}
            nativeLink={true}
          />
        )}
        <PositionedMenuItem
          to={`/publisher/sites/${slug}/edit`}
          text={!!description ? "Edit Profile" : "Create Profile"}
          onClick={handleClose}
        />
        {isActive() && (
          <PositionedMenuItem
            to={`/sites/${slug}`}
            text="View Profile"
            onClick={handleClose}
            nativeLink={true}
          />
        )}
        {show_set_availability && (
          <PositionedMenuItem
            to={`/publisher/sites/${slug}/schedule`}
            text="Set Availability"
            onClick={handleClose}
            nativeLink={true}
          />
        )}
        {isActive() && (
          <PositionedMenuItem
            to={`/publisher/sites/${slug}/reviews`}
            text="Manage Reviews"
            onClick={handleClose}
            nativeLink={true}
          />
        )}
        {userData.has_ad_network_access && show_configure_ad_network && (
          <PositionedMenuItem
            to={`/publisher/programmatic/sites/${slug}/configure`}
            text="Configure Ad Network"
            onClick={handleClose}
            nativeLink={true}
          />
        )}
        {userData.has_ad_network_access && !show_configure_ad_network && (
          <PositionedMenuItem
            to={`/publisher/programmatic/sites/${slug}/instructions`}
            text="Ad Network Settings"
            onClick={handleClose}
            nativeLink={true}
          />
        )}
        {email_ads && userData.has_booker_access && (
          <PositionedMenuItem
            to={`/publisher/sites/${slug}/booker`}
            text="Booker Settings"
            onClick={handleClose}
            nativeLink={true}
          />
        )}
      </Menu>
    </div>
  );
}
