import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";

import { Frequency, RRule, RRuleSet, rrulestr, Weekday } from "rrule";

import Daily from "./Daily";
import Weekly from "./Weekly";
import Monthly from "./Monthly";
import useComponentDidMount from "../../hooks/useComponentDidMount";
import { Close } from "@mui/icons-material";

import {
  convertBymonthweekdayToBynweekday,
  getBynweekdayTextArr,
  getMontByWeekRRuleStr,
} from "./utils";

export type MonthlyGridType = "daysOfWeek" | "daysOfMonth";
export const DAYS_OF_WEEK = [
  RRule.SU,
  RRule.MO,
  RRule.TU,
  RRule.WE,
  RRule.TH,
  RRule.FR,
  RRule.SA,
];

export const weeks = ["01", "02", "03", "04"];
export const weeksCaptions = ["1st", "2nd", "3rd", "4th"];

export type NewsletterScheduleDialogProps = {
  open: boolean;
  onClose: () => void;
  onChange: (newRRule: string) => void;
  data?: string;
};

function getSummary(rruleText: JSX.Element): JSX.Element {
  return (
    <Box>
      <Typography
        color="text.secondary"
        variant="body1"
        fontWeight="bold"
        display="inline"
      >
        {"Summary: "}
      </Typography>
      {rruleText}
    </Box>
  );
}

export default function NewsletterScheduleDialog({
  open,
  onClose,
  onChange,
  data,
}: NewsletterScheduleDialogProps) {
  const [frequency, setFrequency] = useState<number>(Frequency.DAILY);
  const [dtstart, setDtstart] = useState<Date | null>(null);

  const [dailyInterval, setDailyInterval] = useState<number>(1);
  const [weeklyInterval, setWeeklyInterval] = useState<number>(1);
  const [monthlyInterval, setMonthlyInterval] = useState<number>(1);

  const [byweekday, setByweekday] = useState<number[]>([]);
  const [bymonthday, setBymonthday] = useState<number[]>([]);
  const [bymonthweekday, setBymonthweekday] = useState<string[]>([]);

  const [monthlyGridType, setMonthlyGridType] =
    useState<MonthlyGridType>("daysOfMonth");

  useComponentDidMount(() => {
    if (!data) {
      return;
    }

    const rule = rrulestr(data);

    if (rule instanceof RRuleSet) {
      const rrules = rule.rrules();
      const { freq, interval } = rrules[0].options;

      if (rrules[0].origOptions.dtstart) {
        setDtstart(rrules[0].options.dtstart);
      }

      setFrequency(freq);
      setMonthlyInterval(interval);
      setMonthlyGridType("daysOfWeek");

      const bymonthweekday = rrules.reduce<string[]>((acc, item) => {
        const { bysetpos, byweekday } = item.options;
        const week = bysetpos[0];
        const weekdays = byweekday.map(
          (day) => `0${week},${new Weekday(day).toString()}`
        );
        acc.push(...weekdays);
        return acc;
      }, []);

      setBymonthweekday(bymonthweekday);

      return;
    }

    const { freq, interval, bymonthday, bynmonthday, bynweekday } =
      rule.options;

    if (rule.origOptions.dtstart) {
      setDtstart(rule.options.dtstart);
    }

    setFrequency(freq);

    if (freq === Frequency.DAILY) {
      setDailyInterval(interval);
    }

    if (freq === Frequency.WEEKLY) {
      setWeeklyInterval(interval);
      setByweekday(rule.options.byweekday);
    }

    if (
      freq === Frequency.MONTHLY &&
      (bymonthday?.length || bynmonthday?.length)
    ) {
      setMonthlyInterval(interval);
      setBymonthday([...rule.options.bymonthday, ...rule.options.bynmonthday]);
    }

    if (freq === Frequency.MONTHLY && bynweekday?.length) {
      const bymonthweekday = bynweekday.reduce<string[]>((acc, item) => {
        const [day, week] = item;
        const weekdays = `0${week},${new Weekday(day).toString()}`;
        acc.push(weekdays);
        return acc;
      }, []);

      setMonthlyGridType("daysOfWeek");
      setBymonthweekday(bymonthweekday);
    }
  });

  const generateRRuleStrAndText = () => {
    const isMonthByWeekDay =
      frequency === Frequency.MONTHLY && monthlyGridType === "daysOfWeek";
    let rruleResult = new RRule();

    if (frequency === Frequency.DAILY) {
      rruleResult = new RRule({
        freq: RRule.DAILY,
        interval: dailyInterval,
        dtstart,
      });
    }

    if (frequency === Frequency.WEEKLY) {
      rruleResult = new RRule({
        freq: RRule.WEEKLY,
        interval: weeklyInterval,
        byweekday,
        dtstart,
      });
    }

    if (frequency === Frequency.MONTHLY && monthlyGridType === "daysOfMonth") {
      rruleResult = new RRule({
        freq: RRule.MONTHLY,
        interval: monthlyInterval,
        bymonthday,
        dtstart,
      });
    }

    if (isMonthByWeekDay) {
      const bynweekday = convertBymonthweekdayToBynweekday(bymonthweekday);

      rruleResult = new RRule({
        freq: RRule.MONTHLY,
        interval: monthlyInterval,
        bynweekday,
        dtstart,
      });
    }

    const rruleStr = isMonthByWeekDay
      ? getMontByWeekRRuleStr(rruleResult, bymonthweekday)
      : rruleResult.toString();

    const rruleText = isMonthByWeekDay ? (
      <>
        <span>{rruleResult.toText()}</span>
        {getBynweekdayTextArr(bymonthweekday).map((item) => (
          <div key={item}>{item}</div>
        ))}
      </>
    ) : (
      <span>{rruleResult.toText()}</span>
    );

    return { rruleStr, rruleText };
  };

  const handleFrequencyChange = (event: SelectChangeEvent): void => {
    setFrequency(parseInt(event.target.value));
  };

  const handleSubmit = () => {
    const rRuleStr = generateRRuleStrAndText().rruleStr;
    onChange(rRuleStr);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: "16px",
        },
      }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          px: 4,
          fontSize: 24,
          minHeight: "64px",
        }}
      >
        Send Newsletter Schedule
        <IconButton
          size="small"
          onClick={onClose}
          aria-label="close"
          sx={{ mr: -3, mt: -2, ml: "auto" }}
        >
          <Close fontSize="small" />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          minWidth: "380px",
          p: 4,
        }}
      >
        <Divider />
        <FormControl fullWidth margin="normal">
          <Box sx={{ mb: 2, display: "flex", gap: 2, alignItems: "center" }}>
            <Typography
              color="text.secondary"
              variant="body1"
              fontWeight="bold"
            >
              <label htmlFor="frequency">Frequency: </label>
            </Typography>
            <Select
              id="frequency"
              name="frequency"
              value={String(frequency)}
              onChange={handleFrequencyChange}
            >
              <MenuItem value={Frequency.DAILY}>Daily</MenuItem>
              <MenuItem value={Frequency.WEEKLY}>Weekly</MenuItem>
              <MenuItem value={Frequency.MONTHLY}>Monthly</MenuItem>
            </Select>
          </Box>
        </FormControl>
        <Box sx={{ mb: 4 }}>
          {frequency === Frequency.DAILY && (
            <Daily interval={dailyInterval} setInterval={setDailyInterval} />
          )}
          {frequency === Frequency.WEEKLY && (
            <Weekly
              interval={weeklyInterval}
              setInterval={setWeeklyInterval}
              byweekday={byweekday}
              setByweekday={setByweekday}
            />
          )}
          {frequency === Frequency.MONTHLY && (
            <Monthly
              interval={monthlyInterval}
              setInterval={setMonthlyInterval}
              bymonthday={bymonthday}
              setBymonthday={setBymonthday}
              bymonthweekday={bymonthweekday}
              setBymonthweekday={setBymonthweekday}
              monthlyGridType={monthlyGridType}
              setMonthlyGridType={setMonthlyGridType}
            />
          )}
        </Box>
        <Divider sx={{ mb: 2 }} />
        {getSummary(generateRRuleStrAndText().rruleText)}
      </DialogContent>
      <DialogActions sx={{ p: 4, gap: 1.5 }}>
        <Button variant="outlined" fullWidth onClick={onClose} size="large">
          Cancel
        </Button>
        <Button
          fullWidth
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          size="large"
        >
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
}
