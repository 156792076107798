import React from "react";

function Submitted() {
  return (
    <div>
      <img className="reset-password-confirm-img" alt="" />
      <p className="heading">Your Account is Being Reviewed</p>
      <p>We will get back to you as quickly as possible.</p>
    </div>
  );
}

export default Submitted;
