import React from "react";
import { Box, Card, CardContent } from "@mui/material";

import TopMenuItem, { TopMenuItemStatus } from "./TopMenuItem";

export type CurrentStatus =
  | TopMenuItemStatus
  | "Rejected"
  | "Canceled"
  | "Expired";

function getStatusesList(
  statuses: TopMenuItemStatus[] | undefined,
  currentStatus: CurrentStatus | undefined
) {
  if (statuses === undefined) {
    return [];
  }

  const activeIndex = statuses.findIndex((status) => status === currentStatus);

  return statuses.map((status, index) => ({
    name: status,
    completed: index <= activeIndex,
    active: index === activeIndex,
  }));
}

export default function TopMenu({
  currentStatus,
  statuses,
}: {
  currentStatus?: CurrentStatus;
  statuses?: TopMenuItemStatus[];
}) {
  const statusesList = getStatusesList(statuses, currentStatus);

  const isVisible =
    currentStatus !== undefined &&
    ["Rejected", "Canceled", "Expired"].indexOf(currentStatus) === -1;

  // TODO: remove fieldset after css reset (payout-details branch)
  return isVisible ? (
    <fieldset>
      <Card>
        <CardContent sx={{ position: "relative" }}>
          <Box
            sx={{
              position: "absolute",
              top: "45px",
              left: 0,
              width: "100%",
              height: "1px",
              background: "#ddd",
              zIndex: 1,
            }}
          />
          <Box display="flex" justifyContent="space-around">
            {statusesList.map((status) => (
              <TopMenuItem
                status={status.name}
                key={status.name}
                active={status.active}
                completed={status.completed}
              />
            ))}
          </Box>
        </CardContent>
      </Card>
    </fieldset>
  ) : null;
}
