import React from "react";
import SignInputError from "components/SignInputError";

export default function SignUpCheckBox({
  name,
  value,
  onChange,
  errorMessage,
}: {
  name: string;
  value: string;
  onChange: (e: React.FormEvent<HTMLInputElement>) => void;
  errorMessage?: string;
}) {
  return (
    <div className="form-group">
      <label className="form-checkbox inited">
        <input type="checkbox" name={name} value={value} onChange={onChange} />
        <strong className="goals">{value}</strong>
        <i className="dot" aria-hidden="true"></i>
      </label>
      <SignInputError errorMessage={errorMessage} />
    </div>
  );
}
