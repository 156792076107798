import EventAvailableOutlinedIcon from "@mui/icons-material/EventAvailableOutlined";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import axios from "axios";
import { noop } from "lodash";
import React, { useState } from "react";
import { SelectedEventDetails } from "./types";

interface AddReservationModalProps {
  open: boolean;
  handleClose: () => void;
  siteSlug: string;
  selectedEvent: SelectedEventDetails;
  reloadEvents: () => void;
}

const AddReservationModal: React.FC<AddReservationModalProps> = ({
  open,
  handleClose,
  siteSlug,
  selectedEvent: {
    extendedProps: { sponsorship_option_uuid },
    startDate,
  },
  reloadEvents,
}) => {
  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  const handleConfirm = async () => {
    if (!sponsorship_option_uuid || !startDate) {
      return;
    }
    setIsProcessing(true);

    try {
      const deleteUrl = `/api/v1/publisher/sites/${siteSlug}/sponsorship_options/${sponsorship_option_uuid}/sponsorship_option_reservations/${startDate}`;
      await axios.delete(deleteUrl);

      handleClose();
      reloadEvents();
    } catch (err) {
      noop();
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: { borderRadius: "16px", maxWidth: "420px" },
      }}
    >
      <DialogContent sx={{ p: 4 }}>
        <Box
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <EventAvailableOutlinedIcon
            sx={{ color: "success.main", fontSize: 50 }}
          />
        </Box>

        <Typography variant="h6" textAlign="center">
          Mark as Available
        </Typography>
        <Typography textAlign="center" mt={2}>
          Marking this placement as available will allow advertisers to request
          this date.
        </Typography>
      </DialogContent>

      <DialogActions sx={{ p: 4, gap: 1.5 }}>
        <Button
          variant="outlined"
          fullWidth
          onClick={handleClose}
          disabled={isProcessing}
          color="inherit"
          size="large"
        >
          Cancel
        </Button>
        <Button
          fullWidth
          onClick={handleConfirm}
          variant="contained"
          color="success"
          disabled={isProcessing}
          startIcon={isProcessing ? <CircularProgress size={20} /> : null}
          size="large"
        >
          {isProcessing ? "Marking..." : "Confirm"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddReservationModal;
