export const toCamel = (str: string) =>
  str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());

export const toSnake = (str: string) =>
  str &&
  str
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    ?.map((x) => x.toLowerCase())
    .join("_");

export const keysToSnake = (object: any) => {
  const newObject: any = {};
  Object.keys(object).forEach((key) => {
    const snakeKey = toSnake(key);
    if (snakeKey) newObject[snakeKey] = object[key];
  });
  return newObject;
};

export function sanitizeFormValues(obj: { [key: string]: any }): {
  [key: string]: any;
} {
  for (const key in obj) {
    if (obj[key] === null) {
      obj[key] = "";
    } else if (typeof obj[key] === "object") {
      sanitizeFormValues(obj[key]);
    }
  }

  return obj;
}
