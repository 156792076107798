import React from "react";
import { Box, Container, Button } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const CAPTION_PATTERNS = [
  { regex: /\/categories\/favorites/, caption: "Back to Favorite Publishers" },
  {
    regex: /\/categories\/recommendations/,
    caption: "Back to Recommended Publishers",
  },
  { regex: /\/categories\//, caption: "Back to the Category" },
  { regex: /\/collections\//, caption: "Back to the Collection" },
  {
    regex: /\/search\/advertiser_search/,
    caption: "Back to Search By Advertiser",
  },
];

function getBackButtonCaption() {
  if (typeof document === "undefined") return "Back to the Marketplace";

  const referrer = document.referrer;

  for (const { regex, caption } of CAPTION_PATTERNS) {
    if (regex.test(referrer)) return caption;
  }

  return referrer.includes("?")
    ? "Back to Search Results"
    : "Back to the Marketplace";
}

function getBackButtonLink() {
  if (typeof document === "undefined") return "/marketplace";

  const referrer = document.referrer;
  const publisherLists = [
    "/categories",
    "/collections",
    "/marketplace?",
    "/search/advertiser_search",
  ];

  return publisherLists.some((path) => referrer.includes(path))
    ? referrer
    : "/marketplace";
}

export default function AdvertiserSubMenu() {
  const backButtonCaption = getBackButtonCaption();
  const backLink = getBackButtonLink();

  return (
    <Box
      sx={{
        backgroundColor: "rgb(48, 54, 69) !important",
        padding: "4px 0",
      }}
    >
      <Container>
        <Box display="flex" flexGrow={1}>
          <Button
            variant="text"
            startIcon={<ArrowBackIcon />}
            href={backLink}
            sx={{
              color: "rgba(255, 255, 255, 0.75)",
              ":hover": { color: "rgba(255, 255, 255, .95)" },
            }}
          >
            {backButtonCaption}
          </Button>
        </Box>
      </Container>
    </Box>
  );
}
