import { Button, Box } from "@mui/material";

interface ContentFileReaderProps {
  name: string;
  onChange: (file: File) => void;
  caption?: string;
  acceptedFileTypes: string;
  onImageLoad?: (fileSize: [number, number] | null) => void;
}

export default function ContentFileReader({
  name,
  onChange,
  caption = "Select File",
  acceptedFileTypes,
  onImageLoad,
}: ContentFileReaderProps) {
  return (
    <>
      <Box display="flex" alignItems="center">
        <Button variant="contained" component="label">
          {caption}
          <input
            name={name}
            accept={acceptedFileTypes}
            type="file"
            hidden
            onChange={(e) => {
              const fileReader = new FileReader();
              fileReader.onload = () => {
                const img = new Image();
                img.onload = function () {
                  onImageLoad?.([img.width, img.height]);
                };

                if (fileReader.readyState === 2) {
                  if (e.target.files?.[0]) {
                    onChange(e.target.files[0]);
                    img.src = URL.createObjectURL(e.target.files[0]);
                  }
                }
              };
              if (e.target.files?.[0]) {
                fileReader.readAsDataURL(e.target.files[0]);
              }
            }}
          />
        </Button>
      </Box>
    </>
  );
}
