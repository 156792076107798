import React from "react";

export default function SignInputError({
  errorMessage,
}: {
  errorMessage?: string;
}) {
  return !!errorMessage ? (
    <ul className="parsley-errors-list filled">
      <li className="parsley-required">{errorMessage}</li>
    </ul>
  ) : null;
}
