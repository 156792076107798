import React, { useContext } from "react";
import { Typography, Box } from "@mui/material";
import { SiteContext } from "../components/SiteContext";

export default function SubmissionConfirmation() {
  const { bookerEnabled } = useContext(SiteContext);

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <img className="check-circle-green-img" alt="Check Circle Green" />
      <Typography variant="h6" sx={{ marginTop: 4 }}>
        Your profile was successfully submitted!
      </Typography>
      <Box alignItems="left">
        <Typography sx={{ marginTop: 2 }}>
          Our team will review your profile and get back to you within 1-2
          business days.
        </Typography>
        {bookerEnabled && (
          <Typography sx={{ marginTop: 2 }}>
            Please continue to Booker setup. Booker allows you to manage the
            sponsorship process directly from your website.
          </Typography>
        )}
      </Box>
    </Box>
  );
}
