import React from "react";
import { Box, TextField, Typography } from "@mui/material";

export default function Interval({
  interval,
  setInterval,
  caption,
}: {
  interval: number;
  setInterval: (interval: number) => void;
  caption: string;
}) {
  return (
    <Box display="flex" alignItems="center" gap={2} mb={2}>
      <Typography color="text.secondary" variant="body1" fontWeight="bold">
        <label htmlFor="interval">Every</label>
      </Typography>
      <TextField
        id="interval"
        name="interval"
        type="number"
        value={interval}
        onChange={(event) => setInterval(Number(event.target.value))}
        sx={{ width: "48px" }}
      />
      <Box>{interval === 1 ? caption : `${caption}s`}</Box>
    </Box>
  );
}
