import React, { useState, useContext, useMemo } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { Formik, FieldArray, FieldArrayRenderProps } from "formik";
import * as yup from "yup";
import { SiteContext } from "../components/SiteContext";
import { useSnackbar } from "context";
import useComponentDidMount from "hooks/useComponentDidMount";

import { LayoutContext } from "context";
import SubscribersAndOpens from "./SubscribersAndOpens";
import Placement, {
  PUBLISHED_PRICE_MIN,
  SMART_PUBLISHED_PRICE_MIN,
} from "./Placement";
import isEqual from "lodash/isEqual";

import { ICreativeRequirement, IPlacement, ISponsoredEmail } from "types";
import {
  VALIDATOR_STRING_REQUIRED,
  VALIDATOR_NUMBER_REQUIRED,
} from "utils/validators";
import { scrollToFirstError } from "utils/errorHandlers";
import noop from "lodash/noop";
import {
  LINK_ASSET_TYPE,
  ASSET_IMAGE_MIN_WIDTH,
  ASSET_IMAGE_MIN_HEIGHT,
  ASSET_TEXT_MAX_LENGTH_FROM,
  ASSET_TEXT_MAX_LENGTH_TO,
  ASSET_IMAGE_MAX_WIDTH,
  ASSET_IMAGE_MAX_HEIGHT,
  ASSET_TEXT_WORDS_MAX_LENGTH_FROM,
  ASSET_TEXT_WORDS_LENGTH_TO,
} from "./CreativeRequirements/CreativeAsset";
import FormWrapper from "components/FormWrapper";
import { AdvertiserItem } from "../types";
import {
  usePricingValidationModal,
  SUBMIT_BUTTON_ID,
} from "../hooks/usePricingModal";
import PricingModal from "../components/PriceValidationModal";
import get from "lodash/get";

const creativeAssetsSchema = yup
  .array()
  .of(
    yup.object().shape({
      name: VALIDATOR_STRING_REQUIRED.nullable().max(50, "Max 50 characters."),
      creative_type: VALIDATOR_STRING_REQUIRED.nullable(),
      width: yup
        .number()
        .nullable()
        .when(["creative_type"], {
          is: (value: string) => value === "image",
          then: VALIDATOR_NUMBER_REQUIRED.moreThan(
            ASSET_IMAGE_MIN_WIDTH,
            `Must be more then ${ASSET_IMAGE_MIN_WIDTH}px`
          ).lessThan(
            ASSET_IMAGE_MAX_WIDTH,
            `Must be less then ${ASSET_IMAGE_MAX_WIDTH}px`
          ),
        }),
      height: yup
        .number()
        .nullable()
        .when(["creative_type"], {
          is: (value: string) => value === "image",
          then: VALIDATOR_NUMBER_REQUIRED.moreThan(
            ASSET_IMAGE_MIN_HEIGHT,
            `Must be more then ${ASSET_IMAGE_MIN_HEIGHT}px`
          ).lessThan(
            ASSET_IMAGE_MAX_HEIGHT,
            `Must be less then ${ASSET_IMAGE_MAX_HEIGHT}px`
          ),
        }),
      max_length: yup
        .number()
        .nullable()
        .when(["creative_type", "length_type"], {
          is: (type: string, length_type: string) =>
            type === "text" && (length_type === "characters" || !length_type),
          then: VALIDATOR_NUMBER_REQUIRED.moreThan(
            ASSET_TEXT_MAX_LENGTH_FROM,
            `Must be more than ${ASSET_TEXT_MAX_LENGTH_FROM}`
          ).lessThan(
            ASSET_TEXT_MAX_LENGTH_TO,
            `Must be less than ${ASSET_TEXT_MAX_LENGTH_TO}`
          ),
          otherwise: yup
            .number()
            .nullable()
            .when(["creative_type", "length_type"], {
              is: (type: string, length_type: string) =>
                type === "text" && length_type === "words",
              then: VALIDATOR_NUMBER_REQUIRED.min(
                ASSET_TEXT_WORDS_MAX_LENGTH_FROM,
                `Must be at least ${ASSET_TEXT_WORDS_MAX_LENGTH_FROM}`
              ).lessThan(
                ASSET_TEXT_WORDS_LENGTH_TO,
                `Must be less than ${ASSET_TEXT_WORDS_LENGTH_TO}`
              ),
            }),
        }),
    })
  )
  .min(1, "Provide at least two creative requirements. One must be a link.");

export const smartPricingLowestPriceSchema = yup
  .number()
  .when(["smart_pricing_enabled", "published_price"], {
    is: (smart_pricing_enabled: boolean, published_price: number) =>
      smart_pricing_enabled,
    then: yup
      .number()
      .lessThan(
        yup.ref("published_price"),
        "Price should be less than Sponsorship price"
      )
      .test(
        "min",
        `Price must be at least $${PUBLISHED_PRICE_MIN}`,
        (value) => (value && value >= PUBLISHED_PRICE_MIN) || false
      )
      .required("Price is required"),
  });

export const publishedPriceSchema = VALIDATOR_NUMBER_REQUIRED.test(
  "min",
  `Price must be at least $${SMART_PUBLISHED_PRICE_MIN}`,
  (value) => (value && value >= SMART_PUBLISHED_PRICE_MIN) || false
);

const linkAssetSchema = yup.boolean().when(["creativeAssets"], {
  is: (creativeAssets: ICreativeRequirement[]) =>
    creativeAssets.length &&
    !creativeAssets.some((asset) => asset.creative_type === LINK_ASSET_TYPE),
  then: yup.boolean().required("Please provide at least one link asset."),
});

const nonLinkAssetSchema = yup.boolean().when(["creativeAssets"], {
  is: (creativeAssets: ICreativeRequirement[]) =>
    creativeAssets.length &&
    !creativeAssets.some((asset) => asset.creative_type !== LINK_ASSET_TYPE),
  then: yup.boolean().required("Please provide at least one non-link asset."),
});

const getUserAdClickTotalSchema = (
  previousAdvertisersList: AdvertiserItem[]
) => {
  return previousAdvertisersList.length >= 3
    ? yup.number().required("This field is required.")
    : yup.number().optional();
};

const getExampleUrlSchema = (previousAdvertisersList: AdvertiserItem[]) => {
  return previousAdvertisersList.length >= 3
    ? yup.string().required("Sample email is required.")
    : yup.string().optional();
};

const defaultPlacementValue = {
  id: 0,
  name: "",
  published_price: 0,
  smart_pricing_lowest_price: 0,
  smart_pricing_enabled: false,
  placement_location: "",
  ad_description: "",
  user_ad_click_total: "",
  creativeAssets: [],
};
export default function SponsoredEmail() {
  const openSnackbar = useSnackbar(); // ASK. how to optimize
  const { siteSlug } = useParams();
  const { siteData } = useContext(SiteContext);
  const { isDataLoading, setIsDataLoading } = useContext(LayoutContext);
  const [isAdding, setIsAdding] = useState(false);
  const [isDeleting, setIsDeleting] = useState<{ [key: string]: boolean }>({});
  const [previousAdvertisersList, setPreviousAdvertisersList] = useState<
    AdvertiserItem[]
  >([]);
  const [initialValues, setInitialValues] = useState({
    example_url: "",
    list_size: "",
    user_open_total: "",
    user_click_total: "",
    placements: [
      {
        ...defaultPlacementValue,
        name: "Main Sponsorship",
      },
    ],
  });

  const exceedsPreviousAdvertisersLimit = previousAdvertisersList.length >= 3;

  const validationSchema = yup.object().shape({
    list_size: VALIDATOR_NUMBER_REQUIRED,
    user_open_total: VALIDATOR_NUMBER_REQUIRED,
    user_click_total: VALIDATOR_NUMBER_REQUIRED,
    example_url: getExampleUrlSchema(previousAdvertisersList),
    placements: yup
      .array()
      .of(
        yup.object().shape({
          name: VALIDATOR_STRING_REQUIRED,
          published_price: publishedPriceSchema,
          placement_location: VALIDATOR_STRING_REQUIRED,
          ad_description: VALIDATOR_STRING_REQUIRED,
          smart_pricing_enabled: yup.boolean(),
          smart_pricing_lowest_price: smartPricingLowestPriceSchema,
          creativeAssets: creativeAssetsSchema,
          linkAsset: linkAssetSchema,
          nonLinkAsset: nonLinkAssetSchema,
          user_ad_click_total: getUserAdClickTotalSchema(
            previousAdvertisersList
          ),
        })
      )
      .required()
      .min(1),
  });

  const apiUrl = useMemo(
    () =>
      `/api/v1/publisher/sites/${siteSlug}/sponsorship_options/sponsored_email`,
    [siteSlug]
  );

  const nextRoute = `/publisher/sites/${siteSlug}/schedule`;

  const {
    handlePriceBlur,
    priceModalOpen,
    setPriceModalOpen,
    shouldCheckPriceOnSubmit,
    checkPrice,
    blurredFieldName,
  } = usePricingValidationModal("sponsored_email");

  useComponentDidMount(() => {
    setIsDataLoading(true);
    axios({
      method: "get",
      url: `/api/v1/publisher/sites/${siteSlug}/previous_advertisers`,
      responseType: "json",
    })
      .then((response) => {
        setPreviousAdvertisersList(response.data);
      })
      .catch(noop);
    axios({
      method: "get",
      url: apiUrl,
    })
      .then((response) => {
        if (response.data?.length > 0) {
          setInitialValues({
            example_url: response.data[0].example_url || "",
            list_size: response.data[0].list_size || "",
            user_open_total: response.data[0].user_open_total || "",
            user_click_total: response.data[0].user_click_total || "",
            placements: response.data.map(
              (item: IPlacement, index: number) => ({
                id: item.id,
                name: item.name
                  ? item.name
                  : index === 0
                  ? "Main Sponsorship"
                  : "",
                placement_location: item.placement_location || "",
                ad_description: item.ad_description || "",
                user_ad_click_total: item.user_ad_click_total || "",
                published_price: item.published_price ?? 0,
                smart_pricing_lowest_price:
                  item.smart_pricing_lowest_price ?? 0,
                smart_pricing_enabled: Boolean(item.smart_pricing_enabled),
                creativeAssets: [],
              })
            ),
          });
        }
      })
      .catch(noop)
      .finally(() => {
        setIsDataLoading(false);
      });
  });

  const submitFormData = (values: ISponsoredEmail) => {
    setIsDataLoading(true);
    const formdata = new FormData();
    for (const placement of values.placements) {
      formdata.append(
        "sponsorship_options_attributes[][id]",
        placement.id.toString()
      );
      formdata.append(
        "sponsorship_options_attributes[][list_size]",
        values.list_size
      );
      formdata.append(
        "sponsorship_options_attributes[][user_open_total]",
        values.user_open_total
      );
      formdata.append(
        "sponsorship_options_attributes[][user_click_total]",
        values.user_click_total
      );
      formdata.append(
        "sponsorship_options_attributes[][name]",
        placement.name || ""
      );
      formdata.append(
        "sponsorship_options_attributes[][placement_location]",
        placement.placement_location || ""
      );
      formdata.append(
        "sponsorship_options_attributes[][ad_description]",
        placement.ad_description || ""
      );
      formdata.append(
        "sponsorship_options_attributes[][user_ad_click_total]",
        placement.user_ad_click_total || ""
      );
      formdata.append(
        "sponsorship_options_attributes[][published_price]",
        placement.published_price.toString()
      );
      formdata.append(
        "sponsorship_options_attributes[][smart_pricing_enabled]",
        placement.published_price <= PUBLISHED_PRICE_MIN
          ? String(false)
          : placement.smart_pricing_enabled.toString()
      );
      formdata.append(
        "sponsorship_options_attributes[][smart_pricing_lowest_price]",
        (placement.smart_pricing_lowest_price ?? "0").toString()
      );
    }
    axios
      .patch(apiUrl, formdata)
      .then(() => {
        handleNextButton();
      })
      .finally(() => {
        setIsDataLoading(false);
      });
  };

  const handleSubmit = (values: ISponsoredEmail) => {
    // TODO: research why initialValues and values are not equal by link
    if (isEqual(initialValues, values)) {
      return handleNextButton();
    }

    submitFormData(values);
  };

  const onClickAddPlacement = (pushPlacement: (values: IPlacement) => void) => {
    setIsAdding(true);
    axios({
      method: "post",
      url: apiUrl,
      data: {
        ...defaultPlacementValue,
        published_price: "",
      },
    })
      .then((response) => {
        pushPlacement({ ...defaultPlacementValue, id: response.data.id });
      })
      .finally(() => {
        setIsAdding(false);
      });
  };

  const onClickRemovePlacement = (
    remove: (index: number) => void,
    id: number,
    index: number
  ) => {
    setIsDeleting((prev) => ({ ...prev, [id]: true }));
    axios({
      method: "delete",
      url: apiUrl,
      data: { id },
    })
      .then(() => {
        remove(index);
      })
      .finally(() => {
        setIsDeleting((prev) => ({ ...prev, [id]: false }));
      });
  };

  const handleNextButton = () => {
    if (siteData.status === "Live") {
      openSnackbar("Sponsorship updated!");
    } else {
      window.location.href = nextRoute;
    }
  };

  return (
    <>
      <Typography
        variant="h5"
        component="h1"
        color="text.primary"
        sx={{ marginBottom: 1.5 }}
      >
        Sponsored Email
      </Typography>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={validationSchema}
        validateOnBlur={true}
        onSubmit={(values) => {
          if (shouldCheckPriceOnSubmit) {
            const publishedPrice = get(values, blurredFieldName as string);

            checkPrice(publishedPrice).then((isPriceCheckSuccessfull) => {
              if (isPriceCheckSuccessfull) {
                handleSubmit(values);
              }
            });
          } else {
            handleSubmit(values);
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          setFieldValue,
          submitForm,
          handleSubmit,
        }) => (
          <FormWrapper onSubmit={handleSubmit}>
            <PricingModal
              open={priceModalOpen}
              setPriceModalOpen={setPriceModalOpen}
            />
            <SubscribersAndOpens
              exceedsPreviousAdvertisersLimit={exceedsPreviousAdvertisersLimit}
              siteSlug={siteSlug}
              values={values}
              errors={errors as { [key: string]: string }}
              touched={touched as { [key: string]: boolean }}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
              handleBlur={handleBlur}
            />
            <FieldArray name="placements">
              {({ remove, push }: FieldArrayRenderProps) => (
                <Box
                  marginTop={3}
                  display="flex"
                  flexDirection="column"
                  gap={3}
                >
                  {values.placements.map((placement, index) => (
                    <Placement
                      exceedsPreviousAdvertisersLimit={
                        exceedsPreviousAdvertisersLimit
                      }
                      key={index}
                      index={index}
                      siteSlug={siteSlug || ""}
                      isCanRemoved={values.placements.length > 1}
                      prefix={`placements.${index}`}
                      values={values.placements[index] || {}}
                      exampleUrl={values.example_url}
                      errors={
                        (errors.placements?.[index] as {
                          [key: string]: any;
                        }) || {}
                      }
                      touched={
                        (touched.placements?.[index] as {
                          [key: string]: any;
                        }) || {}
                      }
                      isDeleting={isDeleting[placement.id]}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      setFieldValue={setFieldValue}
                      handleRemove={(index: number) =>
                        onClickRemovePlacement(remove, placement.id, index)
                      }
                      handlePriceBlur={handlePriceBlur}
                    />
                  ))}
                  <Box display="flex" justifyContent="flex-end" gap={1}>
                    <Button
                      variant="outlined"
                      sx={{
                        color: "text.primary",
                      }}
                      disabled={isAdding}
                      endIcon={isAdding ? <CircularProgress size={12} /> : null}
                      onClick={() => onClickAddPlacement(push)}
                    >
                      Add Another Placement
                    </Button>
                    <Button
                      id={SUBMIT_BUTTON_ID}
                      type="button"
                      variant="contained"
                      color="primary"
                      disabled={isAdding || isDataLoading}
                      endIcon={
                        isDataLoading ? (
                          <CircularProgress size={12} sx={{ color: "#fff" }} />
                        ) : null
                      }
                      onClick={() => {
                        submitForm().then(() => {
                          scrollToFirstError();
                        });
                      }}
                    >
                      {siteData.status === "Live" ? "Update" : "Next"}
                    </Button>
                  </Box>
                </Box>
              )}
            </FieldArray>
          </FormWrapper>
        )}
      </Formik>
    </>
  );
}
