import { Grid, Link, TextField, Typography } from "@mui/material";
import { format } from "date-fns";
import { useState, useLayoutEffect } from "react";

const ScheduleSection = ({
  type,
  scheduleText,
  date,
  onDateChange,
  openScheduleModal,
}: {
  type: "sponsored" | "dedicated";
  scheduleText: string;
  date: string;
  onDateChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  openScheduleModal: (type: "sponsored" | "dedicated") => void;
}) => {
  const [minDate, setMinDate] = useState("");

  useLayoutEffect(() => {
    const today = new Date();
    const formattedDate = format(today, "yyyy-MM-dd");
    setMinDate(formattedDate);
  }, []);

  return (
    <Grid item xs={12} md={6}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="body1" fontWeight="bold">
            {type === "sponsored"
              ? "Sponsored Email Frequency:"
              : "Dedicated Email Frequency:"}
          </Typography>
          <Typography variant="body2" sx={{ mt: 1 }}>
            <Link
              component="button"
              textTransform="capitalize"
              onClick={(e) => {
                e.preventDefault();
                openScheduleModal(type);
              }}
            >
              {scheduleText || "Set Frequency"}
            </Link>
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="body1" fontWeight="bold">
            Next Send Date:
          </Typography>
          <TextField
            name={`${
              type === "sponsored"
                ? "schedule_anchor_date"
                : "dedicated_email_schedule_anchor_date"
            }`}
            type="date"
            value={date}
            onChange={onDateChange}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              min: minDate,
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ScheduleSection;
