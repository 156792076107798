import React from "react";
import Interval from "./Interval";

export default function Daily({
  interval,
  setInterval,
}: {
  interval: number;
  setInterval: (interval: number) => void;
}) {
  return (
    <Interval interval={interval} setInterval={setInterval} caption="day" />
  );
}
