import React, { useState, useContext, useMemo, useEffect } from "react";
import axios from "axios";
import { Link, useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Typography,
  Card,
  CardContent,
  CardHeader,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { LayoutContext } from "context";
import { SiteContext } from "../components/SiteContext";
import useComponentDidMount from "hooks/useComponentDidMount";
import noop from "lodash/noop";
import ReviewStatus from "./ReviewStatus";
import SubmissionConfirmation from "./SubmissionConfirmation";
import ProfileRejected from "./ProfileRejected";

export interface IProfileReview {
  rejection_reason: string | null;
  profile_error_groups: IProfileErrorGroup[];
}

export interface IProfileErrorGroup {
  name: string;
  errors: IProfileErrorItem[];
}

export interface IProfileErrorItem {
  message: string;
  link: string;
}

export default function ProfileReview() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { siteSlug } = useParams();
  const navigate = useNavigate();
  const [profileReview, setProfileReview] = useState<IProfileReview>({
    rejection_reason: null,
    profile_error_groups: [],
  });
  const { isDataLoading, setIsDataLoading } = useContext(LayoutContext);
  const { siteData, bookerEnabled } = useContext(SiteContext);
  const isCanSubmitReview = useMemo(
    () => profileReview.profile_error_groups.length === 0,
    [profileReview]
  );

  const [isSubmitted, setIsSubmitted] = useState(false);
  const showBookerButton = useMemo(
    () => bookerEnabled && (siteData.status === "In Review" || isSubmitted),
    [isSubmitted, siteData.status, bookerEnabled]
  );

  useEffect(() => {
    if (siteData.status === "Live") {
      navigate("/publisher/sites");
    }
  }, [siteData.status, navigate]);

  useComponentDidMount(() => {
    if (siteData.status === "In Review") {
      return;
    } else if (siteData.status === "Live") {
      return navigate("/publisher/sites");
    }
    setIsDataLoading(true);
    axios({
      method: "get",
      url: `/api/v1/publisher/sites/${siteSlug}/review`,
    })
      .then((response) => {
        setProfileReview(response.data);
      })
      .catch(noop)
      .finally(() => setIsDataLoading(false));
  });

  const handleSubmitForReview = () => {
    setIsDataLoading(true);
    axios({
      method: "patch",
      url: `/api/v1/publisher/sites/${siteSlug}`,
      data: { review: true },
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then(function (response) {
        setIsSubmitted(true);
      })
      .catch(() => {
        setIsDataLoading(false);
      })
      .finally(() => setIsDataLoading(false));
  };

  if (isDataLoading) {
    return null;
  }

  return siteData.status !== "Rejected" ? (
    <>
      <Typography
        variant="h5"
        component="h1"
        color="text.primary"
        sx={{ marginBottom: 1.5 }}
      >
        Submit Profile for Review
      </Typography>
      <Card>
        {siteData.status === "In Review" && (
          <CardHeader
            title="Your profile is currently under review"
            titleTypographyProps={{ variant: "h6" }}
          />
        )}
        <CardContent sx={{ padding: 3 }}>
          {siteData.status === "In Review" ? (
            <>
              {bookerEnabled ? (
                <Typography>
                  Thank you for submitting your Marketplace Profile! It is
                  currently in review with our team and they will get back to
                  you shortly. In the meantime, please continue to Booker setup
                  to further streamline the sponsorship process directly from
                  your website.
                </Typography>
              ) : (
                <Typography>
                  Thank you for submitting your profile! Our team is currently
                  looking over everything and will get back to you shortly. Stay
                  tuned to your inbox for updates.
                </Typography>
              )}
            </>
          ) : isSubmitted ? (
            <SubmissionConfirmation />
          ) : (
            <ReviewStatus
              profile_error_groups={profileReview.profile_error_groups}
            />
          )}
        </CardContent>
      </Card>
      <Box
        display="flex"
        marginTop={3}
        sx={{
          flexDirection: isMobile ? "column" : "row",
          gap: 1,
        }}
      >
        <Box
          display="flex"
          width="100%"
          gap={1}
          flexDirection={isMobile ? "column" : "row"}
          justifyContent="flex-end"
        >
          <Button
            component={Link}
            variant="outlined"
            type="button"
            fullWidth={isMobile}
            disabled={!isCanSubmitReview}
            sx={{
              color: "text.primary",
              textTransform: "capitalize",
            }}
            to={`/sites/${siteSlug}`}
            reloadDocument={true}
            target="_blank"
          >
            {siteData.status === "Live"
              ? "View Profile Page"
              : "Preview Profile Page"}
          </Button>
          {siteData.status === "Draft" && !isSubmitted && (
            <Button
              variant="contained"
              fullWidth={isMobile}
              disabled={isDataLoading || !isCanSubmitReview}
              onClick={handleSubmitForReview}
            >
              Submit For Review
            </Button>
          )}
          {showBookerButton && (
            <Button
              variant="contained"
              fullWidth={isMobile}
              onClick={() => {
                window.location.href = `/publisher/sites/${siteSlug}/booker`;
              }}
            >
              Continue to Booker Setup
            </Button>
          )}
        </Box>
      </Box>
    </>
  ) : (
    <ProfileRejected rejection_reason={profileReview.rejection_reason} />
  );
}
