import {
  ToggleRefinementProps,
  useToggleRefinement,
} from "react-instantsearch";

export default function CustomToggleRefinement(props: ToggleRefinementProps) {
  const {
    value: { isRefined },
    refine,
  } = useToggleRefinement(props);

  return (
    <div className="ais-ToggleRefinement">
      <label className="ais-ToggleRefinement-label form-checkbox">
        <input
          className="ais-ToggleRefinement-checkbox"
          checked={isRefined}
          type="checkbox"
          onChange={(event) => {
            refine({ isRefined: !event.target.checked });
          }}
        />
        <span className="ais-ToggleRefinement-labelText">
          {props.label}
          <i className="dot" aria-hidden="true"></i>
        </span>
      </label>
    </div>
  );
}
