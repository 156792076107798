import { useInstantSearch } from "react-instantsearch";
import Slider from "./Slider";
import { SyntheticEvent } from "react";

export type Format =
  | "Dedicated Emails"
  | "Sponsored Emails"
  | "Sponsored Posts";

export const FormatToKey = {
  "Dedicated Emails": "dedicated_email_prices",
  "Sponsored Emails": "sponsored_email_prices",
  "Sponsored Posts": "sponsored_post_prices",
};

export const MIN = 10;
export const MAX = 4000;
const STEP = 250;

export const getFilter = (
  min: number,
  max: number,
  selectedFormatsOptions: string[]
) => {
  if (selectedFormatsOptions.length > 0) {
    return selectedFormatsOptions
      .map(
        (value: string) =>
          `(${
            max === MAX
              ? `${FormatToKey[value as string as Format]}>=${min}`
              : `${FormatToKey[value as string as Format]}:${min} TO ${max}`
          })`
      )
      .join(" OR ");
  }
  return Object.values(FormatToKey)
    .map((type) =>
      max === MAX ? `${type}>=${min}` : `${type}:${min} TO ${max}`
    )
    .join(" OR ");
};

type PriceSliderProps = {
  setValue: React.Dispatch<React.SetStateAction<number[]>>;
  value: number[];
};

export default function PriceSlider({ setValue, value }: PriceSliderProps) {
  const { indexUiState, setIndexUiState } = useInstantSearch();

  const handleChange = (e: Event, newValue: number | number[]) => {
    setValue(newValue as number[]);
  };

  const handleChangeCommitted = (
    e: Event | SyntheticEvent<Element, Event>,
    value: number | number[]
  ) => {
    setIndexUiState(indexUiState);
  };

  return (
    <Slider
      value={value}
      min={MIN}
      max={MAX}
      step={STEP}
      prefix="$"
      handleChange={handleChange}
      handleChangeCommitted={handleChangeCommitted}
    />
  );
}
