import React, { useEffect, useState } from "react";
import axios from "axios";
import debounce from "lodash/debounce";
import Avatar from "@mui/material/Avatar";
import noop from "lodash/noop";

const getSiteLogo = debounce(
  (siteUrl: string | undefined, callbackFn: (data: string) => void) => {
    if (!siteUrl) {
      return;
    }

    axios({
      method: "get",
      url: "/api/v1/publisher/logo",
      params: { url: siteUrl },
      responseType: "text",
    })
      .then((response) => callbackFn(`data:image/png;base64,${response.data}`))
      .catch(noop);
  },
  600
);

export default function SiteLogo({ siteUrl }: { siteUrl?: string }) {
  const [logoSrc, setLogoSrc] = useState<string>();

  useEffect(() => {
    getSiteLogo(siteUrl, setLogoSrc);
  }, [siteUrl]);

  return logoSrc ? (
    <Avatar sx={{ width: 150, height: 150, mb: 4 }} src={logoSrc as string} />
  ) : null;
}
