import React, { useState, useRef } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  CircularProgress,
} from "@mui/material";
import ReactCrop, { Crop, PixelCrop } from "react-image-crop";
import { UploadPlacement } from "../sponsoredEmail/PlacementExample";

interface Props {
  open: boolean;
  exampleUrl: string;
  isPlacementExample?: boolean;
  isLoading?: boolean;
  handleUploadPlacement?: (requestBody: UploadPlacement) => Promise<any>;
  handleClose: () => void;
}

export default function PreviewSampleEmail({
  open,
  exampleUrl,
  isPlacementExample,
  isLoading,
  handleUploadPlacement,
  handleClose,
}: Props) {
  const imgRef = useRef<HTMLImageElement>(null);
  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();

  const onSave = () => {
    const image = imgRef.current;
    if (!image || !completedCrop) return;
    image.crossOrigin = "Anonymous";
    image.onload = function () {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      if (!ctx) {
        return;
      }
      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;
      const pixelRatio = window.devicePixelRatio;
      canvas.width = completedCrop.width * pixelRatio;
      canvas.height = completedCrop.height * pixelRatio;
      ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
      ctx.imageSmoothingQuality = "high";

      ctx.drawImage(
        image,
        completedCrop.x * scaleX,
        completedCrop.y * scaleY,
        completedCrop.width * scaleX,
        completedCrop.height * scaleY,
        0,
        0,
        completedCrop.width,
        completedCrop.height
      );

      const base64String = canvas.toDataURL("image/jpeg");

      const requestBody: UploadPlacement = {
        placement_img_data: base64String,
        placement_coordinates: completedCrop,
      };
      if (handleUploadPlacement) {
        handleUploadPlacement(requestBody).then(() => {
          setCrop(undefined);
          setCompletedCrop(undefined);
        });
      }
    };
  };

  const onClose = () => {
    handleClose();
    setCrop(undefined);
    setCompletedCrop(undefined);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        "& .MuiDialog-paper": {
          maxWidth: "720px",
        },
      }}
    >
      <DialogTitle>
        {isPlacementExample ? "Highlight Preview" : "Example Preview"}
      </DialogTitle>
      <DialogContent dividers>
        {isPlacementExample ? (
          <>
            <Typography marginBottom={2}>
              Select the placement in the example below
            </Typography>
            <ReactCrop
              crop={crop}
              onChange={(_, percentCrop) => setCrop(percentCrop)}
              onComplete={(c) => setCompletedCrop(c)}
            >
              <img ref={imgRef} alt="Crop me" src={exampleUrl} />
            </ReactCrop>
          </>
        ) : (
          <img src={exampleUrl} alt="" width="100%" />
        )}
      </DialogContent>
      <DialogActions sx={{ justifyContent: "space-between", padding: 2 }}>
        <Button
          variant="outlined"
          sx={{
            color: "text.primary",
          }}
          onClick={onClose}
        >
          Close
        </Button>
        {isPlacementExample && (
          <Button
            variant="contained"
            color="primary"
            disabled={
              isLoading || !completedCrop?.width || !completedCrop.height
            }
            onClick={onSave}
            endIcon={
              isLoading ? (
                <CircularProgress size={12} sx={{ color: "#fff" }} />
              ) : null
            }
          >
            Save
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
