import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import SideMenu from "./components/sideMenu/SideMenu";
import { Outlet, useParams, useOutletContext } from "react-router-dom";
import axios from "axios";
import noop from "lodash/noop";
import { Typography } from "@mui/material";
import TopMenu, { CurrentStatus } from "./components/topMenu";
import { TopMenuItemStatus } from "./components/topMenu/TopMenuItem";
import CampaignBreadCrumbs from "./components/topMenu/CampaignBreadCrumbs";

type ContextType = {
  statusName: CurrentStatus | undefined;
  creativeDeadline: string | null;
  exampleUrl: string | null;
  sponsorshipType: string | null;
  campaignUuid: string | null;
};

export default function Campaigns() {
  const { campaignId } = useParams();
  const [profileName, setProfileName] = useState<string>("");
  const [statusName, setStatusName] = useState<CurrentStatus | undefined>(
    undefined
  );
  const [statuses, setStatuses] = useState<TopMenuItemStatus[] | undefined>();
  const [exampleUrl, setExampleUrl] = useState<string | null>(null);
  const [creativeDeadline, setCreativeDeadline] = useState<string | null>(null);
  const [sponsorshipType, setSponsorshipType] = useState<string | null>(null);
  const [campaignUuid, setCampaignUuid] = useState<string | null>(null);

  useEffect(() => {
    if (campaignId === undefined) {
      return;
    }

    axios
      .get(`/api/v1/advertiser/campaigns/${campaignId}`)
      .then((res) => {
        const {
          profile,
          status: statusName,
          statuses,
          example_url: exampleUrl,
          creative_deadline: creativeDeadline,
          sponsorship_type: sponsorshipType,
          uuid,
        } = res.data;
        setProfileName(profile?.name ?? "");
        setStatusName(statusName);
        setStatuses(statuses);
        setExampleUrl(exampleUrl);
        setCreativeDeadline(creativeDeadline);
        setSponsorshipType(sponsorshipType);
        setCampaignUuid(uuid);
      })
      .catch(noop);
  }, [campaignId]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Typography
        variant="h5"
        component="h1"
        color="text.primary"
        sx={{ marginBottom: 1.5, marginTop: "10px" }}
      >
        {profileName}
      </Typography>
      <CampaignBreadCrumbs lastItem={profileName} />
      <Box display="flex">
        <Box minWidth={240}>
          <SideMenu statusName={statusName} />
        </Box>
        <Box paddingLeft={1.5} flexGrow={1}>
          <TopMenu currentStatus={statusName} statuses={statuses} />
          <Outlet
            context={{
              statusName,
              creativeDeadline,
              exampleUrl,
              sponsorshipType,
              campaignUuid,
            }}
          />
        </Box>
      </Box>
    </Box>
  );
}

export function useStatus() {
  return useOutletContext<ContextType>();
}
