import React from "react";
import {
  Typography,
  Card,
  CardHeader,
  CardContent,
  List,
  ListItem,
  Link,
} from "@mui/material";
import { IProfileReview } from "./index";

interface ProfileRejectedProps {
  rejection_reason: IProfileReview["rejection_reason"];
}

function getRejectionMessage(
  rejection_reason: IProfileReview["rejection_reason"]
) {
  switch (rejection_reason) {
    case "general_rejection":
      return (
        <>
          <Typography>
            Unfortunately, we are not able to let your newsletter onto the
            Marketplace at this time. Below are a few assets that will help you
            grow your newsletter:
          </Typography>
          <List component={Typography}>
            <ListItem>
              <Link
                href="https://www.paved.com/blog/tools-for-newsletters/"
                target="_blank"
              >
                Tools for newsletters
              </Link>
            </ListItem>
            <ListItem>
              <Link
                href="https://www.paved.com/blog/build-your-email-list/"
                target="_blank"
              >
                Building email list
              </Link>
            </ListItem>
          </List>
          <Typography>
            We hope you will revisit us when the time is right. Should you have
            any further questions please feel free to{" "}
            <Link href="mailto:hey@paved.com?subject=Inquiry about rejected profile">
              reach out
            </Link>
            .
          </Typography>
        </>
      );
    case "bad_topic":
      return (
        <Typography>
          Unfortunately, due to our content policy, we do not allow your
          category onto the Marketplace at this time. Learn more about{" "}
          <Link
            href="https://help.paved.com/article/165-prohibited-content"
            target="_blank"
          >
            our content policy here
          </Link>
          .
        </Typography>
      );
    case "too_small":
      return (
        <>
          <Typography>
            Unfortunately, due to your newsletter size, we are not able to
            accept you onto the platform at this time. Please return when you
            reach 5,000 subscribers. In the meantime, below are a few assets
            that will help you grow your newsletter:
          </Typography>
          <List component={Typography}>
            <ListItem>
              <Link
                href="https://www.paved.com/blog/tools-for-newsletters/"
                target="_blank"
              >
                Tools for newsletters
              </Link>
            </ListItem>
            <ListItem>
              <Link
                href="https://www.paved.com/blog/build-your-email-list/"
                target="_blank"
              >
                Building email list
              </Link>
            </ListItem>
          </List>
          <Typography>
            Should you have any further questions please feel free to{" "}
            <Link href="mailto:hey@paved.com?subject=Inquiry about rejected profile">
              reach out
            </Link>
            .
          </Typography>
        </>
      );
    default:
      return "Unfortunately, we are not able to let your newsletter onto the Marketplace at this time.";
  }
}

export default function ProfileRejected({
  rejection_reason,
}: ProfileRejectedProps) {
  return (
    <Card>
      <CardHeader
        title="Your newsletter profile has been rejected"
        titleTypographyProps={{ variant: "h6" }}
      />
      <CardContent sx={{ padding: 3 }}>
        {getRejectionMessage(rejection_reason)}
      </CardContent>
    </Card>
  );
}
