import React from "react";
import { InputAdornment, InputLabel, TextField } from "@mui/material";
import { useTheme } from "@mui/material/styles";

export default function AmountInput({
  name,
  label,
  disabled,
  defaultValue,
  value,
  onChange,
  onBlur,
  error,
  touched,
  isPayout,
}: {
  name: string;
  label: string;
  disabled?: boolean;
  defaultValue?: number;
  value?: number | string;
  error?: string;
  touched?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<any, Element>) => void;
  isPayout?: boolean;
}) {
  const theme = useTheme();
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = e.target.value;

    if (!rawValue && onChange) {
      return onChange(e);
    }

    if (!/^\d+$/.test(rawValue) || rawValue.charAt(0) === "0") {
      return;
    }

    const value = parseInt(rawValue);
    // TODO: 100_000_000 is just to avoid unexpected behaviour. Need to discuss real validation.
    if (value >= 100_000_000 || value < 0) {
      return;
    }

    if (onChange) onChange(e);
  };
  return (
    <>
      <InputLabel
        sx={{
          color: disabled && !isPayout ? "text.disabled" : "text.label",
        }}
        htmlFor={name}
      >
        {label}
      </InputLabel>
      <TextField
        id={name}
        name={name}
        type="text"
        disabled={disabled}
        defaultValue={defaultValue}
        value={value}
        onChange={handleChange}
        onBlur={onBlur}
        error={!!error && touched}
        data-error-element={Boolean(error)}
        sx={{
          ...(isPayout && {
            "& .MuiInputBase-input.Mui-disabled": {
              WebkitTextFillColor: theme.palette.text.primary,
            },
          }),
        }}
        InputProps={{
          inputProps: {
            inputMode: "numeric",
          },
          startAdornment: (
            <InputAdornment
              position="start"
              sx={{
                pl: 1,
                mr: -1,
              }}
            >
              $
            </InputAdornment>
          ),
        }}
        helperText={touched && !!error ? error : ""}
      />
    </>
  );
}
