import React, { useContext, useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import * as Yup from "yup";

import { SignUpContext } from "../context";
import { useAxios, useCustomFormik } from "../hooks";
import SignButton from "components/SignButton";
import StepCaption from "components/StepCaption";
import SignUpField from "./SignUpField";
import noop from "lodash/noop";
import { UserType } from "../types";
import { useLocation, useNavigate } from "react-router-dom";
import { sendSegmentsEvent } from "../utils/analytics";
import { URL_VALIDATOR_REGEX } from "utils/validators";

const FormSchema = Yup.object().shape({
  companyName: Yup.string().required("Company name is required."),
  url: Yup.string()
    .matches(URL_VALIDATOR_REGEX, "This value must be a valid URL.")
    .required("Website is required."),
});

export default function CompanyDetailsForm() {
  const navigate = useNavigate();
  const fullForm = !!useLocation().state?.fullForm;
  const { userData, setUserData } = useContext(SignUpContext);
  const { requestApi } = useAxios();
  const [loading, setLoading] = useState(false);

  const formik = useCustomFormik({
    initialValues: {
      companyName: "",
      url: "",
    },
    validationSchema: FormSchema,
    onSubmit: (values) => {
      const body = {
        company_name: values.companyName,
        url: values.url,
        goals: userData.goals,
        budget: userData.budget,
      };
      setLoading(true);
      sendSegmentsEvent(values.companyName);
      requestApi("patch", "/api/v1/users/onboarding", body)
        .then(() => {
          setUserData({
            ...userData,
            ...values,
          });
          const nextStepRoute =
            userData.userType === UserType.ADVERTISER
              ? "/users/onboarding/goals"
              : "/users/onboarding/phone_number";
          navigate(nextStepRoute);
        })
        .catch(noop)
        .finally(() => {
          setLoading(false);
        });
    },
  });

  useEffect(() => {
    if (userData.companyName) {
      formik.setFieldValue("companyName", userData.companyName);
    }
    if (userData.url) {
      formik.setFieldValue("url", userData.url);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData.companyName, userData.url]);

  const getErrorMessage = (fieldName: string) =>
    formik.touched[fieldName]
      ? formik.errors[fieldName]?.toString()
      : undefined;

  return (
    <div>
      <StepCaption
        heading={fullForm ? "Sign Up on Paved" : "Get Started On Paved"}
        subheading={
          fullForm
            ? "Create an account on Paved to manage your sponsorships."
            : "Finish creating your account to get started."
        }
      />
      <form onSubmit={formik.handleSubmit}>
        <SignUpField
          name="companyName"
          value={formik.values.companyName}
          caption="Company name"
          errorMessage={getErrorMessage("companyName")}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        <SignUpField
          name="url"
          value={formik.values.url}
          caption="Website"
          errorMessage={getErrorMessage("url")}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const value = e.target.value || "";
            formik.setFieldValue("url", value.toLowerCase().trim());
          }}
          onBlur={formik.handleBlur}
        />
        <SignButton
          type="submit"
          variant="contained"
          fullWidth
          size="large"
          disabled={loading}
          endIcon={
            loading ? (
              <CircularProgress size={12} sx={{ color: "#fff" }} />
            ) : null
          }
        >
          Continue
        </SignButton>
      </form>
    </div>
  );
}
