import React from "react";
import {
  Box,
  CircularProgress,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import axios from "axios";
import { ICreativeRequirement, ICreativeRequirementDraft } from "types";
import { isEmpty } from "lodash";

export type AssetType = "text" | "image" | "link";

export const LINK_ASSET_TYPE: AssetType = "link";
export const ASSET_IMAGE_MIN_WIDTH = 24;
export const ASSET_IMAGE_MAX_WIDTH = 10_000;
export const ASSET_IMAGE_MIN_HEIGHT = 24;
export const ASSET_IMAGE_MAX_HEIGHT = 10_000;
export const ASSET_TEXT_MAX_LENGTH_FROM = 4;
export const ASSET_TEXT_MAX_LENGTH_TO = 10_000;
export const ASSET_TEXT_WORDS_MAX_LENGTH_FROM = 1;
export const ASSET_TEXT_WORDS_LENGTH_TO = 1920;

const assetTypes: { value: AssetType; label: string }[] = [
  {
    value: "text",
    label: "Text",
  },
  {
    value: "image",
    label: "Image",
  },
  {
    value: "link",
    label: "Link",
  },
];

type Props = {
  prefix: string;
  values: ICreativeRequirement;
  touched: { [key: string]: boolean };
  errors: { [key: string]: string };
  index: number;
  isDeleting: boolean;
  id: number;
  siteSlug: string | undefined;
  setFieldValue: (field: string, value: string) => void;
  handleChange: (e: SelectChangeEvent | React.ChangeEvent) => void;
  handleBlur: (e: React.FocusEvent) => void;
  handleRemove: (index: number) => void;
  handleCreate: (values: ICreativeRequirementDraft, prefix: string) => void;
};

const CHARACTERS_PER_WORD = 5.2;

export default function CreativeAsset({
  prefix,
  values,
  touched,
  errors,
  index,
  isDeleting,
  siteSlug,
  id,
  handleChange,
  handleBlur,
  handleRemove,
  handleCreate,
}: Props) {
  const onBlur = (
    event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement, Element>
  ) => {
    handleBlur(event);
    if (!isEmpty(errors)) {
      return;
    }

    if (!values.uuid) {
      handleCreate(values, prefix);
      return;
    }

    const { length_type, ...rest } = values;

    let newMaxLength = values.max_length;
    if (length_type === "words" && values.max_length) {
      newMaxLength = Math.round(values.max_length * CHARACTERS_PER_WORD);
    }

    const rawData = { id, ...rest, max_length: newMaxLength };

    axios({
      method: "patch",
      url: `/api/v1/publisher/sites/${siteSlug}/sponsorship_options/sponsored_email/creative`,
      data: rawData,
    });
  };

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Grid container spacing={2} flex={1}>
        <Grid item xs={12} md={6} lg={4}>
          <InputLabel
            htmlFor={`${prefix}.name`}
            sx={{
              color: "text.primary",
              fontWeight: 600,
              fontSize: "0.875rem",
            }}
          >
            Asset Name
          </InputLabel>
          <TextField
            id={`${prefix}.name`}
            name={`${prefix}.name`}
            fullWidth
            value={values.name || ""}
            onChange={handleChange}
            onBlur={onBlur}
            error={touched.name && Boolean(errors.name)}
            data-error-element={Boolean(errors.name)}
            helperText={touched.name && Boolean(errors.name) ? errors.name : ""}
            sx={{
              "& .MuiInputBase-input": {
                background: "white",
              },
            }}
          />
        </Grid>
        <Grid item xs={12} md={4} lg={2}>
          <InputLabel
            id={`${prefix}.creative_type`}
            sx={{
              color: "text.primary",
              fontWeight: 600,
              fontSize: "0.875rem",
            }}
          >
            Asset Type
          </InputLabel>
          <Select
            labelId={`${prefix}.creative_type`}
            name={`${prefix}.creative_type`}
            fullWidth={true}
            value={values.creative_type || ""}
            onChange={handleChange}
            onBlur={onBlur}
            error={touched.creative_type && Boolean(errors.creative_type)}
            sx={{
              "& .MuiInputBase-input": {
                background: "white",
              },
            }}
          >
            {assetTypes.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
          {touched.creative_type && errors.creative_type && (
            <FormHelperText error>{errors.creative_type}</FormHelperText>
          )}
        </Grid>
        {values.creative_type === "text" && (
          <Grid item xs={12} md={6} lg={3}>
            <InputLabel
              htmlFor={`${prefix}.max_length`}
              sx={{
                color: "text.primary",
                fontWeight: 600,
                fontSize: "0.875rem",
              }}
            >
              Max Length
            </InputLabel>
            <TextField
              fullWidth={true}
              id={`${prefix}.max_length`}
              name={`${prefix}.max_length`}
              type="number"
              value={values.max_length}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" sx={{ marginLeft: 0 }}>
                    <Select
                      id={`${prefix}.length_type`}
                      name={`${prefix}.length_type`}
                      value={values.length_type || "characters"}
                      onChange={handleChange}
                      onBlur={onBlur}
                      sx={{
                        borderTopLeftRadius: 0,
                        borderBottomLeftRadius: 0,
                      }}
                    >
                      <MenuItem key="characters" value="characters">
                        characters
                      </MenuItem>
                      <MenuItem key="words" value="words">
                        words
                      </MenuItem>
                    </Select>
                  </InputAdornment>
                ),
              }}
              onChange={handleChange}
              onBlur={onBlur}
              error={touched.max_length && Boolean(errors.max_length)}
              helperText={
                touched.max_length && Boolean(errors.max_length)
                  ? errors.max_length
                  : ""
              }
              sx={{
                "& .MuiInputBase-input": {
                  background: "white",
                },
              }}
            />
          </Grid>
        )}
        {values.creative_type === "image" && (
          <>
            <Grid item xs={12} md={4} lg={2.5}>
              <InputLabel
                htmlFor={`${prefix}.width`}
                sx={{
                  color: "text.primary",
                  fontWeight: 600,
                  fontSize: "0.875rem",
                }}
              >
                Width
              </InputLabel>
              <TextField
                fullWidth={true}
                id={`${prefix}.width`}
                name={`${prefix}.width`}
                type="number"
                value={values.width}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" sx={{ marginLeft: 0 }}>
                      <Box
                        sx={{
                          padding: ".375rem .75rem",
                          backgroundColor: "#fbfbfc",
                          borderLeft: "1px solid rgba(0,40,100,.12)",
                        }}
                      >
                        px
                      </Box>
                    </InputAdornment>
                  ),
                }}
                onChange={handleChange}
                onBlur={onBlur}
                error={touched.width && Boolean(errors.width)}
                data-error-element={Boolean(errors.width)}
                helperText={
                  touched.width && Boolean(errors.width) ? errors.width : ""
                }
                sx={{
                  "& .MuiInputBase-input": {
                    background: "white",
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={2.5}>
              <InputLabel
                htmlFor={`${prefix}.height`}
                sx={{
                  color: "text.primary",
                  fontWeight: 600,
                  fontSize: "0.875rem",
                }}
              >
                Height
              </InputLabel>
              <TextField
                fullWidth={true}
                id={`${prefix}.height`}
                name={`${prefix}.height`}
                type="number"
                value={values.height}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" sx={{ marginLeft: 0 }}>
                      <Box
                        sx={{
                          padding: ".375rem .75rem",
                          backgroundColor: "#fbfbfc",
                          borderLeft: "1px solid rgba(0,40,100,.12)",
                        }}
                      >
                        px
                      </Box>
                    </InputAdornment>
                  ),
                }}
                onChange={handleChange}
                onBlur={onBlur}
                error={touched.height && Boolean(errors.height)}
                data-error-element={Boolean(errors.height)}
                helperText={
                  touched.height && Boolean(errors.height) ? errors.height : ""
                }
                sx={{
                  "& .MuiInputBase-input": {
                    background: "white",
                  },
                }}
              />
            </Grid>
          </>
        )}
      </Grid>
      <IconButton
        color="primary"
        disabled={isDeleting}
        onClick={() => handleRemove(index)}
      >
        {isDeleting ? <CircularProgress size={20} /> : <DeleteOutlineIcon />}
      </IconButton>
    </Box>
  );
}
