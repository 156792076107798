import { ErrorMessage, Field, FormikErrors, FormikTouched } from "formik";
import cls from "classnames";
import { toTitleCase } from "utils";
import { IconButton } from "@mui/material";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";

export type Values = {
  email: string;
  password: string;
};

export default function SignInField({
  touched,
  errors,
  name,
  showPassword,
  onEndIconClick,
  type,
  onBlur,
}: {
  touched: FormikTouched<Values>;
  errors: FormikErrors<Values>;
  name: "email" | "password";
  showPassword?: boolean;
  onEndIconClick?: () => void;
  type: "email" | "password" | "text";
  onBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
}) {
  const caption = toTitleCase(name);

  return (
    <div className="form-group floater">
      {name === "password" && (
        <IconButton
          onClick={onEndIconClick}
          sx={{ position: "absolute", top: 8, right: 4 }}
          tabIndex={-1}
        >
          {showPassword ? <VisibilityOff /> : <Visibility />}
        </IconButton>
      )}
      <Field
        className={cls("form-input", {
          "parsley-error": touched[name] && errors[name],
        })}
        type={type}
        name={name}
        placeholder={caption}
        autoFocus
        {...(name === "email" && {
          minLength: 5,
          maxLength: 50,
        })}
        {...(name === "password" && {
          maxLength: 128,
        })}
        onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
          if (e.target.value !== "") {
            onBlur(e);
          }
        }}
      />
      <ErrorMessage
        name={name}
        render={(msg: string) => (
          <ul className="parsley-errors-list filled">
            <li className="parsley-required">{msg}</li>
          </ul>
        )}
      />
      <label htmlFor={name}>{caption}</label>
    </div>
  );
}
