import React from "react";
import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import Interval from "./Interval";
import MonthlyDaySelector from "./MonthlyDaySelector";
import MonthlyWeekSelector from "./MonthlyWeekSelector";
import type { MonthlyGridType } from "./index";

export default function Monthly({
  interval,
  setInterval,
  bymonthday,
  setBymonthday,
  bymonthweekday,
  setBymonthweekday,
  monthlyGridType,
  setMonthlyGridType,
}: {
  interval: number;
  setInterval: (interval: number) => void;
  bymonthday: number[];
  setBymonthday: (bymonthday: number[]) => void;
  bymonthweekday: string[];
  setBymonthweekday: (bymonthweekday: string[]) => void;
  monthlyGridType: MonthlyGridType;
  setMonthlyGridType: (gridType: MonthlyGridType) => void;
}) {
  return (
    <>
      <Interval interval={interval} setInterval={setInterval} caption="month" />
      <FormControl>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          value={monthlyGridType}
          onChange={(event) =>
            setMonthlyGridType(event.target.value as MonthlyGridType)
          }
          sx={{ marginBottom: 2 }}
        >
          <FormControlLabel
            value="daysOfMonth"
            control={<Radio />}
            label="Day of month"
          />
          <FormControlLabel
            value="daysOfWeek"
            control={<Radio />}
            label="Day of week"
          />
        </RadioGroup>
      </FormControl>
      {monthlyGridType === "daysOfMonth" ? (
        <MonthlyDaySelector onChange={setBymonthday} bymonthday={bymonthday} />
      ) : (
        <MonthlyWeekSelector
          onChange={setBymonthweekday}
          bymonthweekday={bymonthweekday}
        />
      )}
    </>
  );
}
