import React, { createContext, useState, ReactNode, useContext } from "react";
import noop from "lodash/noop";
import { ISite } from "types";
import { UserContext } from "context";

interface ISiteContext {
  siteData: ISite;
  setSiteData: (value: ISite) => void;
  bookerEnabled: boolean;
}

const DEFAULT_STATE: ISiteContext = {
  siteData: {},
  setSiteData: noop,
  bookerEnabled: false,
};

export const SiteContext = createContext(DEFAULT_STATE);

export interface ISiteProviderProps {
  children?: ReactNode;
}

export const SiteProvider = ({ children }: ISiteProviderProps) => {
  const [siteData, setSiteData] = useState(DEFAULT_STATE.siteData);

  const {
    userData: { has_booker_access },
  } = useContext(UserContext);

  return (
    <SiteContext.Provider
      value={{
        siteData,
        setSiteData,
        bookerEnabled: !!(has_booker_access && siteData.email_ads),
      }}
    >
      {children}
    </SiteContext.Provider>
  );
};
