import React, { useState, useEffect, useMemo, useContext } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import axios from "axios";
import { FieldArray, FieldArrayRenderProps } from "formik";
import { faListCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CreativeAsset from "./CreativeAsset";
import { ICreativeRequirement, ICreativeRequirementDraft } from "types";
import noop from "lodash/noop";
import { LayoutContext } from "context";

export type AssetError = {
  [key: string]: string;
};

export type CreativeRequirementsErrors = {
  nonLinkAsset?: string;
  linkAsset?: string;
  assetsErrors?: AssetError[];
};

type Props = {
  id: number;
  prefix: string;
  values: ICreativeRequirement[] | undefined;
  touched: { [key: string]: boolean }[];
  errors: CreativeRequirementsErrors;
  setFieldValue: (field: string, value: string | any[]) => void;
  handleChange: (e: SelectChangeEvent | React.ChangeEvent) => void;
  handleBlur: (e: React.FocusEvent) => void;
};

function getCreativeItems(
  values: (ICreativeRequirementDraft | ICreativeRequirement)[]
) {
  // TODO: Do we really can get here not an array?
  if (!Array.isArray(values)) {
    return values;
  }

  return values.map((value) => ({
    ...value,
    name: value.name || "",
    creative_type: value.creative_type || "",
    length_type: "characters",
  }));
}

export default function CreativeRequirements({
  id,
  prefix,
  values,
  touched,
  errors,
  setFieldValue,
  handleChange,
  handleBlur,
}: Props) {
  const theme = useTheme();
  const { siteSlug } = useParams();
  const apiUrl = useMemo(
    () =>
      `/api/v1/publisher/sites/${siteSlug}/sponsorship_options/sponsored_email/creative?id=${id}`,
    [siteSlug, id]
  );
  const { isDataLoading } = useContext(LayoutContext);
  const [isLoading, setIsLoading] = useState(true);
  const [isDeleting, setIsDeleting] = useState<{ [key: string]: boolean }>({});

  useEffect(() => {
    if (!id) return;
    setIsLoading(true);
    axios({
      method: "get",
      url: apiUrl,
    })
      .then((response) => {
        setFieldValue(prefix, getCreativeItems(response.data));
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [apiUrl, id, prefix, setFieldValue]);

  const onClickSuggestedFormat = () => {
    setIsLoading(true);
    axios({
      method: "post",
      url: `/api/v1/publisher/sites/${siteSlug}/sponsorship_options/sponsored_email/add_default_creative_requirements?id=${id}`,
    })
      .then((response) => {
        setFieldValue(prefix, getCreativeItems(response.data));
      })
      .catch(noop)
      .finally(() => {
        setIsLoading(false);
      });
  };

  const createAsset = (values: ICreativeRequirementDraft, prefix: string) => {
    setIsLoading(true);
    axios({
      method: "post",
      url: apiUrl,
      data: values,
    })
      .then((response) => {
        setFieldValue(prefix, response.data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onClickAddCreative = (pushCreative: any) => {
    const newItem = {
      creative_type: "",
      name: "",
    };

    if (pushCreative) {
      pushCreative(newItem);
    } else {
      setFieldValue(prefix, getCreativeItems([newItem]));
    }
  };

  const onClickRemoveCreative = (
    remove: any,
    prefix: string,
    index: number,
    uuid: string | undefined
  ) => {
    if (!uuid) {
      remove(index);
      return;
    }

    setIsDeleting((prev) => ({ ...prev, [prefix]: true }));
    axios({
      method: "delete",
      url: `${apiUrl}&uuid=${uuid}`,
    })
      .then(() => {
        remove(index);
      })
      .finally(() => {
        setIsDeleting((prev) => ({ ...prev, [prefix]: false }));
      });
  };

  return (
    <Box>
      <Typography
        sx={{
          fontWeight: 400,
          fontSize: "1.125rem",
          color: "#0e1952",
          lineHeight: 1.2,
          mb: 2,
        }}
      >
        Sponsorship Format
      </Typography>
      <Box
        padding={2}
        borderRadius={0.5}
        border="1px solid #e9ecef"
        sx={{
          background: "#f8f9fa",
        }}
      >
        {!values || values.length === 0 ? (
          <Grid container justifyContent="center">
            <Grid item xs={12} lg={8}>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="flex-center"
                textAlign="center"
                gap={1}
                paddingY={4.5}
              >
                <FontAwesomeIcon icon={faListCheck} size="xl" />
                <Typography variant="h6" color="text.primary">
                  Creative Requirements
                </Typography>
                <Typography color="text.secondary">
                  Let advertisers know what assets you will need from them. If
                  you don't have a sponsorship format, we recommend you use our
                  default format.
                </Typography>
                <Box
                  display="flex"
                  justifyContent="center"
                  gap={0.5}
                  sx={{
                    [theme.breakpoints.down("md")]: {
                      flexDirection: "column",
                    },
                  }}
                >
                  <Button
                    color="primary"
                    variant="contained"
                    disabled={isLoading}
                    endIcon={
                      isLoading || isDataLoading ? (
                        <CircularProgress size={12} sx={{ color: "#fff" }} />
                      ) : null
                    }
                    onClick={onClickSuggestedFormat}
                  >
                    Use Suggested Format
                  </Button>
                  <Button
                    color="primary"
                    variant="outlined"
                    sx={{
                      color: "text.primary",
                    }}
                    disabled={isLoading}
                    endIcon={
                      isLoading || isDataLoading ? (
                        <CircularProgress size={12} />
                      ) : null
                    }
                    onClick={() => onClickAddCreative(null)}
                  >
                    Use Custom Format
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        ) : (
          <>
            {touched && errors.linkAsset && (
              <Typography
                component="span"
                sx={{ color: "error.main", mb: 2, display: "block" }}
                data-error-element={Boolean(errors.linkAsset)}
              >
                {errors.linkAsset}
              </Typography>
            )}
            {touched && errors.nonLinkAsset && (
              <Typography
                component="span"
                sx={{ color: "error.main", mb: 2, display: "block" }}
                data-error-element={Boolean(errors.nonLinkAsset)}
              >
                {errors.nonLinkAsset}
              </Typography>
            )}
            <FieldArray name={prefix}>
              {({ remove, push }: FieldArrayRenderProps) => (
                <Box display="flex" flexDirection="column" gap={6}>
                  <Typography>
                    Select the creative you will need the advertiser to submit
                    for this placement.
                  </Typography>
                  {values.map((asset: any, index: number) => {
                    const assetPrefix = `${prefix}.${index}`;
                    return (
                      <CreativeAsset
                        key={`creativeAsset-${index}`}
                        prefix={assetPrefix}
                        index={index}
                        values={values[index] || {}}
                        errors={errors?.assetsErrors?.[index] || {}}
                        touched={touched?.[index] || {}}
                        isDeleting={isDeleting[assetPrefix]}
                        siteSlug={siteSlug}
                        id={id}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        setFieldValue={setFieldValue}
                        handleRemove={(index: number) =>
                          onClickRemoveCreative(
                            remove,
                            assetPrefix,
                            index,
                            asset.uuid
                          )
                        }
                        handleCreate={createAsset}
                      />
                    );
                  })}
                  <Box display="flex" justifyContent="flex-end" gap={1}>
                    <Button
                      variant="contained"
                      size="small"
                      disabled={isLoading}
                      startIcon={
                        isLoading || isDataLoading ? (
                          <CircularProgress size={12} sx={{ color: "#fff" }} />
                        ) : (
                          "+"
                        )
                      }
                      onClick={() => onClickAddCreative(push)}
                    >
                      Add Creative Asset
                    </Button>
                  </Box>
                </Box>
              )}
            </FieldArray>
          </>
        )}
      </Box>
    </Box>
  );
}
