import * as React from "react";
import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";

export default styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: 1.5,
    maxWidth: "200px",
    padding: "0.25rem 0.5rem",
    color: "#fff",
    borderRadius: "3px",
    textAlign: "center",
  },
}));
