import Slider from "./Slider";
import { SyntheticEvent, useState } from "react";
import { RangeInputProps, useRange } from "react-instantsearch";

const MAX = 1000000;
const MIN = 0;
const STEP = 10000;

export default function SubscriberSlider(props: RangeInputProps) {
  const { start, refine } = useRange({ ...props, min: MIN });
  const [value, setValue] = useState([
    Number.isFinite(start[0] as number) ? (start[0] as number) : MIN,
    Number.isFinite(start[1] as number) ? (start[1] as number) : MAX,
  ]);

  const handleChange = (e: Event, newValue: number | number[]) => {
    setValue(newValue as number[]);
  };

  const handleChangeCommited = (
    e: Event | SyntheticEvent<Element, Event>,
    value: number | number[]
  ) => {
    const [min, max] = value as number[];
    refine([min, max === MAX ? undefined : max]);
  };

  return (
    <Slider
      value={value}
      min={MIN}
      max={MAX}
      step={STEP}
      handleChange={handleChange}
      handleChangeCommitted={handleChangeCommited}
    />
  );
}
