import React, { createContext, useState, ReactNode } from "react";
import noop from "lodash/noop";

interface ILayoutContext {
  isDataLoading: boolean;
  setIsDataLoading: (value: boolean) => void;
}

const DEFAULT_STATE: ILayoutContext = {
  isDataLoading: false,
  setIsDataLoading: noop,
};

export const LayoutContext = createContext(DEFAULT_STATE);

export interface ILayoutProviderProps {
  children?: ReactNode;
}

export const LayoutProvider = ({ children }: ILayoutProviderProps) => {
  const [isDataLoading, setIsDataLoading] = useState(
    DEFAULT_STATE.isDataLoading
  );

  return (
    <LayoutContext.Provider
      value={{
        isDataLoading,
        setIsDataLoading,
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
};
