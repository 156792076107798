import { RefinementListProps, useRefinementList } from "react-instantsearch";

export default function CustomRefinementList(props: RefinementListProps) {
  const { items, refine } = useRefinementList(props);

  return (
    <ul className="ais-RefinementList-list">
      {items.map((item) => (
        <li key={item.label}>
          <div className="line">
            <label className="form-checkbox">
              <input
                type="checkbox"
                name={props.attribute}
                value={item.label}
                checked={item.isRefined}
                onChange={() => refine(item.value)}
              />
              {item.label}
              <i className="dot" aria-hidden="true"></i>
            </label>
          </div>
        </li>
      ))}
    </ul>
  );
}
