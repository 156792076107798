import { EventBusyOutlined, InfoOutlined } from "@mui/icons-material";
import { Box, Switch, Typography } from "@mui/material";
import React from "react";

interface MarkAsReservedSwitchProps {
  markAsReserved: boolean;
  setMarkAsReserved: React.Dispatch<React.SetStateAction<boolean>>;
}

const MarkAsReservedSwitch: React.FC<MarkAsReservedSwitchProps> = ({
  markAsReserved,
  setMarkAsReserved,
}) => {
  return (
    <Box position="relative">
      <Box display="flex" alignItems="center" mt={2}>
        <EventBusyOutlined sx={{ mr: 1, color: "text.disabled" }} />
        <Typography>Mark as Reserved</Typography>
        <Switch
          sx={{ ml: "auto" }}
          checked={markAsReserved}
          onChange={() => setMarkAsReserved((prev) => !prev)}
        />
      </Box>
      {markAsReserved && (
        <Box display="flex" gap={0.5} mt={2} position="absolute" bottom={-50}>
          <InfoOutlined color="error" />
          <Typography variant="caption" color="error.main">
            Marking this date reserved will prevent advertisers from booking
            placement on this day.
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default MarkAsReservedSwitch;
