import React from "react";
import { Paper } from "@mui/material";

export default function NoPayoutsInfo({
  hasPayoutDetails,
}: {
  hasPayoutDetails: boolean;
}) {
  return (
    <Paper sx={{ textAlign: "center", p: 4 }}>
      {hasPayoutDetails ? (
        <span>
          You don't have any payouts yet. We have your payout details and you're
          all set.
        </span>
      ) : (
        <span>
          You don't have any payouts yet. But, let's get ready by
          <a href="/publisher/payout_details"> entering your payout details</a>.
        </span>
      )}
    </Paper>
  );
}
