import React, { useState, useContext } from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { IPublisher } from "./types";
import axios from "axios";
import { Link } from "react-router-dom";
import { LayoutContext, UserContext } from "context";
import useComponentDidMount from "hooks/useComponentDidMount";
import SitesList from "./SitesList";
import EmptySitesList from "./EmptySitesList";
import noop from "lodash/noop";

export default function Publisher() {
  const [publishers, setPublishers] = useState<IPublisher[] | undefined>();
  const { setIsDataLoading } = useContext(LayoutContext);
  const { userData } = useContext(UserContext);

  useComponentDidMount(() => {
    setIsDataLoading(true);

    axios({
      method: "get",
      url: "/api/v1/publisher/sites",
    })
      .then(function (res) {
        setPublishers(res?.data);
        setIsDataLoading(false);
      })
      .catch(noop);
  });

  const renderContent = () => {
    if (!userData.firstName || publishers === undefined) {
      return null;
    }

    if (!publishers.length) {
      return <EmptySitesList />;
    }

    return <SitesList publishers={publishers} />;
  };

  return (
    <>
      {publishers && !!publishers.length && (
        <Grid
          item
          xs={12}
          container
          justifyContent="space-between"
          alignItems="center"
          paddingY={3}
        >
          <Typography variant="h5" component="h1" color="text.primary">
            Your Profiles
          </Typography>
          <Link to="/publisher/sites/new">
            <Button variant="contained" sx={{ ml: 2 }}>
              + New Profile
            </Button>
          </Link>
        </Grid>
      )}
      {renderContent()}
    </>
  );
}
