import React from "react";
import {
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableContainer,
  Link,
} from "@mui/material";
import TableCell from "@mui/material/TableCell/TableCell";
import { IPayment } from "./types";
import { format, parseISO } from "date-fns";
import { currencyFormatter } from "utils/formatters";
import StatusItem from "components/StatusItem";

export default function PaymentsList({ payments }: { payments: IPayment[] }) {
  return (
    <TableContainer component={Paper} sx={{ p: 3 }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>REFERENCE</TableCell>
            <TableCell>STATUS</TableCell>
            <TableCell>PAYOUT METHOD</TableCell>
            <TableCell>NET AMOUNT</TableCell>
            <TableCell>PAID DATE</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {payments.map((payment, index) => (
            <TableRow
              key={!!payment.uuid ? payment.uuid : index}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell align="left">
                {payment.status.toLowerCase() === "sent" ? (
                  <>
                    <Link
                      href={`/publisher/payments/${payment.uuid}`}
                      sx={{ textDecoration: "none" }}
                    >
                      {payment.memo}
                    </Link>
                    {payment.site_name && (
                      <>
                        <br />
                        <small>with {payment.site_name}</small>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <span>{payment.memo}</span>
                    {payment.site_name && (
                      <>
                        <br />
                        <small>with {payment.site_name}</small>
                      </>
                    )}
                  </>
                )}
              </TableCell>
              <TableCell align="left">
                <StatusItem status={payment.status} />
              </TableCell>
              <TableCell align="left">{payment.payout_method}</TableCell>
              <TableCell align="left">
                {currencyFormatter(payment.amount)}
              </TableCell>
              <TableCell align="left">
                {!payment.paid_date ? (
                  <span>&mdash;</span>
                ) : (
                  format(
                    parseISO(payment.paid_date.split("T")[0]),
                    "d MMM yyyy"
                  )
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
