import React, { useContext } from "react";
import { SignUpContext } from "./context";
import { useAxios } from "./hooks";
import useComponentDidMount from "hooks/useComponentDidMount";
import { Outlet, useLocation } from "react-router-dom";
import noop from "lodash/noop";
import { sanitizeFormValues } from "./utils";

export default function SignUpForm() {
  const { pathname } = useLocation();
  const {
    onboardingValues,
    setOnboardingValues,
    userData,
    setUserData,
    setSegmentsData,
  } = useContext(SignUpContext);

  const { requestApi } = useAxios();

  const getOnboardingValues = () => {
    requestApi("get", "/api/v1/users/onboarding_values")
      .then((data) => {
        setOnboardingValues(data);
      })
      .catch(noop);
  };

  useComponentDidMount(() => {
    if (!onboardingValues) getOnboardingValues();

    if (pathname.includes("/users/onboarding/")) {
      requestApi("get", "/api/v1/users/me")
        .then((res) => {
          const {
            first_name,
            last_name,
            company_name,
            user_type,
            email,
            ro_id,
            created_at,
          } = res;

          const meData = sanitizeFormValues({
            firstName: first_name,
            lastName: last_name,
            companyName: company_name,
            userType: user_type,
            email,
          });

          setUserData({ ...userData, ...meData });
          if (!!ro_id && !!created_at) {
            setSegmentsData({
              ro_id,
              created_at,
            });
          }
        })
        .catch(noop);

      // TODO: company lookup ?
    }
  });

  return (
    <div className="signup-steps">
      <Outlet />
    </div>
  );
}
