import { EventClickArg, EventInput } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import FullCalendar from "@fullcalendar/react";
import { Box } from "@mui/material";
import React, { useRef, useState } from "react";
import AddReservationModal from "./AddReservationModal";
import "./CalendarComponent.scss";
import CalendarEvent from "./CalendarEvent";
import {
  ActionType,
  isValidYYYYDashMMDashDDDateString,
  ExtendedProps,
  SelectedEventDetails,
  SponsorshipSource,
} from "./types";
import SetCustomPriceModal from "./SetCustomPriceModal";

export interface CalendarComponentProps {
  siteSlug: string;
  events: EventInput[];
  onDatesSet: (start: string, end: string) => void;
  reloadEvents: () => void;
}

const CalendarComponent: React.FC<CalendarComponentProps> = ({
  siteSlug,
  events,
  onDatesSet,
  reloadEvents,
}) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedEvent, setSelectedEvent] =
    useState<SelectedEventDetails | null>(null);

  const isInitialRender = useRef(true);

  const handleEventClick = (clickInfo: EventClickArg) => {
    const event = clickInfo.event;
    const { reserved, source, campaign_id } =
      event.extendedProps as ExtendedProps;
    if (!event.id) return;

    if (!isValidYYYYDashMMDashDDDateString(event.startStr)) {
      console.error("Invalid date format:", event.startStr);
      return;
    }

    if (!reserved) {
      setSelectedEvent({
        startDate: event.startStr,
        actionType: ActionType.REMOVE,
        title: event.title,
        extendedProps: event.extendedProps as ExtendedProps,
      });
      setIsModalOpen(true);
    } else if (source === SponsorshipSource.BOOKER) {
      //TODO: handle this with snackbar
      alert(
        "You can't cancel this reservation, as the advertiser is currently in the process of booking this date."
      );
    } else if (campaign_id) {
      window.open(`/publisher/campaigns/${campaign_id}`, "_blank");
    } else if (reserved) {
      setSelectedEvent({
        startDate: event.startStr,
        actionType: ActionType.ADD,
        title: event.title,
        extendedProps: event.extendedProps as ExtendedProps,
      });
      setIsModalOpen(true);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedEvent(null);
    reloadEvents();
  };

  const handleDatesSet = (dateInfo: { startStr: string; endStr: string }) => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }
    const { startStr, endStr } = dateInfo;
    onDatesSet(startStr, endStr);
  };

  return (
    <Box>
      <FullCalendar
        plugins={[dayGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        events={events}
        eventClick={handleEventClick}
        height="auto"
        showNonCurrentDates={false}
        datesSet={handleDatesSet}
        eventBackgroundColor="transparent"
        eventBorderColor="transparent"
        eventContent={(args) => <CalendarEvent event={args.event} />}
      />

      {selectedEvent &&
        (selectedEvent.actionType === "add" ? (
          <AddReservationModal
            open={isModalOpen}
            handleClose={handleCloseModal}
            siteSlug={siteSlug}
            selectedEvent={selectedEvent}
            reloadEvents={reloadEvents}
          />
        ) : (
          <SetCustomPriceModal
            open={isModalOpen}
            handleClose={handleCloseModal}
            siteSlug={siteSlug}
            selectedEvent={selectedEvent}
            reloadEvents={reloadEvents}
          />
        ))}
    </Box>
  );
};

export default CalendarComponent;
