import React from "react";
import { InstantSearch } from "react-instantsearch";
import ConnectedAutoComplete from "./ConnectedAutoComplete";
import { SearchClient } from "algoliasearch/lite";

type AlogoliaSearchProps = {
  indexName: string;
  searchClient: SearchClient;
  isEmbedded: boolean;
};

export default function AlogoliaSearch({
  indexName,
  searchClient,
  isEmbedded,
}: AlogoliaSearchProps) {
  return (
    <InstantSearch indexName={indexName} searchClient={searchClient}>
      <ConnectedAutoComplete isEmbedded={isEmbedded} />
    </InstantSearch>
  );
}
