import { Button, Card } from "@mui/material";
import { Link } from "react-router-dom";

export default function EmptySitesList() {
  return (
    <Card sx={{ textAlign: "center", pb: 3, mt: 3 }}>
      <h2>Welcome to Paved</h2>
      <h4>Let's create your newsletter profile.</h4>
      <p>
        Get started by creating your profile to access all Paved's products
        including The Marketplace, Ad Network, and Booker.{" "}
        <a
          href="https://www.paved.com/blog/how-to-build-a-paved-profile-to-attract-more-sponsors/"
          target="_blank"
          rel="noreferrer"
        >
          Learn More
        </a>
      </p>
      <Link to="/publisher/sites/new">
        <Button variant="contained" color="success">
          Create Newsletter Profile
        </Button>
      </Link>
    </Card>
  );
}
