import React from "react";
import { Typography, List } from "@mui/material";
import { IProfileErrorGroup } from "./index";
import ProfileErrorGroup from "./ProfileErrorGroup";

interface ReviewStatusProps {
  profile_error_groups: IProfileErrorGroup[];
}

export default function ReviewStatus({
  profile_error_groups,
}: ReviewStatusProps) {
  return (
    <>
      {profile_error_groups.length === 0 ? (
        <>
          <Typography>
            Thank you for completing your profile! Please preview your profile
            with the button below to make sure everything looks right before
            submitting for review.
          </Typography>
          <Typography sx={{ marginTop: 2 }}>
            We will notify you once your profile has been reviewed by our team.
          </Typography>
        </>
      ) : (
        <>
          <Typography>
            Your profile is incomplete. Please return to the profile builder to
            fill in the following missing information:
          </Typography>
          <List>
            {profile_error_groups.map((item, index) => (
              <ProfileErrorGroup item={item} key={index} />
            ))}
          </List>
        </>
      )}
    </>
  );
}
