export const toCamel = (str: string) =>
  str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());

export const toSnake = (str: string) =>
  str &&
  str
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    ?.map((x) => x.toLowerCase())
    .join("_");

export const toTitleCase = (str: string) =>
  str.replace(/^_*(.)|_+(.)/g, (s, c, d) =>
    c ? c.toUpperCase() : " " + d.toUpperCase()
  );

export const keysToSnake = (object: any) => {
  const newObject: any = {};
  Object.keys(object).forEach((key) => {
    const snakeKey = toSnake(key);
    if (snakeKey) newObject[snakeKey] = object[key];
  });
  return newObject;
};

export const imageExists = (image_url: string) => {
  try {
    const http = new XMLHttpRequest();

    http.open("HEAD", image_url, false);
    http.send();

    return http.status !== 404;
  } catch {
    return false;
  }
};
