import {
  Button,
  IconButton,
  Link,
  Dialog,
  DialogContent,
  DialogActions,
  DialogContentText,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export default function PriceValidationModal({
  open,
  setPriceModalOpen,
}: {
  open: boolean;
  setPriceModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const handleClose = (
    event: React.MouseEvent | React.KeyboardEvent,
    reason?: "backdropClick" | "escapeKeyDown"
  ) => {
    if (reason === "backdropClick") {
      return;
    }
    setPriceModalOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
      disableEscapeKeyDown
    >
      <IconButton
        onClick={handleClose}
        style={{ position: "absolute", right: "10px", top: "10px" }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <DialogContentText sx={{ mt: 2, color: "#000" }}>
          We recommend different pricing for sponsored vs. dedicated emails.
          <div>
            <Link
              href="https://www.paved.com/blog/sponsored-vs-dedicated-emails-a-complete-guide/"
              target="_blank"
            >
              Learn more
            </Link>
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "end", p: 2 }}>
        <Button variant="contained" color="primary" onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
