import { Box, useTheme } from "@mui/material";
import { ReactNode } from "react";

type WrapperProps = {
  error?: boolean;
  warning?: boolean;
  children?: ReactNode;
};

export default function Wrapper(props: WrapperProps) {
  const { error, warning } = props;
  const theme = useTheme();

  const warningColor = "#DE5D06";
  const initialColor = "#CDD5E4";

  const borderColor = error
    ? theme.palette.error.main
    : warning
    ? warningColor
    : initialColor;

  const bgImage = `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='5' ry='5' stroke='${encodeURIComponent(
    borderColor
  )}' stroke-width='3' stroke-dasharray='6%2c 10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e" )`;

  return (
    <Box
      sx={{
        p: "15px",
        borderRadius: "5px",
        backgroundImage: bgImage,
      }}
      display="flex"
      alignItems="center"
    >
      {props.children}
    </Box>
  );
}
