import React, { useState } from "react";
import { ToggleButton, Grid } from "@mui/material";

export type MonthlyDaySelectorProps = {
  onChange: (selected: number[]) => void;
  bymonthday: number[];
};

export default function MonthlyDaySelector({
  onChange,
  bymonthday,
}: MonthlyDaySelectorProps) {
  const [selectedItems, setSelectedItems] = useState<number[]>(bymonthday);
  const daysOfMonth = Array.from({ length: 31 }, (_, i) => i + 1);

  const handleToggle = (item: number) => {
    setSelectedItems((prevItems) =>
      prevItems.includes(item)
        ? prevItems.filter((i) => i !== item)
        : [...prevItems, item]
    );
  };

  React.useEffect(() => {
    onChange(selectedItems);
  }, [selectedItems, onChange]);

  return (
    <Grid container spacing={1} sx={{ maxWidth: "392px" }}>
      {daysOfMonth.map((day) => (
        <Grid item key={day} xs={1.71}>
          <ToggleButton
            value={day}
            selected={selectedItems.includes(day)}
            onChange={() => handleToggle(day)}
            sx={{ width: 48, height: 40 }}
          >
            {day}
          </ToggleButton>
        </Grid>
      ))}
      <Grid item>
        <ToggleButton
          value={-1}
          selected={selectedItems.includes(-1)}
          onChange={() => handleToggle(-1)}
          sx={{ minWidth: 120, height: 40 }}
        >
          Last Day
        </ToggleButton>
      </Grid>
    </Grid>
  );
}
