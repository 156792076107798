import Cookies from "js-cookie";
import axios, { AxiosResponse } from "axios";

export function setAuthCookies(
  access_token: string,
  expires_in: number,
  refresh_token: string
) {
  Cookies.set("bearer", access_token);
  Cookies.set("bearer_expires", String(Date.now() + expires_in * 1000));
  Cookies.set("bearer_refresh", refresh_token);
}

export function handleAuth(response: AxiosResponse) {
  const { access_token, expires_in, refresh_token } = response.data;

  setAuthCookies(access_token, expires_in, refresh_token);
  axios.defaults.headers.common["Authorization"] = `Bearer ${access_token}`;
}
