import { useEffect, useMemo, useRef } from "react";
import debounce from "lodash/debounce";

type Callback = (value: any) => void;

export default function useDebounce(callback: Callback, delay: number) {
  const ref = useRef<Callback>();

  useEffect(() => {
    ref.current = callback;
  }, [callback]);

  return useMemo(() => {
    const func = (value: any) => {
      ref.current?.(value);
    };

    return debounce(func, delay);
  }, [delay]);
}
