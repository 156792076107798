import Button, { ButtonProps } from "@mui/material/Button";
import { styled } from "@mui/material/styles";

const SignButton = styled(Button)<ButtonProps>(({ theme }) => ({
  backgroundColor: "#0e63f4",
  mh: "52px",
  fontSize: "18px",
  paddingTop: "11px",
  paddingBottom: "11px",
  marginBottom: "10px",
}));

export default SignButton;
