import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import "./styles.scss";
import { SignUpProvider } from "./sign-up/context";
import useComponentDidMount from "hooks/useComponentDidMount";
import axios from "axios";
import { noop } from "lodash";
import { Snackbar, Alert, Stack, useMediaQuery } from "@mui/material";
import { FlashMessage } from "components/layout";

export default function Users() {
  const smallScreen = useMediaQuery("(max-width: 600px)");
  const [flashMessages, setFlashMessages] = useState<FlashMessage[]>([]);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);

  useComponentDidMount(() => {
    axios
      .get("/api/v1/users/flash_messages")
      .then((response) => {
        const messages = response.data.messages;

        if (!messages) {
          return;
        }

        const newFlashMessages: FlashMessage[] = [];

        const { notice, alert, error } = messages;

        if (notice) {
          newFlashMessages.push({ message: notice, severity: "success" });
        }
        if (alert) {
          newFlashMessages.push({ message: alert, severity: "warning" });
        }
        if (error) {
          newFlashMessages.push({ message: error, severity: "error" });
        }

        setFlashMessages(newFlashMessages);
        setOpenSnackbar(true);
      })
      .catch(noop);
  });

  const handleClose = () => {
    setOpenSnackbar(false);
  };

  return (
    <div className="page-signup">
      <main className="page page-nobottom">
        <div className="form form-signup">
          <header className="header">
            <div className="container">
              <h1 className="brand">
                <a href="/">Paved</a>
              </h1>
            </div>
          </header>
          <div className="space">
            <figure className="image">
              <img className="form-signup-theme-img" alt="Sign Up" />
            </figure>
            <SignUpProvider>
              <Outlet />
            </SignUpProvider>
          </div>
        </div>

        <Snackbar
          open={openSnackbar}
          autoHideDuration={10000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          sx={{ maxWidth: smallScreen ? "100%" : "50%", mt: 8 }}
        >
          <Stack spacing={2} sx={{ width: "100%" }}>
            {flashMessages.map((flashMessage, index) => (
              <Alert
                key={index}
                onClose={handleClose}
                severity={flashMessage.severity}
                sx={{ width: "100%" }}
              >
                {flashMessage.message}
              </Alert>
            ))}
          </Stack>
        </Snackbar>
      </main>
    </div>
  );
}
