import { useState } from "react";
import { ICreativeRequirement } from "types";
import { FormHelperText } from "@mui/material";
import ContentFileReader from "components/ContentFileReader";
import { faCode } from "@fortawesome/pro-light-svg-icons";
import AssetInfo from "./components/AssetInfo";
import AssetPreview from "./components/AssetPreview";
import CardTop from "./components/CardTop";
import Wrapper from "./components/Wrapper";

export default function CreativeItemHTMLField({
  creativeItem,
  error,
  onFileChange,
}: {
  creativeItem: ICreativeRequirement;
  error?: string;
  onFileChange: (file: File | null) => void;
}) {
  const { name, value, uuid } = creativeItem;
  const [newFilename, setNewFilename] = useState("");

  return (
    <>
      <CardTop uuid={uuid} name={name} />
      <Wrapper error={!!error}>
        <AssetPreview icon={faCode} />
        <AssetInfo
          newFilename={newFilename}
          srcUrl={value as string | null}
          placeholder="Upload an HTML file"
          extension="HTML"
        />
        <div style={{ marginLeft: "10px" }}>
          <ContentFileReader
            name={uuid}
            caption={!!newFilename || !!value ? "Change" : "Browse"}
            acceptedFileTypes=".htm,.html"
            onChange={(file) => {
              onFileChange(file);
              setNewFilename(file?.name);
            }}
          />
        </div>
      </Wrapper>
      {!!error && <FormHelperText error={true}>{error}</FormHelperText>}
    </>
  );
}
