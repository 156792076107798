import React, { useState, useContext, useEffect } from "react";
import Box from "@mui/material/Box";
import SideMenu from "./components/sideMenu/SideMenu";
import { Outlet, useParams } from "react-router-dom";
import axios from "axios";
import AddSiteBreadcrumbs from "./components/AddSiteBreadcrumbs";
import { SiteProvider, SiteContext } from "./components/SiteContext";
import { ISite } from "types";
import noop from "lodash/noop";

const Content = ({ data }: { data: ISite }) => {
  const { setSiteData } = useContext(SiteContext);

  useEffect(() => {
    if (data) {
      setSiteData(data);
    }
  }, [data, setSiteData]);

  return (
    <Box display="flex">
      <Box minWidth={240}>
        <SideMenu />
      </Box>
      <Box paddingLeft={1.5} flexGrow={1}>
        <Outlet />
      </Box>
    </Box>
  );
};

export default function AddSite() {
  const { siteSlug } = useParams();
  const [profileName, setProfileName] = useState<string>("");
  const [data, setData] = useState({});

  useEffect(() => {
    if (siteSlug) {
      axios({
        method: "get",
        url: `/api/v1/publisher/sites/${siteSlug}`,
        responseType: "json",
      })
        .then((response) => {
          setData(response.data);
          setProfileName(response.data.name);
        })
        .catch(noop);
    }
  }, [siteSlug]);

  return (
    <SiteProvider>
      <Box sx={{ flexGrow: 1 }}>
        <AddSiteBreadcrumbs
          lastItem={siteSlug ? profileName : "Add Newsletter"}
        />
        <Content data={data} />
      </Box>
    </SiteProvider>
  );
}
