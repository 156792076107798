import { styled } from "@mui/material/styles";
import Slider from "@mui/material/Slider";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { SyntheticEvent } from "react";
const iOSBoxShadow =
  "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)";

const StyledSlider = styled(Slider)(({ theme }) => ({
  color: "#007bff",
  height: 5,
  padding: "15px 0",
  "& .MuiSlider-thumb": {
    height: 20,
    width: 20,
    backgroundColor: "#fff",
    boxShadow: "0 0 2px 0px rgba(0, 0, 0, 0.1)",
    "&:focus, &:hover, &.Mui-active": {
      boxShadow: "0px 0px 3px 1px rgba(0, 0, 0, 0.1)",
      "@media (hover: none)": {
        boxShadow: iOSBoxShadow,
      },
    },
    "&:before": {
      boxShadow:
        "0px 0px 1px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 1px 0px rgba(0,0,0,0.12)",
    },
  },
  "& .MuiSlider-valueLabel": {
    fontSize: 12,
    fontWeight: "normal",
    top: -6,
    backgroundColor: "unset",
    color: theme.palette.text.primary,
    "&::before": {
      display: "none",
    },
    "& *": {
      background: "transparent",
      color: "#000",
    },
  },
  "& .MuiSlider-track": {
    border: "none",
    height: 5,
    backgroundColor: "#1C64F2",
  },
  "& .MuiSlider-rail": {
    opacity: 0.5,
    boxShadow: "inset 0px 0px 4px -2px #000",
    backgroundColor: "#d0d0d0",
  },
}));

type SliderProps = {
  value: number[];
  min: number;
  max: number;
  handleChange: (e: Event, newValue: number | number[]) => void;
  handleChangeCommitted: (
    event: Event | SyntheticEvent<Element, Event>,
    newValue: number | number[]
  ) => void;
  step: number;
  prefix?: string;
};

export default function CustomSlider({
  value,
  min,
  max,
  handleChange,
  handleChangeCommitted,
  step,
  prefix,
}: SliderProps) {
  return (
    <Box sx={{ width: 250 }}>
      <StyledSlider
        value={value}
        min={min}
        max={max}
        step={step}
        onChange={handleChange}
        onChangeCommitted={handleChangeCommitted}
      />
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="body2">
          {(prefix || "") + value[0].toLocaleString()}
        </Typography>
        <Typography variant="body2">
          {(prefix || "") +
            value[1].toLocaleString() +
            (value[1] === max ? "+" : "")}
        </Typography>
      </Box>
    </Box>
  );
}
