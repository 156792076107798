import getUnixTime from "date-fns/getUnixTime";
import { isAssumed } from "./authorize";

export default function includeCustomerIo(email, createdAt) {
  if (isAssumed()) {
    return;
  }

  if (!!process.env.REACT_APP_CUSTOMER_IO_SITE_ID) {
    window._cio = window._cio || [];
    (function () {
      var a, b, c;
      a = function (f) {
        return function () {
          window._cio.push(
            [f].concat(Array.prototype.slice.call(arguments, 0))
          );
        };
      };
      b = ["load", "identify", "sidentify", "track", "page", "on", "off"];
      for (c = 0; c < b.length; c++) {
        window._cio[b[c]] = a(b[c]);
      }
      var t = document.createElement("script"),
        s = document.getElementsByTagName("script")[0];
      t.async = true;
      t.id = "cio-tracker";
      t.setAttribute("data-site-id", process.env.REACT_APP_CUSTOMER_IO_SITE_ID);
      t.setAttribute("data-use-array-params", "true");
      t.setAttribute("data-use-in-app", "true");
      t.src = "https://assets.customer.io/assets/track.js";
      s.parentNode.insertBefore(t, s);
    })();

    window._cio.identify({
      id: email,
      created_at: getUnixTime(new Date(createdAt)),
    });
  }
}
