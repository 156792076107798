import React, { useContext } from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import { useParams } from "react-router-dom";
import { SiteContext } from "../SiteContext";
import SideMenuItem from "components/SideMenuItem";
import SideMenuHeading from "./SideMenuHeading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faAd,
  faEnvelopeOpenDollar,
  faFileInvoiceDollar,
  faRssSquare,
  faSearch,
  faCalendar,
  faBook,
  faMagnifyingGlassDollar,
  faRadar,
  faCloudCheck,
} from "@fortawesome/pro-regular-svg-icons";

export default function SideMenu() {
  const { siteSlug } = useParams();
  const { siteData } = useContext(SiteContext);

  return (
    <Box>
      <nav>
        <List component={"div"}>
          <SideMenuHeading>General</SideMenuHeading>
          <SideMenuItem
            caption="Overview"
            iconComponent={<FontAwesomeIcon icon={faEdit} />}
            to={
              siteSlug
                ? `/publisher/sites/${siteSlug}/edit`
                : "/publisher/sites/new"
            }
          />
          <SideMenuItem
            caption="Previous Ads"
            iconComponent={<FontAwesomeIcon icon={faAd} />}
            to={`/publisher/sites/${siteSlug}/edit/previous_advertisers`}
            disable={!siteSlug}
          />
          <SideMenuItem
            caption="ESP Connection"
            iconComponent={<FontAwesomeIcon icon={faCloudCheck} />}
            to={`/publisher/sites/${siteSlug}/esp`}
            nativeLink={true}
            disable={!siteSlug}
          />
          {siteData.status && siteData.status === "Live" && (
            <SideMenuItem
              caption="Manage Reviews"
              iconComponent={<FontAwesomeIcon icon={faFileInvoiceDollar} />}
              to={`/publisher/sites/${siteSlug}/reviews`}
              nativeLink={true}
            />
          )}
          {(siteData.posts || siteData.dedicated_ads || siteData.email_ads) && (
            <>
              <SideMenuHeading>Sponsorships</SideMenuHeading>
              {siteData.posts && (
                <SideMenuItem
                  caption="Sponsored Post"
                  iconComponent={<FontAwesomeIcon icon={faRssSquare} />}
                  to={`/publisher/sites/${siteSlug}/edit/sponsored_post`}
                />
              )}
              {siteData.dedicated_ads && (
                <SideMenuItem
                  caption="Dedicated Email"
                  iconComponent={<FontAwesomeIcon icon={faFileInvoiceDollar} />}
                  to={`/publisher/sites/${siteSlug}/edit/dedicated_email`}
                  disable={!siteSlug}
                />
              )}
              {siteData.email_ads && (
                <>
                  <SideMenuItem
                    caption="Sponsored Email"
                    iconComponent={
                      <FontAwesomeIcon icon={faEnvelopeOpenDollar} />
                    }
                    to={`/publisher/sites/${siteSlug}/edit/sponsored_email`}
                  />
                </>
              )}
              {(siteData.dedicated_ads || siteData.email_ads) && (
                <SideMenuItem
                  caption="Schedule"
                  iconComponent={<FontAwesomeIcon icon={faCalendar} />}
                  to={`/publisher/sites/${siteSlug}/schedule`}
                  nativeLink={true}
                />
              )}
            </>
          )}
          <SideMenuHeading>Marketplace</SideMenuHeading>
          {siteData.status && siteData.status !== "Live" && (
            <SideMenuItem
              caption="Review"
              iconComponent={<FontAwesomeIcon icon={faSearch} />}
              to={`/publisher/sites/${siteSlug}/edit/review`}
              disable={!siteSlug}
            />
          )}
          <SideMenuItem
            caption={
              siteData.status === "Live" ? "View Profile" : "Preview Profile"
            }
            iconComponent={<FontAwesomeIcon icon={faMagnifyingGlassDollar} />}
            to={`/sites/${siteSlug}`}
            disable={!siteSlug}
            target={"_blank"}
          />
          <SideMenuHeading>Booker</SideMenuHeading>
          <SideMenuItem
            caption="Settings"
            iconComponent={<FontAwesomeIcon icon={faBook} />}
            to={`/publisher/sites/${siteSlug}/booker`}
            disable={!siteSlug}
            nativeLink={true}
          />
          <SideMenuItem
            caption="Radar"
            iconComponent={<FontAwesomeIcon icon={faRadar} />}
            to={`/publisher/sites/${siteSlug}/radar`}
            disable={!siteSlug}
            nativeLink={true}
          />
        </List>
      </nav>
    </Box>
  );
}
