import React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";

export default function AddSiteBreadcrumbs({ lastItem }: { lastItem: string }) {
  return (
    <Breadcrumbs aria-label="breadcrumb">
      <Link to="/publisher/sites">Publisher</Link>
      <Link to="/publisher/sites">Profiles</Link>
      <Typography color="text.primary">{lastItem}</Typography>
    </Breadcrumbs>
  );
}
