import React from "react";
import { Box, Grid, Link, ListItemText } from "@mui/material";
import { ICreativeRequirement } from "../../../../types";

function getDescription(creativeItem: ICreativeRequirement) {
  const { creative_type: creativeType } = creativeItem;

  switch (creativeType) {
    case "image":
      return `Accepted filetypes: PNG, JPG, GIF. Image should be ${creativeItem.width}x${creativeItem.height}px`;
    case "text":
      return `${creativeItem.max_length} character max`;
    case "link":
      return <div>&mdash;</div>;
    default:
      return "";
  }
}

export function CreativeRequirementItemView({
  name,
  value,
  isLink,
}: {
  name: string;
  value: string | JSX.Element;
  isLink?: boolean;
}) {
  return (
    <ListItemText sx={{ p: "0.5rem 0" }}>
      <Grid display="flex" container>
        <Grid item={true} xs={12} md={4}>
          <Box
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              maxWidth: "60vw",
            }}
          >
            <b>{name}:</b>
          </Box>
        </Grid>
        <Grid item={true} xs={12} md={8}>
          {isLink ? (
            <Link
              href={value as string}
              sx={{ textDecoration: "none" }}
              target="_blank"
            >
              View
            </Link>
          ) : (
            value
          )}
        </Grid>
      </Grid>
    </ListItemText>
  );
}

export default function CreativeRequirementItem({
  creativeItem,
}: {
  creativeItem: ICreativeRequirement;
}) {
  return (
    <CreativeRequirementItemView
      name={creativeItem.name}
      value={getDescription(creativeItem)}
    />
  );
}
