import React from "react";
import { MenuItem, Typography } from "@mui/material";
import { Link } from "react-router-dom";

export default function PositionedMenuItem({
  to,
  text,
  onClick,
  nativeLink = false,
}: {
  to: string;
  text: string;
  onClick: () => void;
  nativeLink?: boolean;
}) {
  // TODO: danger 4 confirm details
  const childrenItem = (
    <MenuItem onClick={onClick}>
      <Typography color="text.primary">{text}</Typography>
    </MenuItem>
  );

  return (
    <Link to={to} reloadDocument={nativeLink}>
      {childrenItem}
    </Link>
  );
}
