import React from "react";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { DAYS_OF_WEEK } from "./index";

export default function WeekdaySelector({
  byweekday,
  setByweekday,
}: {
  byweekday: number[];
  setByweekday: (byweekday: number[]) => void;
}) {
  const handleDayChange = (
    event: React.MouseEvent<HTMLElement>,
    newSelectedDays: number[]
  ) => {
    setByweekday(newSelectedDays);
  };

  return (
    <ToggleButtonGroup
      value={byweekday}
      onChange={handleDayChange}
      aria-label="Days of the Week"
    >
      {DAYS_OF_WEEK.map((day) => (
        <ToggleButton
          key={day.toString()}
          value={day.weekday}
          aria-label={day.toString()}
          sx={{
            width: "48px",
          }}
        >
          {day.toString()}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
}
