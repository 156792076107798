import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Box,
  Button,
  Grid,
  IconButton,
  List,
  ListItemText,
  Slide,
  Typography,
  useTheme,
} from "@mui/material";
import { FC } from "react";
import { Asset } from "../CreativeItemImageField";

interface IImagePreviewPanelProps {
  asset: Asset;
  file: File | null;
  onClose: () => void;
  closePreview: () => void;
  handleImageSelect: (image: File | Asset) => void;
  setPreviewOpen: (open: boolean) => void;
  open: boolean;
  requiredWidth: number;
  requiredHeight: number;
}

const IMAGE_TOO_SMALL =
  "The image is too small. Please select another or upload a new one.";
const IMAGE_MAY_NOT_FIT =
  "The image may not fit well. Please ensure the size is correct.";
const IMAGE_SOFT_WARNING =
  "The image is slightly smaller than recommended, but it might still work.";

export const ImagePreviewPanel: FC<IImagePreviewPanelProps> = ({
  open,
  asset,
  file,
  requiredHeight,
  requiredWidth,
  handleImageSelect,
  onClose,
  closePreview,
}) => {
  const theme = useTheme();
  let errorMessage = null;
  const isImageTooSmall =
    asset.width < requiredWidth * 0.6 || asset.height < requiredHeight * 0.6;
  const isImageSlightlySmall =
    !isImageTooSmall &&
    (asset.width < requiredWidth || asset.height < requiredHeight) &&
    asset.width >= requiredWidth * 0.6 &&
    asset.height >= requiredHeight * 0.6;
  const aspectRatioRequired = requiredWidth / requiredHeight;
  const aspectRatioImage = asset.width / asset.height;
  const isImageMayNotFit =
    Math.abs(aspectRatioRequired - aspectRatioImage) > 0.1;

  if (isImageTooSmall) {
    errorMessage = IMAGE_TOO_SMALL;
  } else if (isImageSlightlySmall) {
    errorMessage = IMAGE_SOFT_WARNING;
  } else if (isImageMayNotFit) {
    errorMessage = IMAGE_MAY_NOT_FIT;
  }

  const handleSelectImage = () => {
    handleImageSelect(file && asset.id === file.name ? file : asset);
    closePreview();
    onClose();
  };

  return (
    <Slide direction="left" in={open} mountOnEnter unmountOnExit>
      <Box
        position="absolute"
        top={0}
        left={0}
        width="100%"
        height="100%"
        display="flex"
        justifyContent="flex-end"
        onClick={closePreview}
      >
        <Box
          width="65%"
          height="100%"
          bgcolor={theme.palette.common.white}
          display="flex"
          flexDirection="column"
          borderLeft={`1px solid ${theme.palette.divider}`}
          p={2}
          onClick={(e) => e.stopPropagation()}
        >
          <Box display="flex" justifyContent="space-between" mb={1}>
            <Typography variant="h6">Preview Image</Typography>
            <IconButton onClick={closePreview} edge="end">
              <CloseIcon />
            </IconButton>
          </Box>

          <Box flex={1}>
            <Alert severity="info" sx={{ mb: 1 }}>
              <Typography variant="caption">
                Image requirements: {requiredWidth} x {requiredHeight} px
              </Typography>
            </Alert>

            {errorMessage && (
              <Alert severity="error" sx={{ mb: 1 }}>
                <Typography variant="caption">{errorMessage}</Typography>
              </Alert>
            )}

            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              bgcolor={theme.palette.common.white}
              width="30vw"
              maxHeight="40vh"
              m="auto"
            >
              <img
                src={asset.image}
                alt="Preview"
                style={{
                  width: "100%",
                  height: "auto",
                  maxHeight: "40vh",
                  objectFit: "contain",
                }}
              />
            </Box>

            <Box width={"80%"} m="auto">
              <List>
                <Grid container spacing={1}>
                  <Grid item xs={3}>
                    <ListItemText
                      primary="File name:"
                      primaryTypographyProps={{ variant: "caption" }}
                    />
                  </Grid>
                  <Grid item xs={9}>
                    <ListItemText
                      secondary={asset.file_name}
                      secondaryTypographyProps={{ variant: "caption" }}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={3}>
                    <ListItemText
                      primary="Dimensions:"
                      primaryTypographyProps={{ variant: "caption" }}
                    />
                  </Grid>
                  <Grid item xs={9}>
                    <ListItemText
                      secondary={`${asset.width} x ${asset.height} px`}
                      secondaryTypographyProps={{ variant: "caption" }}
                    />
                  </Grid>
                </Grid>
              </List>
            </Box>
          </Box>

          <Box display="flex" justifyContent="flex-end">
            <Button
              onClick={handleSelectImage}
              color="primary"
              variant="contained"
              sx={{ marginRight: 1 }}
              disabled={isImageTooSmall}
            >
              Select Image
            </Button>
            <Button onClick={closePreview} variant="outlined">
              Back
            </Button>
          </Box>
        </Box>
      </Box>
    </Slide>
  );
};
