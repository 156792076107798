import axios from "axios";
import Cookies from "js-cookie";

export function setAxiosAuth(): void {
  const bearer = Cookies.get("bearer");

  if (bearer) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${bearer}`;
  }
}
