import { useContext } from "react";
import axios from "axios";
import Cookies from "js-cookie";

import { SignUpContext } from "../context";

export const useAxios = () => {
  const { setErrors } = useContext(SignUpContext);
  const setAuthToken = (bearer: string | null) => {
    if (bearer) {
      Cookies.set("bearer", bearer);
      axios.defaults.headers.common["Authorization"] = `Bearer ${bearer}`;
    } else {
      Cookies.remove("bearer");
      delete axios.defaults.headers.common["Authorization"];
    }
  };

  const requestApi = (
    method: string,
    uri: string,
    body?: any,
    responseHeadersList?: string[]
  ) => {
    const promise = new Promise<any>((resolve, reject) => {
      const config = {
        method: method.toLowerCase(),
        url: uri,
        data: body,
        headers: axios.defaults.headers.common,
      };
      axios
        .request(config)
        .then((response) => {
          if (!!responseHeadersList?.length) {
            const result: {
              [key: string]: string;
            } = responseHeadersList.reduce(
              (acc: { [key: string]: string }, item: string) => {
                if (response.headers[item]) {
                  acc[item] = response.headers[item];
                }
                return acc;
              },
              {}
            );

            return resolve(result);
          } else {
            return resolve(response.data);
          }
        })
        .catch((error) => {
          const errorData = error.response?.data?.errors;
          setErrors(errorData);
          reject(error);
        });
    });
    return promise;
  };
  return {
    requestApi,
    setAuthToken,
  };
};
