import React, { FC, useEffect, useState, useRef, useCallback } from "react";
import { ReactComponent as AddFilesImage } from "./Add Files.svg";
import { useParams } from "react-router-dom";
import axios from "axios";
import { noop } from "lodash";

import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import { Asset } from "../CreativeItemImageField";
import { ImagePreviewPanel } from "./ImagePreviewPanel";

interface IAssetsLibraryProps {
  open: boolean;
  setAssetsLibraryOpen: (open: boolean) => void;
  handleImageSelect: (file: File | Asset) => void;
  requiredWidth: number;
  requiredHeight: number;
  uuid: string;
}

const ERROR_MESSAGES = {
  UNSUPPORTED_IMAGE_FORMAT: "Only PNG, JPG, JPEG, and GIF formats are allowed.",
  DUPLICATE_FILE: "This file already exists in the asset library.",
  SMALL_IMAGE:
    "This image is too small. Please select another or upload a new one.",
};

export const AssetsLibrary: FC<IAssetsLibraryProps> = ({
  open,
  setAssetsLibraryOpen,
  handleImageSelect,
  requiredWidth,
  requiredHeight,
  uuid,
}) => {
  const { campaignId } = useParams();
  const [selectedAsset, setSelectedAsset] = useState<Asset | null>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [existingAssets, setExistingAssets] = useState<Asset[]>([]);
  const [metaData, setMetaData] = useState({ current_page: 1, total_pages: 1 });
  const [currentPage, setCurrentPage] = useState(1);
  const [isFetching, setIsFetching] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [uploadedAsset, setUploadedAsset] = useState<Asset | null>(null);
  const theme = useTheme();
  const observerRef = useRef<HTMLDivElement>(null);

  const fetchAssets = useCallback(
    async (page = 1) => {
      if (isFetching || page > metaData.total_pages) return;

      setIsFetching(true);

      try {
        const { data } = await axios.get(
          `/api/v1/advertiser/campaigns/${campaignId}/image_library`,
          {
            params: {
              creative_requirement_id: uuid,
              page_size: 24,
              page,
            },
          }
        );

        const { stored_assets, meta } = data;
        setMetaData(meta);

        setExistingAssets((prevAssets) => [
          ...prevAssets,
          ...(uploadedAsset
            ? [uploadedAsset, ...stored_assets]
            : stored_assets),
        ]);

        setCurrentPage(page);
      } catch (err) {
        noop();
      } finally {
        setIsFetching(false);
      }
    },
    [campaignId, uuid, isFetching, metaData.total_pages, uploadedAsset]
  );

  useEffect(() => {
    if (!open) return;
    setExistingAssets([]);
    setCurrentPage(1);
    fetchAssets(1);
    //only run once the modal is opened fetch initial assets
    // eslint-disable-next-line
  }, [open]);

  useEffect(() => {
    if (!open) return;

    const observer = new IntersectionObserver(
      (entries) => {
        const firstEntry = entries[0];
        if (
          firstEntry.isIntersecting &&
          !isFetching &&
          currentPage < metaData.total_pages
        ) {
          fetchAssets(currentPage + 1);
        }
      },
      { threshold: 1 }
    );

    const currentObserverRef = observerRef.current;
    if (currentObserverRef) {
      observer.observe(currentObserverRef);
    }

    return () => {
      observer.disconnect();
    };
  }, [isFetching, currentPage, metaData.total_pages, open, fetchAssets]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;

    const fileExtension = file.name.split(".").pop()?.toLowerCase();
    const allowedExtensions = ["png", "jpg", "jpeg", "gif"];

    if (!allowedExtensions.includes(fileExtension || "")) {
      setError(ERROR_MESSAGES.UNSUPPORTED_IMAGE_FORMAT);
      return;
    }

    const isDuplicate = existingAssets.some(
      (asset) => asset.file_size === file.size && asset.id === file.name
    );
    if (isDuplicate) {
      setError(ERROR_MESSAGES.DUPLICATE_FILE);
      return;
    }

    const img = new Image();
    const objectUrl = URL.createObjectURL(file);
    img.src = objectUrl;

    img.onload = () => {
      if (
        img.naturalWidth < requiredWidth * 0.6 ||
        img.naturalHeight < requiredHeight * 0.6
      ) {
        setError(ERROR_MESSAGES.SMALL_IMAGE);
        return;
      }

      const newAsset: Asset = {
        id: file.name,
        image: objectUrl,
        thumb: objectUrl,
        width: img.naturalWidth,
        height: img.naturalHeight,
        file_size: file.size,
        file_name: file.name,
      };

      setUploadedAsset(newAsset);
      setSelectedAsset(newAsset);
      setSelectedFile(file);
      setPreviewOpen(true);
      setError(null);
    };
  };

  const handleImageClick = (asset: Asset) => {
    setSelectedAsset(asset);
    setPreviewOpen(true);
  };

  const handleCloseAssetsLibrary = () => {
    setAssetsLibraryOpen(false);
    setPreviewOpen(false);
    setSelectedAsset(null);
    setSelectedFile(null);
    setError(null);
  };

  return (
    <Dialog
      open={open}
      onClose={handleCloseAssetsLibrary}
      fullWidth
      PaperProps={{
        style: { minWidth: "60vw", minHeight: "80vh", overflowX: "hidden" },
      }}
    >
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">Asset Library</Typography>
          <IconButton onClick={handleCloseAssetsLibrary}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>

      <Divider />

      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          padding: 0,
        }}
      >
        {error && (
          <Alert severity="error" onClose={() => setError(null)}>
            {error}
          </Alert>
        )}
        {!!existingAssets.length && (
          <Grid
            container
            spacing={2}
            sx={{
              padding: 4,
              flexGrow: 1,
              textAlign: "center",
            }}
          >
            {existingAssets.map((asset) => (
              <Grid key={asset.id} item xs={6} sm={4} md={3} lg={12 / 5} xl={2}>
                <Box
                  sx={{
                    backgroundImage: `url(${asset.thumb})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    border:
                      selectedAsset?.id === asset.id
                        ? `3px solid ${theme.palette.primary.main}`
                        : `1px solid ${theme.palette.grey[300]}`,
                    borderRadius: "10px",
                    cursor: "pointer",
                    aspectRatio: "1/1",
                  }}
                  onClick={() => handleImageClick(asset)}
                />
                <Typography variant="subtitle2" mt={1} noWrap align="center">
                  {asset.file_name}
                </Typography>
                <Typography variant="caption" align="center">
                  {asset.width} x {asset.height}
                </Typography>
              </Grid>
            ))}
            <div ref={observerRef} style={{ height: 1 }} />
          </Grid>
        )}
        {!existingAssets.length && !isFetching && (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            flexGrow={1}
          >
            <AddFilesImage />
            <Typography variant="h6" component="p" mt={2}>
              Your Asset Library is empty.
            </Typography>
            <Typography component="p">
              Click below to upload any images and logos for your sponsorships.
            </Typography>
            <Button
              sx={{ mt: 3 }}
              variant="contained"
              onClick={() => document.getElementById("file-input")?.click()}
              size="large"
            >
              Upload Assets
            </Button>
          </Box>
        )}
        {isFetching && (
          <Box display="flex" justifyContent="center" mt={2}>
            <CircularProgress />
          </Box>
        )}
      </DialogContent>

      <Divider />

      {!!existingAssets.length && (
        <DialogActions sx={{ justifyContent: "flex-end" }}>
          <Box px={2} py={1}>
            <Button
              variant="contained"
              onClick={() => document.getElementById("file-input")?.click()}
            >
              Upload New
            </Button>
          </Box>
        </DialogActions>
      )}

      <input
        id="file-input"
        type="file"
        accept=".png,.jpg,.jpeg,.gif"
        style={{ display: "none" }}
        onChange={handleFileChange}
      />

      {selectedAsset && (
        <ImagePreviewPanel
          handleImageSelect={handleImageSelect}
          open={previewOpen}
          file={selectedFile}
          asset={selectedAsset}
          onClose={handleCloseAssetsLibrary}
          closePreview={() => setPreviewOpen(false)}
          setPreviewOpen={setPreviewOpen}
          requiredWidth={requiredWidth}
          requiredHeight={requiredHeight}
        />
      )}
    </Dialog>
  );
};
