import { useRef, useMemo } from "react";
import { IPlacement } from "types";

export const SMART_PRICE_SEED_PC = 0.6;
export const PUBLISHED_PRICE_MIN = 75;
export const SMART_PUBLISHED_PRICE_MIN = 150;

interface UseSmartPricingReturn {
  preFillSmartPrice: (publishedPrice: number, smartPrice?: number) => number;
  smartPricingToggle: (checked?: boolean) => void;
  buttonRef: React.MutableRefObject<HTMLInputElement | null>;
  smartPricingDisabled: boolean;
}

export function useSmartPricing(
  values: IPlacement,
  setFieldValue: (field: string, value: any) => void,
  setModalOpen: (value: boolean) => void,
  prefix: string = ""
): UseSmartPricingReturn {
  const buttonRef = useRef<HTMLInputElement>(null);

  function preFillSmartPrice(
    publishedPrice: number,
    smartPrice: number = 0
  ): number {
    if (smartPrice > 0) {
      return smartPrice;
    }

    return Math.max(
      Math.ceil(publishedPrice * SMART_PRICE_SEED_PC),
      PUBLISHED_PRICE_MIN
    );
  }

  function smartPricingToggle(checked = false): void {
    const newValue = buttonRef.current?.value;

    const lowestPriceFieldName = prefix
      ? `${prefix}.smart_pricing_lowest_price`
      : "smart_pricing_lowest_price";

    const smartPricingEnabledFieldName = prefix
      ? `${prefix}.smart_pricing_enabled`
      : "smart_pricing_enabled";

    if (newValue && values["smart_pricing_lowest_price"] === 0) {
      const initialSmartPriceValue = preFillSmartPrice(
        values["published_price"],
        values["smart_pricing_lowest_price"]
      );

      setFieldValue(lowestPriceFieldName, initialSmartPriceValue);
    }

    if (newValue === "true") {
      setFieldValue(smartPricingEnabledFieldName, false);
    } else if (newValue === "false") {
      if (checked) {
        setFieldValue(smartPricingEnabledFieldName, true);
      } else {
        setModalOpen(true);
      }
    }
  }

  const smartPricingDisabled = useMemo(
    () => values["published_price"] < SMART_PUBLISHED_PRICE_MIN,
    [values]
  );

  return {
    preFillSmartPrice,
    smartPricingToggle,
    buttonRef,
    smartPricingDisabled,
  };
}
