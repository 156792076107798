export interface IUser {
  userType: UserType | undefined;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  companyName?: string;
  url?: string;
  targeting?: string[];
  phoneNumber?: string;
  goals?: string[];
  budget?: string;
}

export interface IOnboardingValues {
  budget: string[];
  goals: string[];
  targeting: string[];
  categories: string[];
  frequency: string[];
  subscribers: string[];
}

export enum UserType {
  ADVERTISER = "advertiser",
  PUBLISHER = "publisher",
}

export interface IError {
  [key: string]: string[];
}

export interface SegmentDataLayer {
  state: string;
  email: string;
  first_name: string;
  last_name: string;
  firstName: string;
  lastName: string;
  name: string;
  id: number;
  type: UserType;
  company_name: string;
  company: string;
  created: string;
  created_ts: number;
}

export type SpecWindow = typeof window & {
  analytics: {
    track: (value: string) => null;
    identify: (userId: number, segmentDataLayer: SegmentDataLayer) => null;
  };
};

export type SelectOption = {
  value: string;
  label: string;
};
