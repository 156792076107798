import axios from "axios";
import { useState } from "react";
import { useParams } from "react-router";

type SponsorshipType = "sponsored_email" | "dedicated_email";

export const SUBMIT_BUTTON_ID = "next-button";

export function usePricingValidationModal(sponsorshipType: SponsorshipType) {
  const [priceModalOpen, setPriceModalOpen] = useState(false);
  const [priceModalWasShown, setPriceModalWasShown] = useState(false);
  const [shouldCheckPriceOnSubmit, setShouldCheckPriceOnSubmit] =
    useState(false);
  const [blurredFieldName, setBlurredFieldName] = useState<string | null>(null);

  const { siteSlug } = useParams();

  const checkPrice = (publishedPrice: number) => {
    return axios({
      method: "post",
      processErrorInComponentStatuses: [422],
      url: `/api/v1/publisher/sites/${siteSlug}/sponsorship_options/check_price`,
      params: {
        published_price: publishedPrice,
        sponsorship_type: sponsorshipType,
      },
      responseType: "text",
    })
      .then(() => {
        return true;
      })
      .catch((err) => {
        if (err.response.status === 422) {
          setPriceModalOpen(true);
          setPriceModalWasShown(true);
          return false;
        }
      })
      .finally(() => {
        setShouldCheckPriceOnSubmit(false);
        setBlurredFieldName(null);
      });
  };

  const handlePriceBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (priceModalWasShown) return;

    if (e.relatedTarget?.id === SUBMIT_BUTTON_ID) {
      setShouldCheckPriceOnSubmit(true);
      setBlurredFieldName(e.target.name);
      return;
    }

    checkPrice(Number(e.target.value));
  };

  const modalProps = {
    priceModalOpen,
    handlePriceBlur,
    setPriceModalOpen,
    shouldCheckPriceOnSubmit,
    blurredFieldName,
    checkPrice,
  };

  return modalProps;
}
