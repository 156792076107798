import { Box, InputLabel, Typography } from "@mui/material";

type CardTopProps = {
  uuid: string;
  name: string;
  width?: number;
  height?: number;
};

export default function CardTop({ uuid, name, width, height }: CardTopProps) {
  return (
    <Box sx={{ mt: "12px" }} display="flex" justifyContent="space-between">
      <InputLabel htmlFor={uuid} sx={{ maxWidth: "60vw" }}>
        {name}
      </InputLabel>
      {!!width && !!height && (
        <Typography sx={{ fontWeight: 600, fontSize: "15px" }}>
          {width}x{height}px
        </Typography>
      )}
    </Box>
  );
}
