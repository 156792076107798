import React, { useState, useContext } from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { IPayment } from "./types";
import axios from "axios";
import { Link } from "react-router-dom";
import { LayoutContext, UserContext } from "context";
import useComponentDidMount from "hooks/useComponentDidMount";
import PaymentsList from "./PaymentsList";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { Box } from "@mui/material";
import PaymentsInfoCards from "./PaymentsInfoCards";
import NoPayoutsInfo from "./NoPayoutsInfo";
import noop from "lodash/noop";

export default function Payments() {
  const [payments, setPayments] = useState<IPayment[]>([]);
  const [futurePayouts, setFuturePayouts] = useState<number>(0);
  const [pendingPayouts, setPendingPayouts] = useState<number>(0);
  const [totalPaid, setTotalPaid] = useState<number>(0);
  const [isPageReady, setIsPageReady] = useState<boolean>(false);
  const [hasPayoutDetails, setHasPayoutDetails] = useState<boolean>(true);
  const { setIsDataLoading } = useContext(LayoutContext);
  const { userData } = useContext(UserContext);

  useComponentDidMount(() => {
    setIsDataLoading(true);

    axios({
      method: "get",
      url: "/api/v1/publisher/payouts",
    })
      .then(function (res) {
        const {
          payouts,
          future_payouts,
          pending_payouts,
          total_paid,
          has_payout_details,
        } = res.data;
        setPayments(payouts);
        setFuturePayouts(future_payouts);
        setPendingPayouts(pending_payouts);
        setTotalPaid(total_paid);
        setHasPayoutDetails(has_payout_details);
        setIsPageReady(true);
      })
      .catch(noop)
      .finally(() => setIsDataLoading(false));
  });

  const renderContent = () => {
    if (!userData.firstName || payments === undefined) {
      return null;
    }

    if (!payments.length) {
      return <NoPayoutsInfo hasPayoutDetails={hasPayoutDetails} />;
    }

    return <PaymentsList payments={payments} />;
  };

  return isPageReady ? (
    <>
      <Grid
        item
        xs={12}
        container
        justifyContent="space-between"
        alignItems="center"
        paddingY={3}
      >
        <Typography variant="h5" component="h1" color="text.primary">
          Your Payouts
        </Typography>
        <Link to="/publisher/payout_details" reloadDocument={true}>
          <Button
            variant="outlined"
            type="button"
            sx={{
              color: "text.primary",
              ml: 2,
            }}
            startIcon={<SettingsOutlinedIcon />}
          >
            Payout Settings
          </Button>
        </Link>
      </Grid>
      {!!payments.length && (
        <Box sx={{ mb: 2 }}>
          <PaymentsInfoCards
            futurePayouts={futurePayouts}
            pendingPayouts={pendingPayouts}
            totalPaid={totalPaid}
          />
        </Box>
      )}
      {renderContent()}
    </>
  ) : null;
}
