import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete, {
  AutocompleteRenderGroupParams,
  createFilterOptions,
} from "@mui/material/Autocomplete";
import { faStar } from "@fortawesome/pro-solid-svg-icons";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { Chip, InputLabel, Paper, CircularProgress, Box } from "@mui/material";
import { ICreativeRequirement } from "types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ListSubheader from "@mui/material/ListSubheader";
import { styled } from "@mui/material/styles";

export type Suggestion = { options: Option[]; uuid: string };
export type Option = {
  inputValue?: string;
  title: string;
  type: string;
  top_rated?: boolean;
  uuid?: string;
};

const filter = createFilterOptions<Option>();

const renderOption = (
  props: React.HTMLAttributes<HTMLLIElement>,
  option: Option
) => (
  <ListItem {...props}>
    <ListItemText primary={option.title} />
    {option.top_rated && (
      <Chip
        icon={<FontAwesomeIcon icon={faStar} color={"#fee15f"} size="lg" />}
        label="Top Performer"
        size="small"
        sx={{
          borderRadius: "5px",
          background: "none",
          border: "2px solid #fee15f",
          fontWeight: "500",
          height: "30px",
        }}
      />
    )}
  </ListItem>
);

const renderGroup = (
  params: AutocompleteRenderGroupParams,
  isLoading?: boolean
) => {
  return (
    <li className="MuiAutocomplete-group" key={params.key}>
      <ListSubheader
        component="div"
        sx={{ color: "#495057", fontWeight: "bold" }}
      >
        {params.group === "saved" ? "Previously Used" : "AI Suggestions"}
      </ListSubheader>
      {isLoading && params.group === "ai" ? (
        <Box display="flex" justifyContent="center" gap={2} sx={{ pb: 2 }}>
          <CircularProgress size={20} />
          Loading suggestions
        </Box>
      ) : (
        <ul className="MuiAutocomplete-groupUl">{params.children}</ul>
      )}
    </li>
  );
};

const StyledPaper = styled(Paper)(({ theme }) => ({
  "& .MuiAutocomplete-listbox, & .MuiAutocomplete-groupUl": {
    padding: 0,
    margin: 0,
  },
  "& .MuiListItem-root.MuiAutocomplete-option": {
    paddingLeft: "30px",
  },
  "& .MuiListItem-root.MuiAutocomplete-option:nth-of-type(even)": {
    backgroundColor: "#0000000a",
  },
  "& .MuiAutocomplete-listbox .MuiAutocomplete-option.Mui-focused": {
    backgroundColor: "rgba(180, 206, 251, 0.3)",
  },
  "& .MuiListItemText-root>.MuiTypography-root ": {
    overflow: "hidden",
    textOverflow: "ellipsis",
    paddingRight: "10px",
    display: "-webkit-box",
    WebkitLineClamp: "3",
    WebkitBoxOrient: "vertical",
  },
  "& .MuiAutocomplete-group": {
    borderBottom: "2px solid lightgrey",
  },
}));

export default function AICreativeItemTextField({
  creativeItem,
  value,
  onChange,
  onBlur,
  error,
  options: initialOptions,
  isLoading,
}: {
  creativeItem: ICreativeRequirement;
  value: string;
  onChange: any;
  onBlur: (e: React.FocusEvent) => void;
  error?: string;
  options: Option[];
  isLoading?: boolean;
}) {
  const { name, uuid, max_length } = creativeItem;

  const helperText = !!max_length
    ? `${value.length} / ${max_length}`
    : undefined;

  const options =
    isLoading && !initialOptions.some((option) => option.type === "ai")
      ? [...initialOptions, { title: "", type: "ai" }] // add dummy option to render 'ai' group with loading spinner
      : initialOptions;

  return (
    <>
      <InputLabel htmlFor={uuid} sx={{ maxWidth: "60vw" }}>
        {name}
      </InputLabel>
      <Autocomplete
        id={uuid}
        fullWidth
        value={value}
        onBlur={onBlur}
        onInputChange={(e, newValue) => onChange(newValue)}
        onChange={(event, newValue) => {
          if (typeof newValue === "string") {
            onChange(newValue);
          } else if (newValue && newValue.title) {
            onChange(newValue.title);
          } else if (newValue === null) onChange("");
        }}
        filterOptions={(options, params) => {
          return filter(options, params);
        }}
        selectOnFocus
        handleHomeEndKeys
        options={options}
        getOptionLabel={(option) => {
          if (typeof option === "string") {
            return option;
          }
          return option.title;
        }}
        renderOption={renderOption}
        freeSolo
        groupBy={(option) => option.type}
        disableClearable
        renderGroup={(params) => renderGroup(params, isLoading)}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              placeholder={!!options.length ? "Click to see suggestions…" : ""}
              name={uuid}
              multiline
              error={!!error}
              helperText={error ? error : helperText}
              type="text"
              maxRows={10}
              sx={{
                "& .MuiOutlinedInput-root .MuiAutocomplete-input": {
                  padding: "0.375rem 0.75rem 0.375rem 0.75rem",
                },
              }}
              onKeyDown={(event) => {
                if (event.key === "ArrowUp" || event.key === "ArrowDown") {
                  event.stopPropagation();
                }
              }}
            />
          );
        }}
        PaperComponent={StyledPaper}
      />
    </>
  );
}
