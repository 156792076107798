import React, { useContext } from "react";
import { LayoutContext } from "context";

export default function FormWrapper({
  children,
  onSubmit,
  className,
}: {
  children: React.ReactNode;
  onSubmit: (e?: React.FormEvent<HTMLFormElement>) => void;
  className?: string;
}) {
  const { isDataLoading } = useContext(LayoutContext);

  return (
    <form onSubmit={onSubmit} className={className}>
      <fieldset disabled={isDataLoading}>{children}</fieldset>
    </form>
  );
}
