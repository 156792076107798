import React from "react";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText/ListItemText";

type MenuHeadingProps = {
  children: React.ReactNode;
};

const SideMenuHeading: React.FC<MenuHeadingProps> = ({ children }) => {
  return (
    <ListItem component={"div"}>
      <ListItemText
        primary={children}
        primaryTypographyProps={{
          sx: { color: "#97A8BA", fontWeight: 700 },
        }}
      />
    </ListItem>
  );
};

export default SideMenuHeading;
