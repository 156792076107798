import { useInstantSearch, useStats } from "react-instantsearch";

export default function CustomStats() {
  const { results } = useInstantSearch();
  const noResults = !results.__isArtificial && results.nbHits === 0;

  const { nbHits } = useStats();

  if (results.query && noResults) return <div>No results</div>;

  return (
    <span>
      {`${nbHits.toLocaleString()} ${
        nbHits === 1 ? "result" : "results"
      } found`}
    </span>
  );
}
