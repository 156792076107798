import { Weekday, WeekdayStr, RRule } from "rrule";

const DAY_NAMES: Record<string, string> = {
  MO: "Monday",
  TU: "Tuesday",
  WE: "Wednesday",
  TH: "Thursday",
  FR: "Friday",
  SA: "Saturday",
  SU: "Sunday",
};

export function convertBymonthweekdayToBynweekday(bymonthweekday: string[]) {
  return bymonthweekday.map((item) => {
    const [week, day] = item.split(",");

    return [Weekday.fromStr(day as WeekdayStr).weekday, parseInt(week)];
  });
}

export function getBynweekdayStr(bymonthweekday: string[]) {
  return bymonthweekday
    .sort((a, b) => {
      const [weekA, dayA] = a.split(",");
      const [weekB, dayB] = b.split(",");

      const numComparison = parseInt(weekA) - parseInt(weekB);
      if (numComparison !== 0) {
        return numComparison;
      }

      return (
        Weekday.fromStr(dayA as WeekdayStr).weekday -
        Weekday.fromStr(dayB as WeekdayStr).weekday
      );
    })
    .map((item) => {
      const [week, day] = item.split(",");

      return String(parseInt(week)) + day;
    })
    .join(",");
}

export function ordinalSuffix(num: number): string {
  switch (num) {
    case 1:
      return "1st";
    case 2:
      return "2nd";
    case 3:
      return "3rd";
    default:
      return `${num}th`;
  }
}

export function getBynweekdayTextArr(arr: string[]) {
  const grouped = arr.reduce((acc, item) => {
    const [num, dayCode] = item.split(",");
    const numKey = parseInt(num);
    if (!acc[numKey]) {
      acc[numKey] = [];
    }
    acc[numKey].push(DAY_NAMES[dayCode]);
    return acc;
  }, {} as Record<number, string[]>);

  return Object.keys(grouped)
    .sort((a, b) => parseInt(a) - parseInt(b))
    .map(
      (num) =>
        `on the ${ordinalSuffix(parseInt(num))} ${grouped[parseInt(num)].join(
          ", "
        )}`
    );
}

export function getMontByWeekRRuleStr(
  rule: RRule,
  bymonthweekday: string[]
): string {
  const bynweekdayStr = getBynweekdayStr(bymonthweekday);

  const parts = rule
    .toString()
    .split(";")
    .map((part) => part.split("="));
  const bynweekPart = parts.find((part) => part[0] === "BYNWEEKDAY") ?? [
    "",
    "",
  ];
  bynweekPart[0] = "BYDAY";
  bynweekPart[1] = bynweekdayStr;

  return parts.map((part) => part.join("=")).join(";");
}
