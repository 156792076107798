import React from "react";
import { Box, Typography } from "@mui/material";
import { toTitleCase } from "utils";

type Props = {
  status: string;
};

const mapToColor: { [key: string]: string } = {
  //payment statuses
  sent: "success.main",
  paid: "success.main",
  pending: "warning.main",
  captured: "warning.main",
  requested: "error.main",

  // site statuses
  active: "success.main",
  draft: "warning.main",
  in_review: "warning.main",
  hidden: "success.main",
  live: "success.main",
  deleted: "error.main",
  archived: "error.main",
  rejected: "error.main",
  banned: "error.main",
};

export default function StatusItem({ status }: Props) {
  return (
    <Box display="flex" alignItems="center" gap="6px">
      <Box
        sx={{
          width: "0.5rem",
          height: "0.5rem",
          borderRadius: "100%",
          backgroundColor: mapToColor[status.toLowerCase()] ?? "text.primary",
        }}
      />
      <Typography>{toTitleCase(status)}</Typography>
    </Box>
  );
}
