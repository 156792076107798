import React from "react";
import WeekdaySelector from "./WeekdaySelector";
import Interval from "./Interval";

export default function Weekly({
  interval,
  setInterval,
  byweekday,
  setByweekday,
}: {
  interval: number;
  setInterval: (interval: number) => void;
  byweekday: number[];
  setByweekday: (byweekday: number[]) => void;
}) {
  return (
    <>
      <Interval interval={interval} setInterval={setInterval} caption="week" />
      <WeekdaySelector byweekday={byweekday} setByweekday={setByweekday} />
    </>
  );
}
