import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import { UserContext } from "../../../../../context";
import { noop } from "lodash";
import React, { useContext, useMemo, useState } from "react";
import MarkAsReservedSwitch from "./MarkAsReservedSwitch";
import SuccessMessage from "./SuccessMessage";
import { SelectedEventDetails } from "./types";
import {
  calculatePayoutAndFee,
  formatCurrencyNoDecimals,
  formatDate,
} from "./utils";

export interface SetCustomPriceModalProps {
  open: boolean;
  handleClose: () => void;
  siteSlug: string;
  reloadEvents: () => void;
  selectedEvent: SelectedEventDetails;
}

const getPayoutColor = (isUnavailable: boolean, hasError: boolean) => {
  if (isUnavailable) return "text.disabled";
  if (hasError) return "error.main";
  return "success.main";
};

const validatePrice = (value: number, regularPrice: number) => {
  if (value < 150) {
    return "Price must be at least $150";
  }
  if (value > regularPrice * 3) {
    return "Price must not exceed 3x regular price";
  }
  return null;
};

export const SetCustomPriceModal: React.FC<SetCustomPriceModalProps> = ({
  open,
  handleClose,
  siteSlug,
  selectedEvent: {
    extendedProps: {
      custom_price,
      sponsorship_option_uuid,
      price,
      sponsorship_type,
    },
    startDate,
    title,
  },
  reloadEvents,
}) => {
  const { pavedFee } = useContext(UserContext);
  const [isProcessing, setIsProcessing] = useState(false);
  const [markAsReserved, setMarkAsReserved] = useState(false);
  const [customPrice, setCustomPrice] = useState<number>(
    custom_price ?? price ?? 0
  );
  const [successType, setSuccessType] = useState<
    "blocked" | "customPrice" | null
  >(null);
  const [error, setError] = useState<string | null>(null);

  const formattedDate = formatDate(startDate);

  const newPayout = useMemo(
    () => calculatePayoutAndFee(customPrice, pavedFee[sponsorship_type]),
    [customPrice, pavedFee, sponsorship_type]
  );

  const handleConfirm = async () => {
    if (!sponsorship_option_uuid || !startDate) return;

    setIsProcessing(true);

    try {
      const baseUrl = `/api/v1/publisher/sites/${siteSlug}/sponsorship_options/${sponsorship_option_uuid}/sponsorship_option_reservations`;
      const formData = new FormData();
      formData.append("date", startDate);

      if (customPrice && !markAsReserved) {
        formData.append("price", customPrice.toString());
      }

      const isExistingCustomPrice = Boolean(custom_price);
      const requestUrl = isExistingCustomPrice
        ? `${baseUrl}/${startDate}`
        : baseUrl;
      const requestMethod = isExistingCustomPrice ? axios.patch : axios.post;

      await requestMethod(requestUrl, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      reloadEvents();
      setSuccessType(markAsReserved ? "blocked" : "customPrice");
    } catch (err) {
      noop();
    } finally {
      setIsProcessing(false);
    }
  };

  const handlePriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value.replace(/[^0-9]/g, ""); // Remove non-digit characters

    if (value.length > 6) {
      value = value.slice(0, 6); // Limit to 6 digits
    }

    const numValue = value === "" ? 0 : Number(value);
    setCustomPrice(numValue);
    setError(validatePrice(numValue, price));
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: "16px",
          maxWidth: "420px",
          height: successType ? "auto" : "624px",
        },
      }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          px: 4,
          fontSize: 24,
          minHeight: "64px",
        }}
      >
        {!successType && formattedDate}
        <IconButton
          size="small"
          onClick={handleClose}
          aria-label="close"
          sx={{ mr: -3, mt: -2, ml: "auto" }}
        >
          <Close fontSize="small" />
        </IconButton>
      </DialogTitle>

      {!successType && <Divider />}

      <DialogContent sx={{ display: "flex", flexDirection: "column", p: 4 }}>
        {successType ? (
          <SuccessMessage
            successType={successType}
            formattedDate={formattedDate}
          />
        ) : (
          <Box>
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <Typography
                  color="text.secondary"
                  variant="body1"
                  fontWeight="bold"
                >
                  Placement:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography color="text.secondary">{title}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography
                  color="text.secondary"
                  variant="body1"
                  fontWeight="bold"
                >
                  Regular Price:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography color="text.secondary">
                  {formatCurrencyNoDecimals(price)}
                </Typography>
              </Grid>
            </Grid>

            <Divider sx={{ my: 3 }} />

            <Typography variant="body2" color="text.secondary" mb={2}>
              You can set a unique custom price for this specific date. This
              will override your Smart Pricing settings.
            </Typography>

            <Box mb={3} display="flex" justifyContent="space-between">
              <TextField
                label="Custom Price"
                variant="outlined"
                type="text"
                disabled={markAsReserved}
                fullWidth
                value={customPrice === 0 ? "" : customPrice.toString()}
                onChange={handlePriceChange}
                onPaste={(e) => e.preventDefault()}
                error={Boolean(error)}
                helperText={error}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" sx={{ pl: 1, mr: -1 }}>
                      $
                    </InputAdornment>
                  ),
                  sx: { height: "48px" },
                  inputProps: {
                    maxLength: 6,
                  },
                }}
                sx={{ mt: 1, height: "48px", width: "70%" }}
                FormHelperTextProps={{
                  sx: {
                    position: "absolute",
                    top: "80%",
                    left: 0,
                  },
                }}
              />
              <Box ml={2} flexGrow={1}>
                <Typography variant="body2" color="text.secondary" mt={1}>
                  Your payout
                </Typography>
                <Typography
                  fontWeight="bold"
                  color={getPayoutColor(markAsReserved, Boolean(error))}
                >
                  {newPayout}
                </Typography>
              </Box>
            </Box>

            <Divider />
            <MarkAsReservedSwitch
              markAsReserved={markAsReserved}
              setMarkAsReserved={setMarkAsReserved}
            />
          </Box>
        )}
      </DialogContent>

      {!successType && (
        <DialogActions sx={{ p: 4, gap: 1.5 }}>
          <Button
            variant="outlined"
            fullWidth
            onClick={handleClose}
            disabled={isProcessing}
            size="large"
          >
            Cancel
          </Button>
          <Button
            fullWidth
            onClick={handleConfirm}
            variant="contained"
            color="primary"
            disabled={isProcessing || (markAsReserved ? false : Boolean(error))}
            startIcon={isProcessing ? <CircularProgress size={20} /> : null}
            size="large"
          >
            {isProcessing ? "Applying..." : "Apply"}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default SetCustomPriceModal;
