import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  Grid,
} from "@mui/material";
import axios from "axios";
import NewsletterScheduleDialog from "components/NewsletterScheduleDialog";
import { noop } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { RRule, rrulestr } from "rrule";
import ScheduleSection from "./ScheduleSection";
import { format } from "date-fns";

interface SponsorshipScheduleProps {
  sponsoredEmailEnabled?: boolean;
  dedicatedEmailEnabled?: boolean;
  initialSponsoredDate?: string;
  initialDedicatedDate?: string;
  sponsoredDateAnchor?: string;
  dedicatedDateAnchor?: string;
  initialSponsoredSchedule?: string;
  initialDedicatedSchedule?: string;
  onScheduleUpdated?: () => void;
}

const SponsorshipSchedule: React.FC<SponsorshipScheduleProps> = ({
  sponsoredEmailEnabled = false,
  dedicatedEmailEnabled = false,
  initialSponsoredDate = "",
  initialDedicatedDate = "",
  sponsoredDateAnchor = "",
  dedicatedDateAnchor = "",
  initialSponsoredSchedule = "",
  initialDedicatedSchedule = "",
  onScheduleUpdated = () => {},
}) => {
  const { siteSlug } = useParams<{ siteSlug: string }>();
  const [sponsoredSchedule, setSponsoredSchedule] = useState<string>(
    initialSponsoredSchedule
  );
  const [dedicatedSchedule, setDedicatedSchedule] = useState<string>(
    initialDedicatedSchedule
  );
  const [sponsoredDate, setSponsoredDate] =
    useState<string>(initialSponsoredDate);
  const [dedicatedDate, setDedicatedDate] =
    useState<string>(initialDedicatedDate);
  const [openModal, setOpenModal] = useState<"sponsored" | "dedicated" | null>(
    null
  );
  const [isFormChanged, setIsFormChanged] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false); // For loading state

  /**
   * Calculates the next occurrence based on the RRule string and an optional anchor date.
   * @param rruleString The RRule string defining the schedule.
   * @param anchorDate Optional anchor date to base the calculation on.
   * @returns The next occurrence date in YYYY-MM-DD format or an empty string if not found.
   */
  const getNextOccurrence = (
    rruleString: string,
    anchorDate?: string
  ): string => {
    try {
      const rule = rrulestr(rruleString);
      const baseDate = anchorDate ? new Date(anchorDate) : new Date();
      const nextDate = rule.after(baseDate, true); // true to include the base date if it matches
      return nextDate ? format(nextDate, "yyyy-MM-dd") : "";
    } catch (error) {
      return "";
    }
  };

  const useScheduleText = (schedule: string) => {
    return useMemo(() => {
      if (schedule) {
        try {
          return rrulestr(schedule).toText();
        } catch (error) {
          return "";
        }
      }
      return "";
    }, [schedule]);
  };
  const sponsoredScheduleText = useScheduleText(sponsoredSchedule);
  const dedicatedScheduleText = useScheduleText(dedicatedSchedule);

  /**
   * Sync sponsoredSchedule and sponsoredDate with incoming props
   */
  useEffect(() => {
    setSponsoredSchedule(initialSponsoredSchedule);
    if (initialSponsoredDate) {
      setSponsoredDate(initialSponsoredDate);
    } else if (initialSponsoredSchedule) {
      const nextDate = getNextOccurrence(
        initialSponsoredSchedule,
        sponsoredDateAnchor
      );
      setSponsoredDate(nextDate);
    }
  }, [initialSponsoredSchedule, initialSponsoredDate, sponsoredDateAnchor]);

  /**
   * Sync dedicatedSchedule and dedicatedDate with incoming props
   */
  useEffect(() => {
    setDedicatedSchedule(initialDedicatedSchedule);
    if (initialDedicatedDate) {
      setDedicatedDate(initialDedicatedDate);
    } else if (initialDedicatedSchedule) {
      const nextDate = getNextOccurrence(
        initialDedicatedSchedule,
        dedicatedDateAnchor
      );
      setDedicatedDate(nextDate);
    }
  }, [initialDedicatedSchedule, initialDedicatedDate, dedicatedDateAnchor]);

  /**
   * Determine if the form has changed compared to the initial props
   */
  useEffect(() => {
    const hasChanged =
      sponsoredSchedule !== initialSponsoredSchedule ||
      sponsoredDate !== initialSponsoredDate ||
      dedicatedSchedule !== initialDedicatedSchedule ||
      dedicatedDate !== initialDedicatedDate;
    setIsFormChanged(hasChanged);
  }, [
    sponsoredSchedule,
    sponsoredDate,
    dedicatedSchedule,
    dedicatedDate,
    initialSponsoredSchedule,
    initialSponsoredDate,
    initialDedicatedSchedule,
    initialDedicatedDate,
  ]);

  const createDateChangeHandler =
    (setDate: React.Dispatch<React.SetStateAction<string>>) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setDate(e.target.value);
    };

  const handleSponsoredDateChange = createDateChangeHandler(setSponsoredDate);
  const handleDedicatedDateChange = createDateChangeHandler(setDedicatedDate);

  const handleScheduleChange = (
    type: "sponsored" | "dedicated",
    updatedSchedule: string
  ) => {
    if (updatedSchedule) {
      try {
        const rruleInstance: RRule = rrulestr(updatedSchedule);
        const rruleString = rruleInstance.toString();

        if (type === "sponsored") {
          setSponsoredSchedule(rruleString);
          const nextDate = getNextOccurrence(
            rruleString,
            sponsoredDateAnchor || sponsoredDate
          );
          setSponsoredDate(nextDate || sponsoredDate); // Only update if nextDate exists
        } else {
          setDedicatedSchedule(rruleString);
          const nextDate = getNextOccurrence(
            rruleString,
            dedicatedDateAnchor || dedicatedDate
          );
          setDedicatedDate(nextDate || dedicatedDate); // Only update if nextDate exists
        }
      } catch (error) {
        noop();
      }
    }
  };

  const openScheduleModal = (type: "sponsored" | "dedicated") => {
    setOpenModal(type);
  };

  const closeScheduleModal = () => {
    setOpenModal(null);
  };

  const handleSaveChanges = async () => {
    if (!siteSlug) {
      return;
    }

    setLoading(true);
    const requestBody: Record<string, string> = {};

    if (sponsoredEmailEnabled) {
      requestBody["sponsored_email_schedule"] = sponsoredSchedule;
      requestBody["schedule_anchor_date"] = sponsoredDate;
    }

    if (dedicatedEmailEnabled) {
      requestBody["dedicated_email_schedule_ical"] = dedicatedSchedule;
      requestBody["dedicated_email_schedule_anchor_date"] = dedicatedDate;
    }

    try {
      await axios.patch(
        `/api/v1/publisher/sites/${siteSlug}/schedule`,
        requestBody
      );

      onScheduleUpdated();
      setIsFormChanged(false);
    } catch (error) {
      noop();
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card sx={{ mb: 3 }}>
      <CardHeader title="Sponsorship Schedule" />
      <form>
        <CardContent>
          <Grid container spacing={4}>
            {sponsoredEmailEnabled && (
              <ScheduleSection
                openScheduleModal={openScheduleModal}
                type="sponsored"
                scheduleText={sponsoredScheduleText}
                date={sponsoredDate}
                onDateChange={handleSponsoredDateChange}
              />
            )}
            {dedicatedEmailEnabled && (
              <ScheduleSection
                openScheduleModal={openScheduleModal}
                type="dedicated"
                scheduleText={dedicatedScheduleText}
                date={dedicatedDate}
                onDateChange={handleDedicatedDateChange}
              />
            )}
          </Grid>
        </CardContent>

        <Divider />
        {isFormChanged && (
          <CardActions sx={{ p: 3 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSaveChanges}
              disabled={loading}
              startIcon={loading && <CircularProgress size={20} />}
            >
              {loading ? "Saving..." : "Save Changes"}
            </Button>
          </CardActions>
        )}
      </form>

      {openModal && (
        <NewsletterScheduleDialog
          open={!!openModal}
          data={
            openModal === "sponsored" ? sponsoredSchedule : dedicatedSchedule
          }
          onClose={closeScheduleModal}
          onChange={(newRRule: string) =>
            handleScheduleChange(openModal, newRRule)
          }
        />
      )}
    </Card>
  );
};

export default SponsorshipSchedule;
