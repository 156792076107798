import React, { useState, useLayoutEffect, useContext } from "react";
import * as yup from "yup";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate, useLocation } from "react-router-dom";
import { Formik, Form, FormikHelpers } from "formik";
import { Button, CircularProgress } from "@mui/material";
import SignInField, { Values } from "./SignInField";
import ResetPassword from "./ResetPassword";
import { UserContext } from "context";
import { isAdmin } from "utils/authorize";
import { handleAuth } from "utils/auth";
import { Link } from "react-router-dom";

const validationSchema = yup.object().shape({
  password: yup.string().required("Password is required."),
});

export default function SignInPasswordForm() {
  const { setUserData } = useContext(UserContext);
  const navigate = useNavigate();
  const [showResetPassword, setShowResetPassword] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState(false);
  const location = useLocation();
  const email = location.state?.email;

  useLayoutEffect(() => {
    setUserData({
      firstName: "",
      lastName: "",
      companyName: "",
      userType: undefined,
      createdAt: new Date(),
      isAdmin: false,
      has_booker_access: false,
      has_ad_network_access: false,
      has_marketplace_access: false,
      userMenu: [],
      uuid: "",
      ro_id: null,
      companyId: null,
    });
    Cookies.remove("bearer");
    Cookies.remove("bearer_expires");
    Cookies.remove("bearer_refresh");
    delete axios.defaults.headers.common["Authorization"];
  }, [setUserData]);

  const handleSubmit = (data: Values, actions: FormikHelpers<Values>) => {
    const url = "/api/v1/oauth/token";
    const body = {
      ...data,
      grant_type: "password",
      client_id: process.env.REACT_APP_CLIENT_ID,
      client_secret: process.env.REACT_APP_CLIENT_SECRET,
    };
    actions.setSubmitting(true);
    axios
      .post(url, body)
      .then((response) => handleAuth(response))
      .then(() => axios.get("/api/v1/users/me"))
      .then((response) => {
        const {
          first_name,
          last_name,
          company_name,
          user_type,
          has_booker_access,
          has_ad_network_access,
          created_at,
          has_marketplace_access,
          menu,
          uuid,
          ro_id,
          company_id,
        } = response.data;
        setUserData({
          firstName: first_name,
          lastName: last_name,
          companyName: company_name,
          userType: user_type,
          createdAt: new Date(created_at),
          isAdmin: isAdmin(),
          has_booker_access,
          has_ad_network_access,
          has_marketplace_access,
          userMenu: menu,
          uuid,
          ro_id,
          companyId: company_id,
        });
        if (user_type === "publisher") {
          return (window.location.pathname = "/publisher/sites");
        }
        return (window.location.pathname = "/marketplace");
      })
      .catch((err) => {
        if (err.response.status === 428) {
          // redirect with 428 is used for 2FA
          navigate(err.response.data.location);
          localStorage.setItem("sign_in_data", JSON.stringify(body));
        } else {
          actions.setSubmitting(false);
        }
      });
  };

  return (
    <>
      <Formik
        initialValues={{ email: email, password: "" }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, isSubmitting, touched, handleBlur }) => (
          <Form>
            <div className="signup-steps">
              <div className="step active">
                <p className="heading heading--truncate">{`Welcome back, ${email}`}</p>
                <p className="subheading" style={{ marginBottom: 0 }}>
                  Not this account?{" "}
                  <Link to="/users/sign_in">Use a different email</Link>
                </p>
                <p className="error devise-error" />
                <SignInField
                  name="password"
                  type={showPassword ? "text" : "password"}
                  touched={touched}
                  errors={errors}
                  showPassword={showPassword}
                  onEndIconClick={() => setShowPassword(!showPassword)}
                  onBlur={handleBlur}
                />
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  fullWidth
                  disabled={isSubmitting}
                  sx={{
                    backgroundColor: "primary.dark",
                  }}
                  endIcon={
                    isSubmitting ? (
                      <CircularProgress size={12} sx={{ color: "#fff" }} />
                    ) : null
                  }
                >
                  Log in
                </Button>
                <p
                  className="note"
                  style={{ marginTop: "10px", marginBottom: "10px" }}
                >
                  <a
                    className="popup-open"
                    href="/users/sign_in#reset-password"
                    onClick={(e) => {
                      e.preventDefault();
                      setShowResetPassword(true);
                    }}
                  >
                    Forgot password
                  </a>
                </p>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <ResetPassword
        open={showResetPassword}
        handleClose={() => setShowResetPassword(false)}
        email={email}
      />
    </>
  );
}
