import React from "react";

export default function StepCaption({
  heading,
  subheading,
}: {
  heading: string;
  subheading?: string;
}) {
  return (
    <div className="step active">
      <p className="heading">{heading}</p>
      <p className="subheading">{subheading}</p>
    </div>
  );
}
