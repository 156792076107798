import React, {
  ChangeEvent,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from "@mui/material";
import { useFormik, FormikValues, FormikErrors } from "formik";
import useComponentDidMount from "hooks/useComponentDidMount";

import { ICreativeRequirement } from "types";
import CreativeItemField from "./CreativeItemField";
import axios from "axios";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import isEqual from "lodash/isEqual";
import { LayoutContext } from "context";
import FormWrapper from "components/FormWrapper";
import noop from "lodash/noop";
import CreativeEditHeader from "./CreativeEditHeader";
import CreativeRequirements from "./CreativeRequirements";
import { useStatus } from "../index";
import { scrollToFirstError } from "utils/errorHandlers";
import useDebounce from "hooks/useDebounce";
import { Suggestion } from "./AICreativeItemTextField";
import {
  compareCreativeItemByType,
  getMergedSuggestions,
  getMessages,
  getShapeObject,
  remapSuggestions,
} from "./utils";

export default function CreativeEdit() {
  const { setIsDataLoading } = useContext(LayoutContext);
  const [creativeItems, setCreativeItems] = useState<ICreativeRequirement[]>(
    []
  );
  const [initialValues, setInitialValues] = useState<FormikValues>({});
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const { campaignId } = useParams();
  const {
    statusName,
    exampleUrl,
    creativeDeadline,
    sponsorshipType,
    campaignUuid,
  } = useStatus();

  const [historicalSuggestions, setHistoricalSuggestions] = useState<
    Suggestion[]
  >([]);
  const [AISuggestions, setAISuggestions] = useState<Suggestion[]>([]);
  const linkForSuggestions = creativeItems.find(
    (elem) => elem.creative_type === "link"
  );
  const [isAILoading, setIsAILoading] = useState(false);
  const [uploadErrors, setUploadErrors] = useState<FormikErrors<FormikValues>>(
    {}
  );

  const isSponsoredOrDedicatedEmail =
    sponsorshipType === "sponsored_email" ||
    sponsorshipType === "dedicated_email";

  useEffect(() => {
    if (
      isSponsoredOrDedicatedEmail &&
      linkForSuggestions &&
      linkForSuggestions.value
    ) {
      setIsAILoading(true);
      axios
        .post(
          `/api/v1/advertiser/campaigns/${campaignId}/creative_suggestions`,
          { url: linkForSuggestions.value },
          { processErrorInComponentStatuses: [503, 500] }
        )
        .then((res) => {
          const aiOptions = remapSuggestions(res.data, "ai");
          setAISuggestions(aiOptions);
        })
        .catch(() => {
          setAISuggestions([]);
        })
        .finally(() => {
          setIsAILoading(false);
        });
    }
  }, [linkForSuggestions, isSponsoredOrDedicatedEmail, campaignId]);

  const suggestedRequirements: Suggestion[] = useMemo(
    () => getMergedSuggestions(historicalSuggestions, AISuggestions),
    [historicalSuggestions, AISuggestions]
  );

  const initialize = (response: { data: ICreativeRequirement[] }) => {
    const data: ICreativeRequirement[] = response.data
      .map((item) => {
        return {
          // TODO: convert nulls to undefined
          ...item,
          uuid: String(item.uuid),
        };
      })
      .sort((a, b) => compareCreativeItemByType(a, b));
    setCreativeItems(() =>
      data.map((item: ICreativeRequirement) => {
        if (item.creative_type === "link") {
          item.isValidUrl = undefined;
        }

        return item;
      })
    );
    const formikInitialValues = data.reduce(
      (acc: { [key: string]: any }, item) => {
        acc[item.uuid] = item.value ?? undefined;
        return acc;
      },
      {}
    );
    setIsEditMode(data.some((item) => !!item.value));
    setInitialValues(() => formikInitialValues);
  };

  useComponentDidMount(() => {
    setIsDataLoading(true);
    axios
      .get(`/api/v1/advertiser/campaigns/${campaignId}/creative_items`)
      .then((response) => initialize(response))
      .catch(noop)
      .finally(() => setIsDataLoading(false));
    axios
      .get(`/api/v1/advertiser/campaigns/${campaignId}/creative_library`)
      .then((response) => {
        const data = remapSuggestions(response.data, "saved");
        setHistoricalSuggestions(data);
      })
      .catch(noop);
  });

  const validationSchema = useMemo(() => {
    const shapeObject = getShapeObject(creativeItems);

    return Yup.object().shape(shapeObject);
  }, [creativeItems]);

  const formik = useFormik({
    validationSchema,
    enableReinitialize: true,
    initialValues,
    validateOnChange: true,
    onSubmit: async (values) => {
      const data = { ...values };
      setIsDataLoading(true);

      const links = creativeItems.filter(
        (item) =>
          item.creative_type === "link" &&
          item.isValidUrl === undefined &&
          item.value !== initialValues[item.uuid]
      );

      if (links.length > 0) {
        const requestsList = links.map((item) => {
          const url = formik.values[item.uuid] as string;

          return axios.post(
            `/api/v1/advertiser/campaigns/${campaignId}/creative_items/validate_url`,
            { url },
            { processErrorInComponentStatuses: [503] }
          );
        });

        const settledResults = await Promise.allSettled(requestsList);

        settledResults.forEach((result, index) => {
          const isValidUrl =
            result.status === "fulfilled" && result.value.data.valid;
          links[index].isValidUrl = isValidUrl;

          if (isValidUrl) {
            const uuid = links[index].uuid;
            const urlValue = result.value.data.url;
            formik.setFieldValue(uuid, urlValue);
            data[uuid] = urlValue;
          }
        });

        const areAllLinksValid = links.every((link) => link.isValidUrl);

        creativeItems.forEach((item) => {
          if (item.creative_type === "link") {
            const link = links.find((linkItem) => linkItem.uuid === item.uuid);
            item.isValidUrl = link?.isValidUrl;
            item.validating = false;
          }
        });

        if (!areAllLinksValid) {
          setIsDataLoading(false);
          return setTimeout(() => scrollToFirstError(), 200);
        }
      }

      try {
        const creative_items_attributes = creativeItems.map((item) => ({
          creative_requirement_id: item.uuid,
          value: data[item.uuid],
          id: item.creative_item_id,
        }));

        const fileCreativeItems = creativeItems.filter(
          (item) =>
            item.creative_type === "image" || item.creative_type === "html"
        );

        if (!!fileCreativeItems.length) {
          for (let i = 0; i < fileCreativeItems.length; i++) {
            const imgCreativeItem = fileCreativeItems[i];
            const itemValue = data[imgCreativeItem.uuid];
            if (itemValue instanceof File) {
              const filename = itemValue.name;

              const uploadUrlResponse = await axios.post(
                `/api/v1/advertiser/campaigns/${campaignId}/creative_items/create_presigned_url`,
                {
                  filename,
                  field_uuid: imgCreativeItem.uuid,
                  sponsorship_option_id: campaignUuid,
                }
              );

              const { presigned_url, public_url } = uploadUrlResponse.data;

              const response = await fetch(presigned_url, {
                method: "PUT",
                body: itemValue,
              });

              if (response.ok && (await response.text()) === "") {
                const creativeItemAttribute = creative_items_attributes.find(
                  (attr) =>
                    attr.creative_requirement_id === imgCreativeItem.uuid
                );

                if (creativeItemAttribute) {
                  creativeItemAttribute.value = public_url;
                }
              }
            } else if (
              typeof itemValue === "string" &&
              itemValue.startsWith("http")
            ) {
              const creativeItemAttribute = creative_items_attributes.find(
                (attr) => attr.creative_requirement_id === imgCreativeItem.uuid
              );

              if (creativeItemAttribute) {
                creativeItemAttribute.value = itemValue;
              }
            }
          }
        }

        await axios
          .patch(`/api/v1/advertiser/campaigns/${campaignId}/creative_items`, {
            creative_items_attributes,
          })
          .then(() => {
            const queryParamsStr = isEditMode
              ? "creative_success=true"
              : "upload=true&creative_success=true";

            window.location.href = `${window.location.origin}/advertiser/campaigns/${campaignId}?${queryParamsStr}`;
          });
      } catch (e) {
        console.error(e);
      }

      setIsDataLoading(false);
    },
  });

  const validateUrl = (uuid: string) => {
    if (formik.isSubmitting || !!formik.errors[uuid]) {
      return;
    }

    const shouldLoadSuggestions = uuid === linkForSuggestions?.uuid;

    const url = formik.values[uuid];

    const currentCreativeItem = creativeItems.find((c) => c.uuid === uuid);

    if (!currentCreativeItem) {
      return;
    }

    currentCreativeItem.isValidUrl = undefined;
    currentCreativeItem.validating = true;

    setCreativeItems([...creativeItems]);
    if (shouldLoadSuggestions) setAISuggestions([]);

    axios
      .post(
        `/api/v1/advertiser/campaigns/${campaignId}/creative_items/validate_url`,
        { url },
        { processErrorInComponentStatuses: [503] }
      )
      .then((result) => {
        currentCreativeItem.isValidUrl = result.data.valid;
        if (result.data.valid) {
          formik.setFieldValue(uuid, result.data.url);
        }
        if (
          result.data.valid &&
          isSponsoredOrDedicatedEmail &&
          shouldLoadSuggestions
        ) {
          setIsAILoading(true);
          axios
            .post(
              `/api/v1/advertiser/campaigns/${campaignId}/creative_suggestions`,
              { url: result.data.url },
              { processErrorInComponentStatuses: [503, 500] }
            )
            .then((result) => {
              const aiOptions = remapSuggestions(result.data, "ai");
              setAISuggestions(aiOptions);
            })
            .catch(noop)
            .finally(() => {
              setIsAILoading(false);
            });
        }
      })
      .catch(() => (currentCreativeItem.isValidUrl = false))
      .finally(() => {
        currentCreativeItem.validating = false;
        setCreativeItems([...creativeItems]);
      });
  };

  const validateUrlDebounce = useDebounce(validateUrl, 1_000);

  const htmlAndImageItems = creativeItems.filter(
    (item) => item.creative_type === "html" || item.creative_type === "image"
  );
  const links = creativeItems.filter((item) => item.creative_type === "link");

  const textItems = creativeItems.filter(
    (item) => item.creative_type === "text"
  );

  const groups = [
    {
      items: links,
      key: "links",
    },
    { name: "Sponsorship Assets", items: htmlAndImageItems, key: "assets" },
    { name: "Sponsorship Copy", items: textItems, key: "text" },
  ];

  return (
    <FormWrapper onSubmit={formik.handleSubmit}>
      <Grid container mt={3}>
        <Grid item xs={12}>
          <CreativeRequirements
            creativeItems={creativeItems}
            creativeDeadline={creativeDeadline}
            exampleUrl={exampleUrl}
          />
        </Grid>
        <Grid item xs={12}>
          <Card sx={{ mt: "8px" }}>
            {statusName === "Creative" && (
              <CardHeader component={CreativeEditHeader} />
            )}
            <CardContent>
              <Grid container spacing={2} flexDirection="column">
                {groups.map((group) => {
                  return (
                    <React.Fragment key={group.key}>
                      {!!group.items.length && group.name && (
                        <Grid item={true}>
                          <Typography variant="h6" sx={{ mt: 3 }}>
                            {group.name}
                          </Typography>
                        </Grid>
                      )}
                      {group.items.map((creativeItem: ICreativeRequirement) => {
                        const {
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          setFieldValue,
                          setFieldTouched,
                        } = formik;
                        const name = creativeItem.uuid;
                        const value = values[name];
                        const [error, warning] = getMessages(
                          !!touched[name],
                          creativeItem,
                          errors,
                          uploadErrors
                        );

                        return (
                          <Grid item={true} key={creativeItem.uuid}>
                            <CreativeItemField
                              creativeItem={creativeItem}
                              value={value}
                              error={error}
                              warning={warning}
                              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                handleChange(e);

                                if (creativeItem.creative_type === "link") {
                                  creativeItem.isValidUrl = undefined;
                                  validateUrlDebounce(name);
                                }
                              }}
                              onFileChange={(file: File | null) => {
                                setFieldValue(creativeItem.uuid, file);

                                if (creativeItem.creative_type === "image") {
                                  const newUploadErrors = { ...uploadErrors };
                                  delete newUploadErrors[creativeItem.uuid];
                                  setUploadErrors(newUploadErrors);
                                }
                              }}
                              onBlur={handleBlur}
                              setFieldValue={setFieldValue}
                              validating={creativeItem.validating}
                              isValid={creativeItem.isValidUrl}
                              onValidateClick={() => {
                                if (creativeItem.creative_type !== "link") {
                                  return;
                                }

                                validateUrl(name);
                              }}
                              options={suggestedRequirements}
                              isLoading={isAILoading}
                              setFieldTouched={setFieldTouched}
                            />
                          </Grid>
                        );
                      })}
                    </React.Fragment>
                  );
                })}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="flex-end" marginTop={3}>
            <Button
              color="primary"
              variant="contained"
              type="submit"
              disabled={
                isEqual(initialValues, formik.values) ||
                creativeItems.some((creativeItem) => creativeItem.validating)
              }
              id="submit-creative-button"
            >
              {isEditMode ? "Update Creative" : "Submit Creative"}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </FormWrapper>
  );
}
