import {
  Box,
  Card,
  CardContent,
  List,
  ListItemText,
  Typography,
} from "@mui/material";
import { ICreativeRequirement } from "../../../../types";
import CreativeRequirementItem, {
  CreativeRequirementItemView,
} from "./CreativeRequirementItem";

export default function CreativeRequirements({
  creativeItems,
  creativeDeadline,
  exampleUrl,
}: {
  creativeItems: ICreativeRequirement[];
  creativeDeadline: string | null;
  exampleUrl: string | null;
}) {
  return (
    <Card>
      <CardContent>
        <Box sx={{ mb: 2 }}>
          <Typography variant="h6" component="h3">
            Creative Requirements
          </Typography>
        </Box>

        <List component="div" disablePadding sx={{ mt: "1.15rem" }}>
          <ListItemText>
            The publisher has provided the following creative requirements:
          </ListItemText>
          <Box>
            {creativeItems.map((creativeItem) => (
              <CreativeRequirementItem
                key={creativeItem.uuid}
                creativeItem={creativeItem}
              />
            ))}
            {exampleUrl && (
              <CreativeRequirementItemView
                name="Example"
                value={exampleUrl}
                isLink={true}
              />
            )}
            {creativeDeadline && (
              <CreativeRequirementItemView
                name="Creative Deadline"
                value={creativeDeadline}
              />
            )}
          </Box>
        </List>
      </CardContent>
    </Card>
  );
}
