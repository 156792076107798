enum SponsorshipType {
  SPONSORED_EMAIL = "sponsored_email",
  DEDICATED_EMAIL = "dedicated_email",
  SPONSORED_POST = "sponsored_post",
}

enum ActionType {
  ADD = "add",
  REMOVE = "remove",
}

enum SponsorshipSource {
  BOOKER = "booker",
  PUBLISHER = "publisher",
  MARKETPLACE = "marketplace_campaign",
}

type ExtendedProps = {
  campaign_id?: string;
  custom_price?: number;
  name?: string;
  reserved: boolean;
  source?: SponsorshipSource;
  sponsorship_option_uuid?: string;
  price: number;
  sponsorship_type: SponsorshipType;
};

type SelectedEventDetails = {
  startDate: DateStringYYYYDashMMDashDD;
  actionType: ActionType;
  title: string;
  extendedProps: ExtendedProps;
};

type DateStringYYYYDashMMDashDD =
  `${number}${number}${number}${number}-${number}${number}-${number}${number}`;

export function isValidYYYYDashMMDashDDDateString(
  date: string
): date is DateStringYYYYDashMMDashDD {
  return /^\d{4}-\d{2}-\d{2}/.test(date);
}

export { SponsorshipType, ActionType, SponsorshipSource };

export type { ExtendedProps, SelectedEventDetails, DateStringYYYYDashMMDashDD };
