import { Box, Typography } from "@mui/material";

interface SuccessMessageProps {
  successType: "blocked" | "customPrice";
  formattedDate: string;
}

const SuccessMessage: React.FC<SuccessMessageProps> = ({
  successType,
  formattedDate,
}) => {
  const isBlocked = successType === "blocked";

  return (
    <Box textAlign="center" m="auto">
      <svg
        width="94"
        height="94"
        viewBox="0 0 94 94"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M39.5677 59.8766L26.6717 46.9806C26.1878 46.4967 25.5949 46.2383 24.8931 46.2056C24.1905 46.172 23.5644 46.4304 23.015 46.9806C22.4648 47.53 22.1897 48.1393 22.1897 48.8083C22.1897 49.4774 22.4648 50.0867 23.015 50.636L36.6473 64.2683C37.4817 65.1027 38.4552 65.5199 39.5677 65.5199C40.6803 65.5199 41.6542 65.1027 42.4895 64.2683L70.786 35.9717C71.27 35.4878 71.5283 34.8949 71.561 34.1931C71.5946 33.4905 71.3363 32.8644 70.786 32.315C70.2367 31.7648 69.6274 31.4897 68.9583 31.4897C68.2892 31.4897 67.68 31.7648 67.1306 32.315L39.5677 59.8766ZM47.0168 93.5C40.5869 93.5 34.5414 92.2798 28.8805 89.8394C23.2204 87.399 18.2966 84.0872 14.109 79.9039C9.92142 75.7206 6.60657 70.8011 4.16446 65.1453C1.72149 59.4904 0.5 53.4476 0.5 47.0168C0.5 40.5869 1.72019 34.5414 4.16058 28.8805C6.60097 23.2204 9.91281 18.2966 14.0961 14.109C18.2794 9.92141 23.1989 6.60657 28.8547 4.16446C34.5096 1.72149 40.5524 0.5 46.9832 0.5C53.4131 0.5 59.4586 1.7202 65.1195 4.16059C70.7796 6.60098 75.7034 9.91281 79.891 14.0961C84.0786 18.2794 87.3934 23.1989 89.8355 28.8547C92.2785 34.5096 93.5 40.5524 93.5 46.9832C93.5 53.4131 92.2798 59.4586 89.8394 65.1195C87.399 70.7796 84.0872 75.7034 79.9039 79.891C75.7206 84.0786 70.8011 87.3934 65.1453 89.8355C59.4904 92.2785 53.4476 93.5 47.0168 93.5ZM47 88.3333C58.5389 88.3333 68.3125 84.3292 76.3208 76.3208C84.3292 68.3125 88.3333 58.5389 88.3333 47C88.3333 35.4611 84.3292 25.6875 76.3208 17.6792C68.3125 9.67083 58.5389 5.66667 47 5.66667C35.4611 5.66667 25.6875 9.67083 17.6792 17.6792C9.67083 25.6875 5.66667 35.4611 5.66667 47C5.66667 58.5389 9.67083 68.3125 17.6792 76.3208C25.6875 84.3292 35.4611 88.3333 47 88.3333Z"
          fill="#28A745"
        />
      </svg>

      <Typography variant="h5" fontWeight="bold" mt={3}>
        {isBlocked ? "Date Marked as Reserved" : "You Applied Custom Price"}
      </Typography>
      <Typography variant="body2" color="text.secondary" mt={2}>
        {isBlocked
          ? `You've marked ${formattedDate} as reserved. Advertisers won’t be able to select this date anymore.`
          : `Custom pricing is now live for ${formattedDate}.`}
      </Typography>
    </Box>
  );
};

export default SuccessMessage;
