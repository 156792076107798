import React, { useEffect, useState, useContext } from "react";
import { Button, FormHelperText, Grid, InputLabel, Box } from "@mui/material";
import axios from "axios";
import PreviewSampleEmail from "./PreviewSampleEmail";
import { LayoutContext } from "context";

export default function EmailSampleUploader({
  error,
  touched,
  exceedsPreviousAdvertisersLimit,
  previewUrl,
  siteSlug,
  id,
  callbackHandleUpload,
}: {
  error?: string | null;
  touched?: boolean;
  exceedsPreviousAdvertisersLimit?: boolean;
  previewUrl: string | null;
  siteSlug?: string;
  id: number | null;
  callbackHandleUpload?: (url: string) => void;
}) {
  const [uploadMode, setUploadMode] = useState<boolean>(!previewUrl);
  const [uploadFileName, setUploadFileName] = useState<string | undefined>();
  const [openSamplePreview, setOpenSamplePreview] = useState(false);
  const { setIsDataLoading } = useContext(LayoutContext);

  useEffect(() => {
    setUploadMode(!previewUrl);
  }, [previewUrl]);

  return (
    <>
      <InputLabel
        sx={{ display: "flex", alignItems: "center" }}
        htmlFor="draft_file"
      >
        Sample Email
        {!exceedsPreviousAdvertisersLimit && (
          <FormHelperText
            sx={{ marginTop: 0.25, paddingLeft: 0.75, fontStyle: "italic" }}
          >
            Optional
          </FormHelperText>
        )}
      </InputLabel>
      {!uploadMode && (
        <Grid item>
          <Button
            variant="outlined"
            type="button"
            sx={{
              color: "text.primary",
              textTransform: "capitalize",
              mr: 2,
            }}
            onClick={() => setOpenSamplePreview(true)}
          >
            View
          </Button>
          <Button
            variant="outlined"
            type="button"
            sx={{
              color: "text.primary",
              textTransform: "capitalize",
            }}
            onClick={() => setUploadMode(true)}
          >
            Change
          </Button>
        </Grid>
      )}
      {uploadMode && (
        <>
          <Box
            sx={{ display: "flex", alignItems: "baseline" }}
            data-error-element={touched && Boolean(error)}
          >
            <Button
              variant="outlined"
              component="label"
              sx={{
                color: "text.primary",
                textTransform: "capitalize",
                mr: 2,
              }}
            >
              Browse
              <input
                name="draft_file"
                accept=".html,.eml,.htm,message/rfc822"
                id="draft_file"
                type="file"
                hidden
                onChange={(e) => {
                  if (!siteSlug || !e.target.files?.[0]) {
                    return;
                  }

                  setUploadFileName(e.target.files[0].name);

                  const formData = new FormData();
                  formData.append("draft_file", e.target.files[0]);
                  formData.append("id", String(id));
                  setIsDataLoading(true);
                  axios
                    .patch(
                      `/api/v1/publisher/sites/${siteSlug}/sponsorship_options/sponsored_email/email_sample_upload`,
                      formData
                    )
                    .then((res) => {
                      if (callbackHandleUpload && res?.data?.example_url)
                        callbackHandleUpload(res.data.example_url);
                    })
                    .finally(() => {
                      setIsDataLoading(false);
                    });
                }}
              />
            </Button>

            <div>{uploadFileName}</div>
          </Box>
          {touched && error && (
            <FormHelperText
              sx={{
                marginTop: 1,
                color: "error.main",
              }}
            >
              {error}
            </FormHelperText>
          )}
          <FormHelperText>
            Upload a recent example of your newsletter. This should be an HTML
            or EML file.{" "}
            <a
              href="https://help.paved.com/article/142-how-to-export-emails-as-an-eml-file"
              target="_blank"
              rel="noreferrer"
            >
              Learn more
            </a>
            .
          </FormHelperText>
          <FormHelperText sx={{ marginTop: 1 }}>
            Accepted filetypes: HTML, EML files
          </FormHelperText>
        </>
      )}
      <PreviewSampleEmail
        open={openSamplePreview}
        exampleUrl={previewUrl || ""}
        handleClose={() => setOpenSamplePreview(false)}
      />
    </>
  );
}
